import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../Support/SupportPage.css';


export default function SupportFormSkeleton ({textarea}){
    return(
        <div style={{padding: 'none', background: 'transparent', margin: '20px'}} >
            {<Skeleton height={textarea? 100:30} width={340}/>}
        </div>
    )
}