import React from 'react';

function Spinner() {
    return (
        <div className="spinner">
            <div className="spinner-inner" />
            <div className="spinner-text">Loading...</div>
        </div>
    );
}

export default Spinner;
