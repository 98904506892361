import React from 'react';

function Terms({number, text}) {


    return (
        <div className='split'>
            <div>
                {number}
            </div>
            <div>
                {text}
            </div>
        </div>
    );
}

export default Terms;
