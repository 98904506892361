import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import {toast} from "react-toastify";

const CareGiverForm = (props) => {
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('');




    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {


            const CareGiver ={
                'Surname': localStorage.getItem('Surname_CG')  ?  localStorage.getItem('Surname_CG'): '',
                'Full_Name': localStorage.getItem('FullName_CG')  ?  localStorage.getItem('FullName_CG'): '',
                'Email': localStorage.getItem('Email_CG')  ?  localStorage.getItem('Email_CG'): '',
                'Phone_Number': localStorage.getItem('Phone_number_CG')  ?  localStorage.getItem('Phone_number_CG'): '',
                'starting_Month_Year': localStorage.getItem('start_CG')  ?  localStorage.getItem('start_CG'): '',
            }


            const form_data = {
                'CareGiver': CareGiver,
            };

            setPopUp('Loading.....')

            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Permanent Residency in Canada(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.status === 201) {
                    setPopUp('submitted');
                    toast.success("Form submitted successfully");
                    props.ReceiveJetHireForm(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed');
                toast.error("Failed to submit form");

            }

        }


    };


    return (
        <form ref={props.close}  onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Personal Information
            </div>
            <div className="IELTS1">

                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='Surname_CG'
                                placeholder='Surname' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Full Name' star='*' type='text'
                                localStorageName='FullName_CG'
                                placeholder='Full name ' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email' star='*' type='email'
                                localStorageName='Email_CG'
                                placeholder='jarom@gmail.com' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Phone Number' star='*' type='number'
                                localStorageName='Phone_number_CG'
                                placeholder='Phone Number' required={true}>

                </VisaInputField>

                <VisaInputField labelName='What month and year do you want to start the training?
                    ' star='*' type='text'
                                localStorageName='start_CG'
                                placeholder='October, 2024' required={true}>

                </VisaInputField>

            </div>






            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveJetHireForm(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>

    );
};

export default CareGiverForm;