import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import VisaDoubleInputField from "../Visa/visaDoubleInput";
import TitleDropdown from "../Visa/titleDropdown";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export default function IELTSForm (props){
    const gender = ['Male', 'Female', 'Others'];
    const Title = ["Dr", "Mr", "Mrs", "Miss", "Ms"];
    const test_type = ["Academic", "General", "UKVI Academic", "UKVI General"];
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            const IELTSForm ={
                'Title': localStorage.getItem('IELTSTitleOS')  ?  localStorage.getItem('IELTSTitleOS'): '',
                'First_Name': localStorage.getItem('IELTSFirstNameOS')  ?  localStorage.getItem('IELTSFirstNameOS'): '',
                'Last_Name': localStorage.getItem('IELTSSurnameOS')  ?  localStorage.getItem('IELTSSurnameOS'): '',
                'phone_number_code': localStorage.getItem('IELTSPhoneNumberCodeOS')  ?  localStorage.getItem('IELTSPhoneNumberCodeOS'): '',
                'phone_number': localStorage.getItem('PhoneNumberOS')  ?  localStorage.getItem('PhoneNumberOS'): '',
                'Email': localStorage.getItem('IELTSEmailOS')  ?  localStorage.getItem('IELTSEmailOS'): '',
                'Gender': localStorage.getItem('IELTSGenderOS')  ?  localStorage.getItem('IELTSGenderOS'): '',
                'date_of_birth': localStorage.getItem('IELTSDOB_OS')  ?  localStorage.getItem('IELTSDOB_OS'): '',
                'Home_address': localStorage.getItem('IELTSHomeAddressOS')  ?  localStorage.getItem('IELTSHomeAddressOS'): '',
                'Test_city_location': localStorage.getItem('IELTSTestCityLocationOS')  ?  localStorage.getItem('IELTSTestCityLocationOS'): '',
                'Test_type': localStorage.getItem('IELTSTesttypeOS')  ?  localStorage.getItem('IELTSTesttypeOS'): '',
                'Preferred_date_1': localStorage.getItem('IELTSTestDateOS1')  ?  localStorage.getItem('IELTSTestDateOS1'): '',
                'Preferred_date_2': localStorage.getItem('IELTSTestDateOS2')  ?  localStorage.getItem('IELTSTestDateOS2'): '',
            }


            const form_data = {
                'IELTS Form': IELTSForm,
            };



            setPopUp('Loading..')

            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'IELTS Form(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                if (response.status === 201) {
                    setPopUp('submitted');
                    toast.success("Form submitted successfully");
                    props.ReceiveStateIELTS(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed');
                toast.error("Failed to submit form");
            }
        }

    };

    return(
        <form className='blurred-div' onSubmit={(e)=>handleSubmit(e)} ref={props.close}>

            <div className="blueHeader">
                Registration Form
            </div>
            <div className="IELTS1">

                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='IELTSSurnameOS'
                                placeholder='Surname as on intl. passport' required={true}>
                </VisaInputField>

                <VisaInputField labelName='First name' star='*' type='text'
                                localStorageName='IELTSFirstNameOS'
                                placeholder='First name as on intl. passport' required={true}>

                </VisaInputField>

                <div className="IELTSInner2">
                    <div className='visa_inputField ' id='inputTop'>
                        <label>
                            Title<span>*</span>
                        </label>
                        <TitleDropdown name='Title' localStorageName='IELTSTitleOS' mwidth='130px'
                                       mxfont='14px' mxwidth='100%' mfont='10px'
                                       options={Title} title="Select"
                                       className='visa_input'/>
                    </div>
                    <VisaInputField labelName='Date Of Birth' star='*' type='date'
                                    localStorageName='IELTSDOB_OS'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                </div>

                <VisaInputField labelName='Email Address' star='*' type='email'
                                localStorageName='IELTSEmailOS'
                                placeholder='xyz@gmailcom' required={true}>

                </VisaInputField>

              <div className="IELTSInner">
                  <VisaDoubleInputField localStorageName='PhoneNumberOS'
                                        localTitleStorage='IELTSPhoneNumberCodeOS'
                                        labelName='Phone Number' star='*'
                                        firstPlaceHolder='+234' type='tel'
                                        placeholder='Phone Number' value_i='+234' required={true}>
                  </VisaDoubleInputField>

                  <div className='visa_inputField ' id='inputTop'>
                      <label>
                          Gender<span>*</span>
                      </label>
                      <TitleDropdown name='Gender' localStorageName='IELTSGenderOS' mwidth='130px'
                                     mxfont='14px' mxwidth='120px' mfont='10px'
                                     options={gender} title="Select"
                                     className='visa_input'/>
                  </div>
              </div>

                <VisaInputField labelName='Home Address' star='*' type='text'
                                localStorageName='IELTSHomeAddressOS'
                                placeholder='Home Address' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Test city location' star='*' type='text'
                                localStorageName='IELTSTestCityLocationOS'
                                placeholder='Test city location' required={true}>

                </VisaInputField>

                <div className='visa_inputField ' id='inputTop'>
                    <label>
                        Which IELTS Test modules are you taking<span>*</span>
                    </label>
                    <TitleDropdown name='Test Type' localStorageName='IELTSTesttypeOS' mwidth='130px'
                                   mxfont='14px' mxwidth='100%' mfont='10px'
                                   options={test_type} title="Select"
                                   className='visa_input'/>
                </div>


                <div className="IELTSInner2">
                    <VisaInputField labelName='Preferred date of test (First Choice)' star='*' type='date'
                                    localStorageName='IELTSTestDateOS1'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='Preferred date of test (Second Choice)' star='*' type='date'
                                    localStorageName='IELTSTestDateOS2'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                </div>
            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'  type='submit'>{PopUp? PopUp : 'Submit'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={
                        ()=>props.ReceiveStateIELTS(false)
                    }>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>

            </div>

        </form>
    )
}