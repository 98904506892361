import React, {useState} from "react";
import envVariables from "../../dev";
import axiosWithAuth from "../Axios/axiosWithAuth";

export default function Testcomponent(){
    const [isScrollable, setIsScrollable] = useState(true);
    let biddd;

    const handleToggleScroll = () => {
        setIsScrollable(!isScrollable);
    };

    const apikey = envVariables.APIKEY;

    const fetchData = async () => {

         const email = localStorage.getItem('EmailBookingPage0') ? localStorage.getItem('EmailBookingPage0') : '';
        let save_response = JSON.parse(localStorage.getItem('save_booking_response'))
        if(save_response){
            save_response = save_response.data
        }
        const bid = save_response.booking_id ? save_response.booking_id.toString() : null;
        // console.log(save_response)
        const booking_summary = {
            'email': email
        };


        try {
            const formData = new FormData();
            formData.append('jarom', 'jaromtravels');
            formData.append('jarom_subject', 'visa');
            formData.append('jarom_message', booking_summary);


            // formData.forEach((value, key) => {
            //     console.log(`${key}: ${value}`);
            // });

            const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.data === 'Successful') {
                  console.log(response.data);
                // setInterval(setPopUp(false), 3000)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>


            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <button onClick={handleToggleScroll}>
                {isScrollable ? 'Disable Scroll' : 'Enable Scroll'}
            </button>
            <div
                style={{
                    height: '100vh',
                    overflow: isScrollable ? 'auto' : 'hidden',
                }}
            >
                {/* Your page content goes here */}
                <h1>Your Page Content</h1>
                <p>More content...{biddd}</p>
                <button onClick={fetchData}>click me</button>
            </div>
        </div>
    );
}