import React, {useEffect, useState} from "react";
import Slider from "rc-slider";

export default function SingleSeeker({ header, seconder, initial, start, stop , unit, className, storageKey, handleSubmit, myPrice, resetPrice}) {
    const [price, setPrice] = useState(initial); // Initial price value
    const handlePriceChange = (value) => {
        setPrice(value);
        localStorage.setItem(storageKey, price);
    };
    const handlePriceDrop = () => {
        handleSubmit();
    };
    useEffect(() => {
        const storedValue = localStorage.getItem(storageKey);
        if (storedValue !== null) {
            setPrice(parseInt(storedValue));
        }
    }, []);
        const currency = localStorage.getItem('Currency');
        if (currency === 'NGN') {
            myPrice = Number(price).toLocaleString('en-NG', { maximumFractionDigits: 0 });
        } else if (currency === 'USD') {
            myPrice = Number(price).toLocaleString('en-US', { maximumFractionDigits: 0 });
        } else if (currency === 'EUR') {
            myPrice = Number(price).toLocaleString('en-GB', { maximumFractionDigits: 0 });
        }
    const CombinedMethod = () =>{
        resetPrice();
        if(storageKey === 'price'){
            setPrice(localStorage.getItem('price'));
        }
        else{
            setPrice(localStorage.getItem('maxTravelTime'));
        }
    }



    return (
        <div>
            <div className="topper">{header}<span onClick={CombinedMethod}>Resets</span></div>
            <div className="first">
                {seconder}
            </div>
            <Slider
                min={start}
                max={stop}
                value={price}
                onChange={handlePriceChange}
                onAfterChange={handlePriceDrop}
            />
            <div className='sec'>
                <div className={className}><div>{unit}</div><div>0</div></div>
                <span><div className={className}><div>{unit}</div><div>{myPrice}</div></div></span>
            </div>
        </div>
    );
}
