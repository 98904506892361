import React, {useEffect, useState} from 'react';

function VisaDoubleInputField({localTitleStorage, localStorageName, labelName,star,firstPlaceHolder,placeholder,value,onChange,type,name,required, value_i}) {

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValue_, setSelectedValue_] = useState('');
    localStorage.setItem(localTitleStorage, '+234');
    useEffect(() => {
        const storedValue = localStorage.getItem(localStorageName);
        if (storedValue) {
            setSelectedValue(storedValue);
        }
        const storedValue_ = localStorage.getItem(localTitleStorage);
        if (storedValue_) {
            setSelectedValue_(storedValue_);
        }
    }, [localStorageName],[localTitleStorage]);
    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        localStorage.setItem(localStorageName, newValue);
        if (onChange) {
            onChange(newValue);
        }
    };
    const handleTChange = (event) => {
        const newValue_ = event.target.value;
        setSelectedValue_(newValue_);
        localStorage.setItem(localTitleStorage, newValue_);
        if (onChange) {
            onChange(newValue_);
        }
    };
    return (
        <div className='visa_inputField '>
            <label>
                {labelName}<span>{star}</span>
            </label><br/>
            <div className='visa_Double'>
                <input className='visa_input myinput' id='firstInput' placeholder={firstPlaceHolder} required={true} onChange={handleTChange} type="text" value={selectedValue_ !== null ? selectedValue_ : value_i}/>
                <input className='visa_input myinput' id='secondInput' name={name} type={type}
                       placeholder={placeholder} value={selectedValue} onChange={handleChange}
                       required={true}/>
            </div>
        </div>
    );
}

export default VisaDoubleInputField;
