import React, {useEffect, useState} from 'react'
import BaggageSkeleton from "../SkeletonLoadingComponent/BookingSkeleton/BaggageSkeleton";


export default function Baggage({isSelectedSec,type,item, loading,description,  name,dimen ,priceBag, bagImg, checkid, onSelectSec, onDeselectSec, popular, localStorageName, index, price}){

    const [hasClearedLocalStorage, setHasClearedLocalStorage] = useState(false);
    if(type==='top' && (price === 0 || price ==='0')){
        localStorageName = 'error'
    }
    useEffect(() => {
        if (!hasClearedLocalStorage) {
            localStorage.removeItem(localStorageName);
            localStorage.removeItem(`${localStorageName}_price`);
            setHasClearedLocalStorage(true);
        }
    }, [hasClearedLocalStorage, localStorageName]);

    const handleCheckboxChange = () => {
        if(!isSelectedSec) {
            onSelectSec({item, priceBag})
            localStorage.setItem(localStorageName, index )
            localStorage.setItem(`${localStorageName}_price`, price )
        }
        else{
            onDeselectSec({item, priceBag})
            localStorage.removeItem(localStorageName)
        }

    };

    const selectedStyle ={
        color: isSelectedSec ? '#054DAB' : ''
    }


    const popDisplay ={
        visibility: popular ? '' : 'hidden'
    }



    return(
        <div>
            {loading ?
                <div>
                    <BaggageSkeleton/>
                </div>
                :
                <div className={`clickbag ${isSelectedSec ? 'clickbagSelected' : ''}`}  id={'checkid'}>
                    <div className="checkboxxBag">
                        <input
                            id={checkid}
                            type="checkbox"
                            checked={isSelectedSec}
                            onChange={handleCheckboxChange}
                            name={name}
                            value={name}

                        />

                        <label htmlFor={checkid}>
                            <div  className="closeUp">
                                <div className="BaggImg">
                                    <img src={bagImg} alt="baggage"/>
                                </div>

                                <div className="ItemsAmt">
                                    <div className="spaceInit1">
                                        <div className="presItm">{item}</div>
                                        <div className="description">{description}</div>
                                    </div>
                                    <div className="spaceInit2">
                                        <div className="dimen">{dimen}</div>

                                    </div>
                                </div>
                            </div>

                            <div className="priceBag" >
                                <div style={popDisplay} className="popular">Popular</div>
                                <div className="priceBagMain" style={selectedStyle}>{priceBag}</div>
                            </div>
                        </label>
                    </div>

                </div>
            }
        </div>


    )

}