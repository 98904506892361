import React, {useEffect, useState} from 'react';
import VisaInputField from "./visaInput";


const MultipleFileInputs = ({heading, additionalFunction, buttonName, getUploadURL, diff}) => {
    const [fileInputs1, setFileInputs1] = useState(JSON.parse(localStorage.getItem('Squamous')) || [{diff,  id: 1, value: null }]);
    const [fileInputs2, setFileInputs2] = useState(JSON.parse(localStorage.getItem('Stratified')) ||[{diff,  id: 1, value: null }]);

    const handleAddFileInput1 = () => {
        const newId = fileInputs1.length + 1;
        setFileInputs1([...fileInputs1, {diff,  id: newId, value: null }]);
    };
    const handleAddFileInput2 = () => {
        const newId = fileInputs2.length + 1;
        setFileInputs2([...fileInputs2, {diff,  id: newId, value: null }]);
    };


    const handleFileInputChange1 = (id, file) => {
        console.log(file, 'file')
        const updatedFileInputs1 = fileInputs1.map((input) => {
            if (input.id === id) {
                return {diff, id: input.id, value: file };
            }
            // getUploadURL(`${heading} ${input.id}`, `${heading} ${input.id}name`, `visa_data_${input.id}`)
            return input;
        });
        setFileInputs1(updatedFileInputs1);
        localStorage.setItem('Squamous', JSON.stringify(fileInputs1));

    };

    const handleFileInputChange2 = (id, file) => {
        // console.log(file, 'file')
        const updatedFileInputs2 = fileInputs2.map((input) => {
            if (input.id === id) {
                return {diff, id: input.id, value: file };
            }
            // getUploadURL(`${heading} ${input.id}`, `${heading} ${input.id}name`, `visa_Passport_${input.id}`)


            return input;
        });
        setFileInputs2(updatedFileInputs2);
        localStorage.setItem('Stratified', JSON.stringify(fileInputs2))

    };
    // localStorage.setItem('Visa_data1', JSON.stringify(fileInputs1));
    // localStorage.setItem('Visa_data2', JSON.stringify(fileInputs2))
    // console.log(JSON.parse(localStorage.getItem('Visa_data1')), 'fileinputes1')
    // console.log(JSON.parse(localStorage.getItem('Visa_data2')), 'fileinputes2')

    const Stratified = JSON.parse(localStorage.getItem('Stratified')) ? JSON.parse(localStorage.getItem('Stratified')) : [];
    const Squamous = JSON.parse(localStorage.getItem('Squamous')) ? JSON.parse(localStorage.getItem('Squamous')) : [];


    useEffect(()=>{
        for (let i = 1; i <= Squamous.length; i++) {
            getUploadURL(`${heading} ${i}`, `${heading} ${i}name`, `visa_data_${i}`)
        }
        for (let i = 1; i <= Stratified.length; i++) {
            getUploadURL(`${heading} ${i}`, `${heading} ${i}name`, `visa_Passport_${i}`)
        }
    }, [Squamous, Stratified, getUploadURL, heading, ])



    return (
        <div className='multiple'>

            {diff ==='squamous' &&
                <>
                    {fileInputs1.map((input) => (
                        <>
                            <VisaInputField
                                key={input.id}
                                labelName={`${heading} ${input.id}`}
                                star="*"
                                type="file"
                                placeholder=""
                                additionalFunction={additionalFunction}
                                required={true}
                                idInput={input.id}
                                localStorageName={`${heading} ${input.id}`}
                                onChange={handleFileInputChange1}
                            />

                        </>

                    ))}
                    <button className='visa_multiple' onClick={handleAddFileInput1}>Add Data Page &nbsp;&nbsp; +</button>

                </>
            }
            {diff ==='startified' &&
                <>
                    {fileInputs2.map((input) => (
                        <>
                            <VisaInputField
                                key={input.id}
                                labelName={`${heading} ${input.id}`}
                                star="*"
                                type="file"
                                placeholder=""
                                additionalFunction={additionalFunction}
                                required={true}
                                idInput={input.id}
                                localStorageName={`${heading} ${input.id}`}
                                onChange={handleFileInputChange2}
                            />

                        </>

                    ))}
                    <button className='visa_multiple' onClick={handleAddFileInput2}>Add Passport Photograph &nbsp;&nbsp; +</button>

                </>
            }
            <br/>
        </div>
    );
};

export default MultipleFileInputs;
