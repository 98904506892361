import React, {useEffect, useRef, useState} from 'react';
import './SignUpOne.css';
import BlueBack from "./BlueBack";
import InputField from "../InputField/InputField";
import user from "../../images/user.png";
import sma from "../../images/sms.png";
import {Link} from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import axiosWithAuth from "../Axios/axiosWithAuth";
import Spinner from "../Spinner/Spinner";
import lock from "../../images/lock.png"
import envVariables from "../../dev";
import {Helmet} from "react-helmet-async";

let formData = new FormData();

export default function SignUpTwo() {
    const [isLoading, setIsLoading] = useState(false);

    const [step, setStep] = useState(1);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [formValid, setFormValid] = useState(false);

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");


    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };
    const validatePassword = (event) => {
        if (password.length < 8) {
            setPasswordError("Password must be at least 8 characters long.");
            setFormValid(false);
        } else if (!password.match(/[a-z]/)) {
            setPasswordError("Password must contain at least one lowercase letter.");
            setFormValid(false);
        } else if (!password.match(/[A-Z]/)) {
            setPasswordError("Password must contain at least one uppercase letter.");
            setFormValid(false);
        } else if (!password.match(/\d/)) {
            setPasswordError("Password must contain at least one number.");
            setFormValid(false);
        } else if (!password.match(/[@$!%*.?&]/)) {
            setPasswordError("Password must contain at least one special character.");
            setFormValid(false);
        } else if (password !== confirmPassword) {
            setPasswordError("Passwords do not match.");
            setFormValid(false);
        } else {
            setPasswordError("");
            setFormValid(true);
        }
    };
    useEffect(() => {
        const storedFullName = localStorage.getItem("fullName");
        const storedEmail = localStorage.getItem("email");
        validatePassword();

        if (storedFullName) {
            setFullName(storedFullName);
        }
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, [password, confirmPassword]);
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        validatePassword();
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        validatePassword();
    };
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if (formValid) {
    //         // save password to local storage
    //     }
    // };
    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleStepOneSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem("fullName", fullName);
        localStorage.setItem("email", email);

        handleNext()
    };
    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem("password", password);
        handleNext()
    }

    const [image, setImage] = useState(
        "https://res.cloudinary.com/dk80ynx1w/image/upload/v1678524294/Group_133533_mje81f.svg"
    );
    const fileInput = useRef(null);


    const handleButtonClick = () => {
        fileInput.current.click();
    };

    const handleImageUpload = (event) => {
        const imageFile = event.target.files[0];
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 500,
            useWebWorker: true,
        };

        if (imageFile.size > 1000000) {
            const errorDiv = document.querySelector('.passwordDetails');
            errorDiv.innerHTML = 'Images greater than 1MB might not be saved on our system.';
            errorDiv.style.color = 'yellow';
            try {
                imageCompression(imageFile, options).then((compressedImage) => {
                    const imageUrl = URL.createObjectURL(compressedImage);
                    setImage(imageUrl);

                    let filename = "filename.jpg";
                    if (compressedImage.type === "image/png") {
                        filename = "filename.png";
                    } else if (compressedImage.type === "image/gif") {
                        filename = "filename.gif";
                    }

                    const reader = new FileReader();
                    reader.readAsBinaryString(compressedImage);
                    reader.onload = () => {
                        const binaryString = reader.result;
                            formData.append("image", binaryString, filename);
                    };
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            setImage(URL.createObjectURL(imageFile));
            formData.append("image", imageFile); // append original image to formData
        }
        console.log(formData.get('image'))
    };

    const [username, setUsername] = useState("");

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const apikey = envVariables.APIKEY;


    function uploadUserData() {
        console.log(formData.get('image'))
        setIsLoading(true);
        const password = localStorage.getItem("password");
        const fullname = localStorage.getItem("fullName");
        const email = localStorage.getItem("email");

        formData.append("name", fullname);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("username", username);
        axiosWithAuth(apikey).post('user/create/', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                console.log(response.data);
                if (response.status === 200 || response.status === 201) {
                    console.log("response 200")
                    // Navigate to the next screen or perform any action you need to take
                    window.location.href = '#/login';

                } else {
                    const errorDiv = document.querySelector('.passwordDetails');
                    errorDiv.innerHTML = 'An error occurred while uploading user data. Please try again.';
                }
            })
            .catch((error) => {
                console.log(error);
                const errorDiv = document.querySelector('.passwordDetails');
                errorDiv.innerHTML = 'An error occurred while uploading user data. Please try again.';
                errorDiv.style.color = 'red'; // sets the font color to red
                // errorDiv.style.fontSize = '18px'; // sets the font size to 18 pixels
            })
            .finally(() => setIsLoading(false));
    }


    const handleFinalSubmit = (event) => {
        event.preventDefault();
        localStorage.setItem("username", username);
        uploadUserData();
    };

    return (
        <>
            <Helmet>
                <title>Sign Up - Jarom Travel Agency</title>

                <meta name="description" content="Create an account to book your next adventure with Jarom travel agency. Join us for exclusive deals and personalized travel experiences." />
                <meta name="keywords" content="travel, sign up, create account, travel deals, vacation packages, Flight Tickets, Visa Processing, International Exams" />
                <link rel="canonical" href="https://jaromtravels.com/signup/step" />
                <meta name="robots" content="index" />
                <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
                </script>
            </Helmet>
            <div>
                {step === 1 && (
                    <BlueBack containerClassName="SignUpStepOne">
                        <div className='stepperTop'>
                            <Link to="/signup">
                                <img className='backButton'
                                     src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678244681/arrow-left_u6g21o.png'
                                     alt='cancelButton'/>
                            </Link>
                            <img className='stepImg'
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678291013/Steps_fvmfmk.svg'
                                 alt='cancelButton'/>
                            <Link to="/signup">
                                <img className='cancelButton'
                                     src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                                     alt='cancelButton'/>
                            </Link>
                        </div>
                        <h1 className='firstOne'>
                            Create your Account.
                        </h1>
                        <div className='secOne'>Create an account, to get prompt updates<br/> on flight tickets and
                            cheap deals and offers
                        </div>
                        <form onSubmit={handleStepOneSubmit}>
                            <InputField classNameSg='sg sgx' value={fullName} onChange={handleFullNameChange}
                                        className="col-9 fi" name="Full Name" type="text" placeholder="Full name"
                                        required iconSrc={user}/>
                            <InputField classNameSg='sg' value={email} onChange={handleEmailChange} className="col-9 fi"
                                        name="Email" type="email" placeholder="Email" required iconSrc={sma}/>
                            <button type='submit' className='stepButton stepButtonMargin'>Next</button>
                        </form>


                    </BlueBack>
                )}
                {step === 2 && (
                    <BlueBack containerClassName="SignUpStepTwo">
                        <div className='stepperTop'>
                            <img className='backButton' onClick={handleBack}
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678244681/arrow-left_u6g21o.png'
                                 alt='cancelButton'/>
                            <img id='tip' className='stepImg'
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678312607/Steps_1_wy4fdi.svg'
                                 alt='Stepper'/>
                            <Link to="/signup">
                                <img className='cancelButton'
                                     src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                                     alt='cancelButton'/>
                            </Link>
                        </div>
                        <h1 className='firstOne'>
                            Create your Account.
                        </h1>
                        <div className='secOne'>Create a strong password to keep <br/> your account safe</div>
                        <InputField classNameSg='sg' className="col-9 fi" name="Password" type="password"
                                    placeholder="Password" onChange={handlePasswordChange} value={password}
                                    required iconSrc={lock}/>
                        {passwordError && (
                            <div className='passwordDetails'>At least 8 characters long<br/>A mixture of both UPPERCASE
                                and lowercase<br/>Must contain numbers<br/>Must include at least one special character,
                                e.g .,!@#^?</div>)}
                        {!passwordError && <div className='passwordDetails'></div>}
                        <InputField classNameSg='sg sgx' className="col-9 fi" name="Confirm password" type="password"
                                    placeholder="Confirm password" onChange={handleConfirmPasswordChange}
                                    value={confirmPassword} required={true} iconSrc={lock}/>
                        <button type='submit' onClick={handlePasswordSubmit} className='stepButton stepButtonMargin'
                        >Next
                        </button>

                    </BlueBack>
                )}
                {step === 3 && (
                    <BlueBack containerClassName="SignUpStepThree">
                        <div className='stepperTop'>
                            <img className='backButton' onClick={handleBack}
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678244681/arrow-left_u6g21o.png'
                                 alt='cancelButton'/>
                            <img id='tip' className='stepImg'
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678335273/Steps_x9anya.svg'
                                 alt='Stepper'/>
                            <Link to="/signup">
                                <img className='cancelButton'
                                     src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                                     alt='cancelButton'/>
                            </Link>
                        </div>
                        <h1 className='firstOne'>
                            Create your Account.
                        </h1>
                        <div className='secOne'>Choose your favourite header picture and <br/> set a unique username
                        </div>
                        <div>
                            <div className='profileDiv'>
                                {image && <img className='profile_picture' src={image} alt="Uploaded image"/>}
                                <button onClick={handleButtonClick}>Choose Avatar</button>
                                <input
                                    type="file"
                                    ref={fileInput}
                                    name='image'
                                    onChange={handleImageUpload}
                                    style={{display: "none"}}
                                />
                            </div>
                            <InputField classNameSg='sg' value={username} onChange={handleUsernameChange}
                                        className="col-9 fi" name="Username" type="text" placeholder="Username"
                                        required iconSrc={user}/>
                            <div className='passwordDetails'>
                                Username can contain only special character as _, - or .<br/>
                                Username can contain numbers<br/>
                                Username must not exceed 16 characters<br/>
                            </div>
                            <button onClick={handleFinalSubmit} disabled={isLoading} className='stepButton finish'>{isLoading ? <Spinner /> : 'Finish'}</button>

                        </div>
                    </BlueBack>
                )}
            </div>
        </>
    );

}
