import React from "react";
import SeatOption from "../Bookingpage/SeatOption";

export default function QuickOption ({quickoption, offersIndex,route, offers, mainRoute, passengerIndex, genarateQuickOptionSummary, handle_return}){

    const finalCount = mainRoute ? mainRoute.length : null;
    const finalRouteFrom= route ? route.cityFrom : null;
    const finalRouteTo= route ? route.cityTo : null;
    const quickoptionFinal = quickoption ? quickoption : null;




    return(
        <div key={offersIndex}>
            <div className="Seat1of3">
                <div className="seating">Seating {offersIndex + 1} of {finalCount}</div>
                <div>
                    <div className='dirTrip'>{finalRouteFrom} → {finalRouteTo}</div>

                </div>


            </div>

            <div className="Seatmapmaincont">
                <div className="SeatMapUnav">We don’t have a seat map for this flight</div>
                <div className="PreffOption">Please choose your preferred option below</div>
            </div>

            <div className="unavSeatOpCont">
                {quickoptionFinal.map((quickoption, quickoptionIndex) =>(
                    <div className="unavSeatOp" key={quickoptionIndex}>

                        <SeatOption
                            mainRoute={mainRoute}
                            quickOption={quickoption}
                            inputId={`RS_${offersIndex}_${quickoptionIndex}`}
                            seatMapIndex={offersIndex}
                            quickoptionIndex={quickoptionIndex}
                            name={`quickoptions`}
                            title={quickoption.option}
                            subtitle={quickoption.price.amount}
                            value={`RS ${offersIndex}`}
                            handle_return={handle_return}
                            genarateQuickOptionSummary={genarateQuickOptionSummary}


                        />

                    </div>
                ))
                }



            </div>

        </div>
    )
}