import React from "react";
import SupportMainSkeleton from "../SkeletonLoadingComponent/AboutSupportSkeleton/SupportMainSkeleton";

export default function SupportMain({loading}){
    return(
        <>
                <div className="SupportMainBody">

                    <div className="SupportMainContainer">
                        <div className="ContactUsText">
                            Reach out to us via:
                        </div>

                        <div className="ReachOutContainer">
                            {loading ?
                                <SupportMainSkeleton/>
                                :
                                <div className="phone">
                                <span className="stayPut">
                                     <img src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001690/JaromTravels/gtpj8alsl2dyuqrknrdj.svg" alt="phoneicon" className="phoneIcon Icon"/>
                                <p>+234 8140016639</p>
                                </span>

                                </div>

                            }

                            {loading ?
                                <SupportMainSkeleton/>
                                :
                                <div className="email">
                                <span className='stayPut'>
                                     <img src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001649/JaromTravels/tbscvfyyjzm80jyibcm5.svg" alt="Email icon" className="emailIcon Icon"/>
                                    <p>info@jaromtravels.com</p>
                                </span>

                                </div>


                            }

                            {loading ?
                                <SupportMainSkeleton/>
                                :
                                <div className="location">
                                <span className="stayPut">
                                    <img src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716001669/JaromTravels/eivwmopftufz0atjmutj.svg" alt="Location icon" className="locationIcon Icon"/>
                                <p>Abuja Novare  central office 1st floor shoprite building wuse zone 5 abuja, Nigeria</p>
                                </span>

                                </div>
                            }
                        </div>

                    </div>

                </div>

                <div className="OrText">
                    OR
                </div>
        </>

    )
}