import React, {useEffect, useState} from "react";
import Slider from 'rc-slider';
import './newIndex.css';
import DoubleSeeker from "./doubleSeeker";
import SingleSeeker from "./singleSeeker";
import Check from "./checkBox";
import Bags from "./bags";
import {encrypt} from "../Security";
import MultiCityStop from "./MultiCityStop";
import CheckBoxSkeleton from '../SkeletonLoadingComponent/FlightSearchPageSkeleton/CheckBoxSkeleton';


function Filter({filterAirlines,isFlight,airlines, isVisa, handleSubmit,price, unit, tprice, factor,checkloading, isavaliLoad}) {
    const [priceRange, setPriceRange] = useState([2, 25]); // Initial range values
    const [filterValue, setFilterValue] = useState('');



    const handlePriceRangeChange = (range) => {
        setPriceRange(range);
        localStorage.setItem('stopOver', JSON.stringify(range));
    };

    useEffect(() => {
        const storedValue = localStorage.getItem("stopOver");
        if (storedValue !== null) {
            const parsedValue = JSON.parse(storedValue);
            setPriceRange(parsedValue);
        }
    }, []);
    const currency = localStorage.getItem('Currency');
    if (currency === 'NGN') {

        price = 3000000;
        tprice = `₦${price*factor}`;
        unit = '₦'
        // localStorage.setItem('price',price*factor)
    } else if (currency === 'USD') {
        price = 4000
        unit = '$'
        tprice = `$${price*factor}`;
        // localStorage.setItem('price',price*factor)
    } else if (currency === 'EUR') {
        price = 4000
        unit = '€'
        tprice = `€${price*factor}`;
        // localStorage.setItem('price',price*factor)
    }


    const [checkedKey, setCheckedKey] = useState('anyStop');

    const handleCheckChange = (key, value) => {
        setCheckedKey(value === 'true' ? key : '');
        // Perform any additional actions based on the checkbox change
    };
    useEffect(() => {
        const storedValue = localStorage.getItem('checkedKey');
        if (storedValue) {
            setCheckedKey(storedValue);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('checkedKey', checkedKey);
    }, [checkedKey]);
    const handlePriceDrop = () => {
        handleSubmit();
    };
    const resetPrice = () =>{
        let value;
        const currency = localStorage.getItem('Currency');
        if (currency === 'NGN') {
            value = 3000000 * factor;
            localStorage.setItem("price",value)
        } else if (currency === 'USD') {
            value = 4000 * factor;
            localStorage.setItem("price",value)
        } else if (currency === 'EUR') {
            value = 4000 * factor;
            localStorage.setItem("price",value)
        }
        handleSubmit();
    };
    const resetHours = () =>{
        localStorage.setItem("maxTravelTime", 100)
        resetStopOver();
        handleSubmit();
    };
    const resetStops = () =>{
        setCheckedKey('anyStop');
        const storageKey = 'anyStop';
        const otherKeys = ['anyStop', 'directStop', 'upToOneStop', 'upToTwoStop'];
        otherKeys.forEach((key) => {
            if (key !== storageKey) {
                localStorage.setItem(key, 'false');
            }
        });
        if(otherKeys.includes(storageKey)){
            localStorage.setItem(storageKey, 'true');
            setTimeout(handleSubmit, 1000);
        }
    };
    const handleUncheckAll = () => {
        window.location.reload();
    };
    const resetStopOver = () =>{
        setPriceRange([2, 25]);
        localStorage.setItem('stopOver', JSON.stringify([2, 25]));
    }

    const resetOverall =()=>{
        resetPrice();
        resetStops();
        resetHours();
        localStorage.setItem('CabinBaggage', encrypt(String(0)));
        localStorage.setItem('CheckedBaggage', encrypt(String(0)));
        localStorage.setItem('departureTime', JSON.stringify([0,1439]));
        localStorage.setItem('arrivalTime', JSON.stringify([0,1439]));
        handleUncheckAll();
    }


    const [tripType, setTripType] = useState('' );
    // const [selectedOption, setSelectedOption] = useState(() => localStorage.getItem('Trip') || 'One Way');
    function checkLocalStorageValue (){
        if(localStorage.getItem("Trip")){
            setTripType(localStorage.getItem("Trip"));
        }else{
            setTripType('One Way')
        }
    }

    setInterval(checkLocalStorageValue, 500);

    // const tripClick = () => {
    //     const storedValue = localStorage.getItem('Trip');
    //         setTripType(storedValue);
    //         // window.location.reload();
    // };
    //
    // useEffect(() => {
    //     tripClick();
    //
    // }, []);

    const multiCitySTopStyle={
        height:(tripType === 'Multi-City' || tripType ===  'Multi-City (Cheapest)') ? '200px' : ''
    }


    return (
        <div>
            <div className="filterCase">
                <div className='filterHead'>Filter<span onClick={resetOverall}>Reset all</span></div>
                <div className='myHr'></div>
                <div className="enclose">
                    <SingleSeeker resetPrice={resetPrice} handleSubmit={handleSubmit} storageKey='price' className='priceReverse' unit={unit} start={0} stop={price*factor} initial='400000' header='PRICE' seconder={tprice}/>
                </div>
                <div className='myHr'></div>
                <div className="enclose">
                    <div className="topper">STOPS<span onClick={resetStops}>Resets</span></div>

                        <div className='filterCheckboxer' style={multiCitySTopStyle}>
                            {checkloading || isavaliLoad ?
                                <div>
                                    <CheckBoxSkeleton cardNum={10}/>
                                </div>

                                :

                                <div>
                                    {((tripType === 'Round Trip') || (tripType === 'One Way'))  &&
                                        <>
                                            <Check storageKey='anyStop' header='Any' onCheckChange={handleCheckChange}
                                                   isChecked={checkedKey === 'anyStop'} handleSubmit={handleSubmit} />
                                            <Check storageKey='directStop' header='Direct' onCheckChange={handleCheckChange}
                                                   isChecked={checkedKey === 'directStop'} handleSubmit={handleSubmit}/>
                                            <Check storageKey='upToOneStop' header='Up to 1 stop' onCheckChange={handleCheckChange}
                                                   isChecked={checkedKey === 'upToOneStop'} handleSubmit={handleSubmit}/>
                                            <Check storageKey='upToTwoStop' header='Up to 2 stop' onCheckChange={handleCheckChange}
                                                   isChecked={checkedKey === 'upToTwoStop'} handleSubmit={handleSubmit} />
                                        </>
                                    }
                                    {((tripType === 'Multi-City') || (tripType ===  'Multi-City (Cheapest)')) &&

                                        <>
                                            <MultiCityStop
                                                checkedKey={checkedKey}
                                                Destination='Lagos to Luton'
                                                hadleSubmitF={handleSubmit}
                                                handleCheckChangeF={handleCheckChange}
                                            />
                                            <br/>

                                            <MultiCityStop
                                                checkedKey={checkedKey}
                                                Destination='Lagos to Luton'
                                                hadleSubmitF={handleSubmit}
                                                handleCheckChangeF={handleCheckChange}
                                            />

                                            <br/>


                                            <MultiCityStop
                                                checkedKey={checkedKey}
                                                Destination='Lagos to Luton'
                                                hadleSubmitF={handleSubmit}
                                                handleCheckChangeF={handleCheckChange}
                                            />

                                        </>


                                    }

                                </div>

                            }



                        </div>


                </div>
                <div className='myHr'></div>
                <div className="enclose">
                    <div className="topper">BAGS<span></span></div>
                    <Bags handleSubmit={handleSubmit}  text='Cabin Baggage ' storageKey='CabinBaggage' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687356195/Group_oiz2re.svg' idtext='phil' id2='bagButton'/>
                    <Bags handleSubmit={handleSubmit} text='Checked Baggage' storageKey='CheckedBaggage' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687364001/Vector_clsaho.svg' id2='bagButton'/>
                </div>
                <div className='myHr'></div>
                <div className="enclose">
                    <div className="topper">AIRLINE<span onClick={handleUncheckAll}>Reset</span></div>
                    <div className='airSearch'>
                        <img src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687365000/search-normal_xqnk4l.svg' alt='airline search'/>
                        <input placeholder='Search Airlines' value={filterValue} onChange={e => setFilterValue(e.target.value)} type='text'/>
                    </div>

                    <div className='filterCheckboxer'>
                        {checkloading || isavaliLoad ?
                            <div>
                                <CheckBoxSkeleton cardNum={7}/>
                            </div>

                            :
                            <div>
                                {filterValue === '' ? (
                                    // Display all items when the filterValue is empty
                                    airlines.map(pair => (<>

                                            {/*<CheckBoxSkeleton cardNum={pair.id}/>*/}

                                            <Check
                                                key={pair.id}
                                                filterAirlines={filterAirlines}
                                                name={pair.id}
                                                storageKey={pair.id}
                                                header={pair.name}

                                            />
                                        </>
                                    ))
                                ) : (
                                    // Apply filtering when the filterValue is not empty
                                    airlines
                                        .filter(pair => pair.name.toLowerCase().includes(filterValue.toLowerCase()))
                                        .map(pair => (
                                            <Check
                                                key={pair.id}
                                                filterAirlines={filterAirlines}
                                                name={pair.id}
                                                storageKey={pair.id}
                                                header={pair.name}
                                            />
                                        ))
                                )}
                            </div>

                        }
                    </div>
                </div>
                <div className='myHr'></div>
                <div className="enclose">
                    <SingleSeeker resetPrice={resetHours} handleSubmit={handleSubmit} storageKey='maxTravelTime' className='price' start={0} stop={100} unit='hours' initial='20' seconder='Up to 100 hours'  header='MAX TRAVEL TIME'/>
                </div>
                <div className="enclose">
                    <div className='topper'>STOPOVER</div>
                    <div className='first'>
                        2-25 hours <span></span>
                    </div>
                    <Slider
                        range
                        min={2}
                        max={25}
                        value={priceRange}
                        onChange={handlePriceRangeChange}
                        onAfterChange={handlePriceDrop}
                    />
                    <div className='sec'>
                        {priceRange[0]}hours
                        <span>{priceRange[1]}hours</span>
                    </div>

                </div>
                <div className='myHr'></div>
                <div className="enclose">
                    <DoubleSeeker storageKey='departureTime' header='DEPARTURE TIME' second='All day'/>
                    <DoubleSeeker storageKey='arrivalTime' handleSubmit={handleSubmit} header='ARRIVAL TIME' second='All day'/>
                </div>

            </div>
        </div>
    );
}

export default Filter;
