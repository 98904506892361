import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../ManageBookingPage/manageBooking.css';


export default function ManagebookingSkeleton (){
    return(
        <div style={{padding: 'none', background: 'transparent'}} className="propsContainer" >
            {<Skeleton height={340} width={200}/>}
        </div>
    )
}