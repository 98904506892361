import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../FlightSearch/search.css';


export default function CheckBoxSkeleton({cardNum}){
    return(
        Array(cardNum)
            .fill(0)
            .map( (numToDisplay) =>(
                <div className="visa_lcheck">
                    <div className="custom-checkbox filter-checkbox">
                        {<Skeleton width={100} />}
                    </div>
                    <div className={`logged checked`} id="visa_tick">
                        {<Skeleton width={170}/>}
                    </div>
                </div>
            )))

}