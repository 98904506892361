import React, {useEffect, useRef, useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"
import {Link, useLocation, useNavigate} from "react-router-dom";
import getCookie from "./Login/getCookie";
import axios from "axios";

export default function Header({get}){

    const navigate = useNavigate();

    const getStarted = () => {
        navigate("/");
        setTimeout(() => {
            if (get.current) {
                get.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };
    const [selectedItem, setSelectedItem] = useState(null);
    const [isTrue, setIsTrue] = useState(() => false)
    const location = useLocation();
    const [showFirst, setShowFirst] = useState(true);
    const [showSecond, setShowSecond] = useState(false);

    useEffect(() =>{
            if (location.pathname === '/visa') {
                setIsTrue(true);
            } else if (location.pathname === '/services') {// when support page is routed it will be fixed
                setIsTrue(true);
            } else if (location.pathname === '/Support'){
                setIsTrue(true);
            }else if (location.pathname === '/AboutUs') {
                setIsTrue(true);
            } else {
                setSelectedItem(false);
            }
        },
        [isTrue]
    )

    useEffect(() => {
        // Update selectedItem state when the location changes
        if (location.pathname === '/') {
            setSelectedItem('item1');
        }
        else if (location.pathname === '/visa') {
            setSelectedItem('item2');
        } else if (location.pathname === '/ourservices') {// when support page is routed it will be fixed
            setSelectedItem('item3');
        } else if (location.pathname === '/Support'){
            setSelectedItem('item4');
        }else if (location.pathname === '/AboutUs') {
            setSelectedItem('item5');
        } else {
            setSelectedItem(null);
        }
    }, [location]);

    function handleItemClick(item) {

        if (location.pathname === '/services') {// when support page is routed it will be fixed
            alert('Page Under Construction');
        }
        setSelectedItem(item);

    }
    const [showDiv, setShowDiv] = useState(false);
    const divRef = useRef(null);
    const divRef2 = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setShowDiv(!showDiv);

                if (divRef2.current) {
                    divRef2.current.click();
                }
            }
        };


        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showDiv]);

    const handleClickFirst = () => {
        setShowFirst(false);
        setShowSecond(true);
        setShowDiv(!showDiv);
    };

    const handleDivClick = () => {
        setShowFirst(true);
        setShowSecond(false);
        setShowDiv(showDiv);
    };
    const SignUp = () => {

        window.location.href = '#/signup';

    };



    const [imageData, setImageData] = useState(null);

    // let userProfile = localStorage.getItem('userProfile') ? JSON.parse(localStorage.getItem('userProfile')) : '';

    const token = getCookie('AUTH-Token');

    function callAPI (){
        const headers = {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json' // Example content type, adjust as needed
        };

        axios.get('https://api.jaromtravels.com/api/user/me/', {headers})
            .then(response =>{
                // localStorage.setItem('userProfile', JSON.stringify(response.data))
                setImageData(response.data)
                // console.log('x')
            })
            .catch((err)=>{
                console.error(err)
            })
    }

    useEffect(() => {
        if(token){
            callAPI();
        }

    }, [token]);

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }



        

    return (
        <div>
            <div className='fixWidth'>
                <nav>
                    <Link to='/'>
                        <LazyLoadImage effect="blur" className='logo'
                                       src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1683163940/nakedjarome_1_xfopye.svg" alt='Logo'/>
                    </Link>

                    <div className='nav_d'>
                        <div className="userImg" style={{cursor: "pointer"}}>
                            <Link to='/signup' >
                                 <img onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})} src={imageData ? imageData.image : 'http://res.cloudinary.com/dk80ynx1w/image/upload/v1715994801/JaromTravels/a5giijgck1wetqievvr0.png'} alt="User Avater"/>
                            </Link>
                        </div>
                        <section className="p-menu1">
                            <div id="navbar" className="navigation" role="navigation">
                                <input id="toggle1" type="checkbox"/>
                                <label className="hamburger1" htmlFor="toggle1">
                                    {showFirst && (
                                        <div className="top" onClick={handleClickFirst}></div>
                                    )}
                                    {showSecond && (
                                        <div className="below" ref={divRef2} onClick={handleDivClick}></div>
                                    )}
                                </label>
                                {showDiv &&
                                    <div className="menu1" ref={divRef}>
                                        <Link to='/' className="link1">
                                            <a onClick={scrollTop} className="link1" href="">Flight</a>
                                        </Link>
                                        <Link to='/visa' className="link1">
                                            <a onClick={scrollTop} className="link1" href="">Visa</a>
                                        </Link>
                                        <Link to='/ourservices' className="link1">
                                            <a onClick={scrollTop} className="link1" href="">Our Service</a>
                                        </Link>
                                        <Link to='/Support' className="link1">
                                            <a onClick={scrollTop} className="link1" href="">Support</a>
                                        </Link>

                                        <Link to='/AboutUs' className="link1">
                                            <a onClick={scrollTop} className="link1" href="">About Us</a>
                                        </Link>

                                        <Link to='/signup' className="link1">
                                            <button onClick={scrollTop} className="buttonBlue menuSize">Sign up</button>
                                        </Link>

                                    </div>
                                }
                            </div>
                        </section>
                        <div className='hideAndSeek'>
                            <div className='navContainer'>
                                <Link to='/' onClick={scrollTop} className='link2'>
                                    <div className={selectedItem === 'item1' ? 'clickedActive' : ''}
                                         onClick={() => handleItemClick('item1')}>Flight
                                    </div>
                                </Link>
                                <Link to='/visa' onClick={scrollTop} className='link2'>
                                    <div
                                        className={selectedItem === 'item2' ? 'clickedActive' : ''}
                                        onClick={() => handleItemClick('item2')}>Visa
                                    </div>
                                </Link>
                                <Link to='/ourservices' onClick={scrollTop} className='link2'>
                                    <div
                                        className={selectedItem === 'item3' ? 'clickedActive' : ''}
                                        onClick={() => handleItemClick('item3')}>Our services
                                    </div>
                                </Link>
                                <Link to='/Support' onClick={scrollTop} className='link2'>
                                    <div
                                        className={selectedItem === 'item4' ? 'clickedActive' : ''}
                                        onClick={() => handleItemClick('item4')}>Support
                                    </div>
                                </Link>
                                <Link to='/AboutUs' onClick={scrollTop} className='link2'>
                                    <div className={selectedItem === 'item5' ? 'clickedActive' : ''}
                                         onClick={() => handleItemClick('item5')}>About Us
                                    </div>
                                </Link>

                                <Link to='/managebookingpage' onClick={scrollTop} className='link2'>
                                    <div className='navControl'>Manage your Booking</div>
                                </Link>
                                <div>
                                     <button className='nav_b' onClick={getStarted}>Get Started</button>
                                </div>
                            </div>


                        </div>


                    </div>
                </nav>
            </div>
        </div>
    );

}