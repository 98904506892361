import React, {useState} from "react";
import './ourservices.css'


export default function OurServicesComp({recievedState ,mainText1Half, subText, imgClass, makeRight, mainText2Half, fullText }){
    const[fullContent, setFullContent] = useState(false);
    const[showForm, setShowForm] = useState(false);



    const tooglefunc = ()=>{
        setFullContent(!fullContent)
    }

    const handleClick =()=>{
        setShowForm(!showForm)
        recievedState(showForm)
    }



    return(
        <div className="servCompcont">
            {/*{showForm &&*/}

            {/*}*/}


            <div className="servComp">
                <div className={imgClass} id={makeRight}>
                    <h2 className="servCompHeader">{mainText1Half} <br/> {mainText2Half}</h2>
                    <div className="servCompSubText">
                        {fullContent? fullText : subText} <span className='makrReadBold' onClick={tooglefunc}>{fullContent ? '...Read Less' : '...Read More'}</span>
                    </div>

                        <button className="buttonServ" onClick={handleClick}>
                            <div className="btnTextos">Get Started</div>
                            <div className="btnArros"><img src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg" alt=""/></div>
                        </button>

                </div>
            </div>

        </div>
    )
}