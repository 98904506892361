import React from "react";

export default function NoSeatMapandQuickOpt({route, offersIndex, mainRoute}){
    const styleforText={
        textAlign: 'center',
        width: '70%'
    }
    return(
        <div>
            <div className="Seat1of3">
                <div className="seating">Seating {offersIndex + 1} of {mainRoute.length}</div>
                <div>
                    <div className='dirTrip'>{route.cityFrom}  →  {route.cityTo}</div>

                </div>


            </div>

            <div className="Seatmapmaincont">
                <div className="SeatMapUnav">Seat Map and Quick Option for this flight is Unavailable</div>
                <div className="PreffOption">Seat Will be assigned automatically</div>
            </div>

        </div>
    )
}