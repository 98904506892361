import React from 'react'
import './404Page.css'
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";

export default function ErrorPage(){
    return(

        <>
            <Helmet>
                <title>Page Not Found - Jarom Travel Agency</title>
                <meta name="robots" content="index" />
                <meta name="title" content="Page Not Found - Jarom Travel Agency" />
                <meta name="description" content="The page you are looking for does not exist. Please check the URL or return to the homepage." />
                <meta name="keywords" content="404, page not found, error page, travel agency" />
                <link rel="canonical" href="https://jaromtravels.com/ourservices" />

                <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
                </script>
            </Helmet>

            <div className='parent'>
                <div className="child">
                    <img className='errimg' src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716019111/JaromTravels/eushffjloyjoo2wkqg19.svg" alt="404"/>
                    <h1>Page  not found </h1>
                    <p>This Page doesn`t exist or was removed! We suggest you  back to home.</p>
                    <Link to='/'>
                        <div className="unavailableBtn">
                            <img className='white-icon' src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg" alt="back-arrow"/>
                            Go back home
                        </div>
                    </Link>
                </div>

            </div>
        </>
    )
}
