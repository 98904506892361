import React from 'react';
import TitleDropdown from "./titleDropdown";

function VisaSidder({className, name, required}) {
    const tour = ['Tourist Visa', 'Visit Visa', 'Work Visa', 'Student Visa', 'Business Visa']

    return (
        <div className={className}>
            <div className="visa_selection">
                <div className="title">
                    Select Visa Type
                </div>

                <div className='visa_inputField ' id='inputTop'>
                    <div className='visaKeeper'>
                        <TitleDropdown required={required} name={name} margin='auto auto 24px' mxfont='14px' mfont='8px' height='24px' width='100%' localStorageName='VisaType'
                                       options={tour} title="Visa Type" className='visa_input'/>
                    </div>

                </div>
            </div>
            <div className="visa_timing">
                Visa Processing Takes 3-5 working days except Fridays
            </div>
            <div className='visa_moreInfo'>
                For More information<br/>
                <span>
                    Call - 2345 5556 888<br/>
                    Email - info@jaromtravels.com
                </span>
            </div>
            <div className="visa_cc">
                <span>Please note that your visa would be processed in accordance with your travel date in view. This is to ensure visa validity covers entry into your country of choice.</span>
            </div>
        </div>
    );
}

export default VisaSidder;
