import React from 'react';
import './SignUpOne.css';
import BlueBack from "./BlueBack";
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet-async";

export default function SignUpOne() {
    return (
      <>
          <Helmet>
              <title>Sign Up - Jarom Travel Agency</title>
              <meta name="robots" content="index" />
              <meta name="description" content="Create an account to book your next adventure with Jarom travel agency. Join us for exclusive deals and personalized travel experiences." />
              <meta name="keywords" content="travel, sign up, create account, travel deals, vacation packages, Flight Tickets, Visa Processing, International Exams" />
              <link rel="canonical" href="https://jaromtravels.com/signup" />
              <script type="application/ld+json">
                  {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
              </script>
          </Helmet>
          <div>
              <BlueBack containerClassName="SignUpBack">
                  <Link to='/'>
                      <img id='SignUpBack-cancelButton' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png' alt='cancelButton'/>
                  </Link>
                  <h1 className='firstOne'>
                      Create your Account.
                  </h1>
                  <div className='secOne' >Create an account, to get prompt updates<br/>on flight tickets and cheap deals and offers</div>
                  <button className='socialButtons'><img alt='googleLogo' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678228461/flat-color-icons_google_j3ofzp.png'/>Sign up with Google</button>
                  <button className='socialButtons'><img alt='faceBookLogo' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678228905/ci_apple_xdoib0.png'/>Sign up with FaceBook</button>
                  <div className='liner'><hr/><span className='or'>or</span><hr/></div>
                  <Link to="/signup/step">
                      <button className='socialButtons'><img alt='emailLogo' src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678234855/sms_s0z2mu.png'/>Sign up with Email</button>
                  </Link>
                  <div className='thi'>By signing up, you agree to the <span>Terms of Service</span> and<br/> <span>Privacy Policy</span>, including <span>Cookie use.</span></div>
                  <div className='blueBack-fort'>Have an account already?<span>
                    <Link to ="/login">
                    Log in
                </Link>
                </span>. </div>
              </BlueBack>

          </div>
      </>
    );
}

