import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../FlightSearch/search.css';


export default function TicketCardSkeleton ({cardNum}){
    const skele={
        padding:'10px'
    }
    const skele2={
        background:'transparent',
        border: 'none'
    }

    return(
        Array(cardNum)
            .fill(0)
            .map( (numToDisplay) =>(
                <div className="flightCover"  key={numToDisplay.id}>
                    <div className='flightDetails'>
                        <div className='detailCover' style={skele}>
                            {<Skeleton containerClassName="flex-1" height={130}/>}
                        </div>
                        <div className='moreDetails' style={skele2}>

                            {<Skeleton width={100} height={20}/>}
                        </div>
                    </div>
                    <div className='ticketCase'>
                        <div className='ticketPricing'>
                            <div className='price'>
                                {<Skeleton width={70} height={20}/>}
                            </div>

                            <div>
                                <Skeleton width={150} height={30}/>
                            </div>
                        </div>
                    </div>
                </div>

            )))
}

