import React, {useState} from "react";
import VisaInputField from "../Visa/visaInput";
import VisaDoubleInputField from "../Visa/visaDoubleInput";
import envVariables from "../../dev";
import axiosWithAuth from "../Axios/axiosWithAuth";
import {toast} from "react-toastify";

export default function TourVacation(props){
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState(null);

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (e.target.checkValidity()) {
            const TouringPackage ={
                'First_Name': localStorage.getItem('TourFirstNameOS')  ?  localStorage.getItem('TourFirstNameOS'): '',
                'Last_Name': localStorage.getItem('TourSurnameOS')  ?  localStorage.getItem('TourSurnameOS'): '',
                'Email': localStorage.getItem('TourEmailOS')  ?  localStorage.getItem('TourEmailOS'): '',
                'Phone_Number_code': localStorage.getItem('TourPhoneNumberCodeOS')  ?  localStorage.getItem('TourPhoneNumberCodeOS'): '',
                'Phone_Number': localStorage.getItem('TourPhoneNumberOS')  ?  localStorage.getItem('TourPhoneNumberOS'): '',
                'Choice_of_country(ies)': localStorage.getItem('TourChoiceOfCountryOS')  ?  localStorage.getItem('TourChoiceOfCountryOS'): '',
                'No_adult': localStorage.getItem('TourAdultNoOS')  ?  localStorage.getItem('TourAdultNoOS'): '',
                'No_children': localStorage.getItem('TourChildrenNoOS')  ?  localStorage.getItem('TourChildrenNoOS'): '',
                'No_Infants': localStorage.getItem('TourInfantsNoOS')  ?  localStorage.getItem('TourInfantsNoOS'): '',
                'Travelling_Date': localStorage.getItem('TourTravellingDateOS')  ?  localStorage.getItem('TourTravellingDateOS'): '',
                'Return_Date': localStorage.getItem('TourReturnDateOS')  ?  localStorage.getItem('TourReturnDateOS'): '',
            }


            const form_data = {
                'Touring Package': TouringPackage,
            };

            setPopUp('Loading..')



            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Touring Package(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                if (response.status === 201) {
                    setPopUp('submitted');
                    toast.success("Form submitted successfully");
                    props.ReceiveStateTouring(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed');
                toast.error("Failed to submit form");

            }
        }


    };

    return(
        <>
            <form className='blurred-div'  onSubmit={(e)=>handleSubmit(e)} ref={props.close}>
                <div className="blueHeader">
                    Personal Information
                </div>
                <div className="IELTS1">
                    <VisaInputField labelName='Surname' star='*' type='text'
                                    localStorageName='TourSurnameOS'
                                    placeholder='Surname as on intl. passport' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='First name' star='*' type='text'
                                    localStorageName='TourFirstNameOS'
                                    placeholder='First Name as on intl. passport' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='Email Address' star='*' type='email'
                                    localStorageName='TourEmailOS'
                                    placeholder='xyz@gmailcom' required={true}>

                    </VisaInputField>

                    <div className="IELTSInner">
                        <VisaDoubleInputField localStorageName='TourPhoneNumberOS'
                                              localTitleStorage='TourPhoneNumberCodeOS'
                                              labelName='Phone Number' star='*'
                                              firstPlaceHolder='+234' type='tel'
                                              placeholder='Phone Number' value_i='+234' required={true}>
                        </VisaDoubleInputField>
                    </div>


                    <VisaInputField labelName='Choice Of Country(ies)' star='*' type='text'
                                    localStorageName='TourChoiceOfCountryOS'
                                    placeholder='Choice Of Country(ies)' required={true}>

                    </VisaInputField>
                </div>



                <div className="blueHeader">
                   Travel Details
                </div>
                <div className="IELTS1">

                    <VisaInputField labelName='Number Of Adults (12 Years Above)' star='*' type='number'
                                    localStorageName='TourAdultNoOS'
                                    placeholder='Number Of Adults (12 Years Above)' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='Number Of Children (2 - 11 Years)' star='*' type='number'
                                    localStorageName='TourChildrenNoOS'
                                    placeholder='Number Of Children (2 - 11 Years)' required={true}>

                    </VisaInputField>
                    <VisaInputField labelName='Number Of Infants (0 - 2 Years)' star='*' type='number'
                                    localStorageName='TourInfantsNoOS'
                                    placeholder='Number Of Infants' required={true}>

                    </VisaInputField>
                    <div className="IELTSInner2">
                        <VisaInputField labelName='Intend Travelling date' star='*' type='date'
                                        localStorageName='TourTravellingDateOS'
                                        placeholder='DD-MM-YY' required={true}>

                        </VisaInputField>
                        <VisaInputField labelName='Return Date' star='*' type='date'
                                        localStorageName='TourReturnDateOS'
                                        placeholder='DD-MM-YY' required={true}>

                        </VisaInputField>

                    </div>
                </div>


                <div className="centerBtn">
                    <div className='visa_casing'>
                        <button className='visa_next' type='submit'>
                            {PopUp? PopUp : 'Submit Form'}<span><img
                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                        </button>
                        <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveStateTouring(false)}>
                            <img id='visaPrev'
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                        </button>
                    </div>
                </div>

            </form>
        </>
    )
}