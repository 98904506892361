import React, {useState} from 'react'


export default function MainContSSF({ classInfo, onSelect, onDeselect, isSelected, boolVal, onSelect2 }){

    let localStorageKeys_to_remove1 = [];
    function customStringify(obj) {
        const seen = new WeakSet();

        function replacer(key, value) {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return "[Circular Reference]";
                }
                seen.add(value);
            }
            return value;
        }

        return JSON.stringify(obj, replacer);
    }

    const set_to_local = (dataToStore, key_name) => {
        const data_stringify = customStringify(dataToStore);
        localStorage.setItem(key_name, data_stringify);
        localStorageKeys_to_remove1.push(key_name);
    }
    const capitalizeFirstChar = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const refined =  capitalizeFirstChar(classInfo.ticket);
    const refinedpkg = capitalizeFirstChar(classInfo.rules.service_package)

    const handleSelect = () => {
        if (isSelected) {
            console.log(isSelected, 'is')
            onSelect(null);
            onSelect2(null);

        } else {
            onSelect(price_);
            onSelect2(refined)
            set_to_local(classInfo, 'ticket_type_data')
            console.log(classInfo)
        }
    }

    const priceeee = classInfo.price.amount;


    const currency = localStorage.getItem('Currency');

    let currSign = ''
    let price_ = '';
    if (currency === 'NGN') {
        price_ = Number(priceeee).toLocaleString('en-NG', { maximumFractionDigits: 2 });
        price_ = `₦${price_}`;
        currSign = '₦'
    }
    else if (currency === 'USD') {
        price_ = Number(priceeee).toLocaleString('en-US', { maximumFractionDigits: 2 });
        price_ = `$${price_}`;
        currSign ='$'
    }
    else if (currency === 'EUR') {
        price_ = Number(priceeee).toLocaleString('en-GB', { maximumFractionDigits: 2 });
        price_ = `€${price_}`;
        currSign='€'
    }




    const style ={
        position: 'relative',
        border: isSelected ? '1.2px solid #054DAB' : '',
        background: isSelected ? '#FAFCFF' : '',
        boxShadow: isSelected ? '0px 2.3999950885772705px 9.599980354309082px 0px rgba(0, 0, 0, 0.06)' : ''
}

    const style2 ={
        color: isSelected ? '#FAFCFF' : '',
        background: isSelected ? '#054DAB' : '',
        boxShadow: isSelected ? '0px 2.3999950885772705px 9.599980354309082px 0px rgba(0, 0, 0, 0.06)' : ''
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);



    return(
        <div className={`mainContSSF`} style={style}>
            {classInfo.ticket === 'standard' && <div className="recom">Recommended</div>}
            <div className="row1SSF">

                <div className="SSFTitleFeatuerCont">

                    <div className="SSFTitle">
                        {refined}
                    </div>

                    <div className="SSFFeatuer">
                        <ul>
                            <li>
                                <img
                                    src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716006719/JaromTravels/ndasvj6wi31vjb0yclfu.svg"
                                    alt="green mark" className="blueDot"/>
                                {/*<img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691364280/starpenzu/mdi_tick_kdxibi.svg" alt="green mark"/>*/}
                                <div
                                    className="intextSSF ssfUnder"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >{refinedpkg} Services</div>
                                {isHovered && (
                                    <ul  className='ticketHov'>
                                       <li>Low caller priority</li>
                                       <li>No email support</li>
                                       <li>30 € processing fee for additional services</li>
                                    </ul>
                                )}
                            <div>

                            </div>
                            </li>
                            <li>
                                <img
                                    src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716006719/JaromTravels/ndasvj6wi31vjb0yclfu.svg"
                                    alt="green mark" className="blueDot"/>
                                {/*<img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691364280/starpenzu/iconoir_cancel_zbhihl.svg" alt="green mark"/>*/}
                                <div className="intextSSF ssfMiddle">{classInfo.rules.refund_pct === null ? '0' : classInfo.rules.refund_pct}% Refundable when you decide to cancel</div>
                            </li>
                            <li>
                                <img
                                    src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716006719/JaromTravels/ndasvj6wi31vjb0yclfu.svg"
                                    alt="green mark" className="blueDot"/>
                                {/*<img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691364280/starpenzu/iconoir_cancel_zbhihl.svg" alt="green mark"/>*/}
                                <div className="intextSSF">Non-refundable for the first {classInfo.rules.non_refundable_period} hours after purchase.{}</div>
                            </li>

                            <li>
                                <img
                                    src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716006719/JaromTravels/ndasvj6wi31vjb0yclfu.svg"
                                    alt="green mark" className="blueDot"/>
                                {/*<img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1691364280/starpenzu/iconoir_cancel_zbhihl.svg" alt="green mark"/>*/}
                                <div className="intextSSF">Service are free of charge within the last {classInfo.rules.change_period} hours</div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div className="row2SSF">
                <div className="priceSSF">
                    {price_}
                </div>

                <div className="btnSSF" style={style2} onClick={handleSelect}>Choose Plan</div>
            </div>
        </div>

    )
}