import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../Support/SupportPage.css';


export default function MoreInfoSkeleton ({cardNum, typeOfScreen}){

    const style2 = {
        position: 'unset'
    };
    const style = {
        display: 'inline-flex',
        padding: 'none',
        width: '100%'
    };
    const style3 = {
        boxShadow: 'none',
        background: 'none',
        marginBottom: '10px'
    };

    return(
                    <div className="moreInfoCont" style={style2} >

                        {typeOfScreen === 'mobile' &&
                            <div className='more-info-Booking' style={style2}>
                                <div className='itenery' style={style}><Skeleton width={100}/></div>
                                <div className='transport' style={style} containerClassName="flex-1">{<Skeleton width={40}/>} → {<Skeleton width={40}/>}<span style={style}>Duration: <span style={style}><Skeleton width={40}/></span></span></div>
                                <div>
                                    {Array(cardNum)
                                        .fill(0)
                                        .map( (numToDisplay) =>(
                                            <div style={style3} key={numToDisplay.id} containerClassName="flex-1" className='stops'>
                                                <Skeleton width={255} height={119}/>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        }

                        {typeOfScreen === 'tab' &&
                            <div className='more-info-Booking' style={style2}>
                                <div className='itenery' style={style}><Skeleton width={100}/></div>
                                <div className='transport' style={style} containerClassName="flex-1">{<Skeleton width={60}/>} → {<Skeleton width={60}/>}<span style={style}>Duration: <span style={style}><Skeleton width={60}/></span></span></div>
                                <div>
                                    {Array(cardNum)
                                        .fill(0)
                                        .map( (numToDisplay) =>(
                                            <div style={style3} key={numToDisplay.id} containerClassName="flex-1" className='stops'>
                                                <Skeleton width={285} height={119}/>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        }

                        {typeOfScreen === 'desktop' &&
                            <div className='more-info-Booking' style={style2}>
                                <div className='itenery' style={style}><Skeleton width={100}/></div>
                                <div className='transport' style={style} containerClassName="flex-1">{<Skeleton width={80}/>} → {<Skeleton width={80}/>}<span style={style}>Duration: <span style={style}><Skeleton width={80}/></span></span></div>
                                <div>
                                    {Array(cardNum)
                                        .fill(0)
                                        .map( (numToDisplay) =>(
                                            <div style={style3} key={numToDisplay.id} containerClassName="flex-1" className='stops'>
                                                <Skeleton width={410} height={119}/>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        }


                    </div>
    )
}