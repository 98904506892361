import React, {useEffect, useRef, useState} from "react";
import './ourservices.css'
import IELTSForm from './IELTSForm'
import OurServicesComp from "./OurServicesComp";
import TourVacation from "./TourVacation";
import AdmissionInfo from "./AdmissionInfo";
import BirthServices from "./birthServices";
import PermanentResidency from "./permanentResidency";
import {Helmet} from "react-helmet-async";
import CarHireForm from "./CarHireForm";
import JetHireForm from "./JetHireForm";
import CareGiverForm from "./CareGiverForm";
import HoneyMoonForm from "./HoneyMoonForm";
import HotelReservationForm from "./HotelReservationForm";
import AirportPickupForm from "./AirportPickupForm";

const services = {
    IELTS: "IELTS",
    Touring: "Touring",
    AdmissionInfo: "AdmissionInfo",
    Visa: "Visa",
    Birth: "Birth",
    Relocation: "Relocation",
    CarHire: 'CarHire',
    JetHire: 'JetHire',
    CareGiver: 'CareGiver',
    HoneyMoon: 'HoneyMoon',
    HotelReservation: 'HotelReservation ',
    AirportPickup: 'AirportPickup'
};

export default function OurServices() {
    const [activeService, setActiveService] = useState(null);
    const diceRef = useRef(null);

    const setServiceState = (service) => {
        setActiveService(service);
        document.body.style.overflow = service ? 'hidden' : 'auto';
    };

    const handleOutsideClick = (event) => {
        if (diceRef.current && !diceRef.current.contains(event.target)) {
            setServiceState(null);
        }
    };

    const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
            setServiceState(null);
        }
    };

    useEffect(() => {
        if (activeService) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleEscapeKey);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [activeService]);

    return (
        <>
            <Helmet>
                <title>Our Services - Jarom Travel Agency</title>
                <meta name="robots" content="index" />
                <meta name="title" content="Our Services - Jarom Travel Agency" />
                <meta name="description" content="Explore the range of services we offer, including IELTS Training & Reg., Touring Package, Admission Processing, Permanent Residency, Birth Service Abroad and more." />
                <meta name="keywords" content="IELTS Training & Registration, Touring Package, Admission Processing, Permanent Residency in Canada, Birth Service Abroad" />
                <link rel="canonical" href="https://jaromtravels.com/ourservices" />
                <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
                </script>
            </Helmet>
            <div className='forceMiddle' >
                <div className="oursercont">
                    <div className="ourserheadercont">
                        <h1 className="ourserheader">Our Services</h1>
                        <div className="ourserheadertext">
                            We are committed to giving a quality services for you.<br />
                            As we deliver on time and give you a comfortable, affordable,<br />
                            yet high quality service that suits you best.
                        </div>
                    </div>

                    <div >
                        {activeService === services.IELTS && (
                            <div className="blurred-background">
                                <IELTSForm close={diceRef} ReceiveStateIELTS={() => setServiceState(null)} />
                            </div>
                        )}
                        {activeService === services.Touring && (
                            <div className="blurred-background">
                                <TourVacation close={diceRef} ReceiveStateTouring={() => setServiceState(null)} />
                            </div>
                        )}
                        {activeService === services.AdmissionInfo && (
                            <div className="blurred-background">
                                <AdmissionInfo close={diceRef} ReceiveStateadmissionInfo={() => setServiceState(null)} />
                            </div>
                        )}

                        {activeService === services.Birth && (
                            <div className="blurred-background">
                                <BirthServices  close={diceRef} ReceiveStateIELTS={() => setServiceState(null)} />
                            </div>
                        )}
                        {activeService === services.Relocation && (
                            <div className="blurred-background">
                                <PermanentResidency close={diceRef} ReceivePermanentResidency={() => setServiceState(null)} />
                            </div>
                        )}

                        {activeService === services.CarHire && (
                            <div className="blurred-background">
                                <CarHireForm close={diceRef} ReceiveCarHireForm={() => setServiceState(null)} />
                            </div>

                        )}

                        {activeService === services.JetHire && (
                            <div className="blurred-background">
                                <JetHireForm close={diceRef} ReceiveJetHireForm={() => setServiceState(null)} />
                            </div>

                        )}

                        {activeService === services.CareGiver && (
                            <div className="blurred-background">
                                <CareGiverForm close={diceRef} ReceiveJetHireForm={() => setServiceState(null)} />
                            </div>

                        )}

                        {activeService === services.HoneyMoon && (
                            <div className="blurred-background">
                                <HoneyMoonForm close={diceRef} ReceiveJetHireForm={() => setServiceState(null)} />
                            </div>

                        )}

                        {activeService === services.HotelReservation && (
                            <div className="blurred-background">
                                <HotelReservationForm close={diceRef} ReceiveJetHireForm={() => setServiceState(null)} />
                            </div>

                        )}

                        {activeService === services.AirportPickup && (
                            <div className="blurred-background">
                                <AirportPickupForm close={diceRef} ReceiveJetHireForm={() => setServiceState(null)} />
                            </div>

                        )}



                        <OurServicesComp
                            recievedState={() => setServiceState(services.IELTS)}
                            imgClass='servBg'
                            mainText1Half='IELTS Training'
                            mainText2Half='& Registration'
                            fullText='The International English Language Testing System (IELTS) is a globally recognized standardized test for assessing English language proficiency for study, work, and residency purposes. Managed jointly by the British Council and IDP, it is essential for individuals planning to pursue opportunities abroad in countries such as Canada, Australia, Schengen, USA, and Ireland.
                        If your goal is to study abroad, the IELTS Academic Test is required. For visa, permanent residency, or work purposes, the IELTS General Test is suitable. Furthermore, for those aiming to study in the UK, there are two types of IELTS tests available: IELTS for UKVI Academic and IELTS for UKVI General Training.'
                            subText='The International English Language Testing System (IELTS) is a globally recognized standardized test for assessing English language proficiency for study, work, and residency purposes. Managed jointly by the British Council and IDP, it is essential for individuals planning to pursue opportunities abroad in countries such as Canada, Australia, Schengen, USA, and Ireland'
                        />

                        <OurServicesComp
                            recievedState={() => setServiceState(services.Touring)}
                            imgClass='servBg2'
                            mainText1Half='Touring'
                            mainText2Half='Package'
                            makeRight='makeRight'
                            fullText='Embark on an unforgettable journey with our tailored tour and vacation packages, curated to cater to individuals, companies, and groups alike. Whether you are envisioning a personalized adventure or opting for the camaraderie of a group tour, we are dedicated to crafting the perfect getaway for you.
                        Indulge in the luxury of customization by expressing your unique preferences through our Customized Package option. Share your desires with us by filling out the form below.'
                            subText='Embark on an unforgettable journey with our tailored tour and vacation packages, curated to cater to individuals, companies, and groups alike. Whether you are envisioning a personalized adventure or opting for the camaraderie of a group tour, we are dedicated to crafting the perfect getaway for you.
                        Indulge in the luxury of customization'
                        />

                        <OurServicesComp
                            recievedState={() => setServiceState(services.AdmissionInfo)}
                            imgClass='servBg3'
                            mainText1Half='Admission'
                            mainText2Half='Processing'
                            fullText='We facilitate admission processing in Canada, USA, Schengen, UK, New Zealand, Australia, and Ireland. Basic requirements for all these countries are as follows:
                        For BSc programs, you will need International passport data page, WAEC certificate, WAEC scratch card, Secondary school transcript, and IELTS certificate.
                        For Post-Graduate programs, you will need International passport data page, Degree certificate, Degree transcript, 2 reference letters (academic or professional), WAEC certificate, IELTS (if applicable), CV, and Statement of purpose.'
                            subText='We facilitate admission processing in Canada, USA, Schengen, UK, New Zealand, Australia, and Ireland. Basic requirements for all these countries are as follows:
                        For BSc programs, you will need International passport data page, WAEC certificate, WAEC scratch card, Secondary school transcript, and IELTS certificate.'
                        />


                        <OurServicesComp
                            recievedState={() => setServiceState(services.Birth)}
                            imgClass='servBg5'
                            mainText1Half='Birth Service'
                            mainText2Half='Abroad'
                            fullText='We offer birth services in several countries:
                        In Canada:
                         Hospital and doctor bill ranges from $9500 CAD to $11,500 CAD.
                         Accommodation costs between $1500 CAD to $2500 CAD.
                         Baby documentation fee is $500 CAD.

                         In Barbados:
                         Hospital and doctor bill ranges from $5500 to $8500.
                         Accommodation varies from $1500 to $3000 (depending on choice).
                         Immigration fee is $3000.
                         Baby documentation fee is $1750.

                         In Brazil:
                         Hospital and doctor bill ranges from $4000 to $6000.
                         Accommodation ranges from $600 to $1500.
                         Baby documentation fee is $500.
                         Permanent Resident fee from the parent is $500 to $1000.

                         In the USA:
                         Hospital and doctor bill ranges from $6500 to $10,000.
                         Accommodation costs between $1500 to $3000.
                         Baby documentation fee is $900.

                         Requirements for birth services include:
                         International Passport data page.
                         Scan.
                         Blood history.
                         Processing time to get hospital and doctor is 3 to 4 weeks.
                         it is advisable to start as early as possible.'
                            subText='We offer birth services in several countries:
                        In Canada:
                         Hospital and doctor bill ranges from $9500 CAD to $11,500 CAD.
                         Accommodation costs between $1500 CAD to $2500 CAD.
                         Baby documentation fee is $500 CAD.

                         In Barbados:
                         Hospital and doctor bill ranges from $5500 to $8500.
                         Accommodation varies from $1500 to $3000 (depending on choice).
                         Immigration fee is $3000.
                         Baby documentation fee is $1750.

                         In Brazil:
                         Hospital and doctor bill ranges from $4000 to $6000.
                         Accommodation ranges from $600 to $1500.'
                        />

                        <OurServicesComp
                            recievedState={() => setServiceState(services.Relocation)}
                            imgClass='servBg6'
                            mainText1Half='Permanent Residency'
                            mainText2Half=' in Canada'
                            makeRight='makeRight'
                            fullText='Programs for Permanent Residency in Canada:
                         Express Entry: For skilled workers, including Federal Skilled Worker Program, Federal Skilled Trades Program, and Canadian Experience Class.
                         Provincial Nominee Program (PNP): For workers with skills, education, and work experience to contribute to a specific province or territorys economy.
                        Requirements for Permanent Residency:
                        IELTS (International English Language Testing System)
                        Verification of results through WES (World Education Services)
                        CV (Curriculum Vitae)
                        International passport data page

                        Selection Factors:
                        Age
                        Education
                        Work experience
                        Valid job offer
                        English or French language skills
                        Adaptability

                        Proof of Funds:
                        Required unless legally working in Canada or having a valid job offer from a Canadian employer.'
                            subText='Programs for Permanent Residency in Canada:
                         Express Entry: For skilled workers, including Federal Skilled Worker Program, Federal Skilled Trades Program, and Canadian Experience Class.
                         Provincial Nominee Program (PNP): For workers with skills, education, and work experience to contribute to a specific province or territorys economy.
                        Requirements for Permanent Residency:
                        IELTS (International English Language Testing System)
                        Verification of results through WES (World Education Services)
                        CV (Curriculum Vitae)
                        International passport data page

                        Selection Factors:
                        Age
                        Education'
                        />

                        <OurServicesComp
                            recievedState={() => setServiceState(services.CarHire)}
                            imgClass='servBg7'
                            mainText1Half='Car Hire'
                            mainText2Half='Services'
                            fullText='Our car hire service offers you the flexibility and convenience you need for your travel plans. Whether you are embarking on a business trip, exploring a new city, or simply need a reliable ride for a special occasion, we have a wide range of vehicles to suit your needs.

                        Booking Your Car Hire
                        To book a car, simply fill out our car hire form with the following details:

                        Pick-Up Details: Let us know your desired pick-up location and date so we can have your vehicle ready when you need it.
                        Drop-Off Details: Indicate your drop-off location and date to complete your journey seamlessly.
                        Car Type: Choose from our selection of vehicles, ranging from economy cars to luxury models.
                        Personal Information: Provide your full name, contact details, and birth date to finalize your booking.
                        We are committed to ensuring your comfort and safety, offering well-maintained vehicles with modern amenities. Book with us today and experience a smooth, hassle-free ride.'
                            subText='Our car hire service offers you the flexibility and convenience you need for your travel plans. Whether you are embarking on a business trip, exploring a new city, or simply need a reliable ride for a special occasion, we have a wide range of vehicles to suit your needs.

                        Booking Your Car Hire
                        To book a car, simply fill out our car hire form with the following details:

                        Pick-Up Details: Let us know your desired pick-up location and date so we can have your vehicle ready when you need it.
                        Drop-Off Details: Indicate your drop-off location and date to complete your journey seamlessly.
                        Car Type: Choose from our selection of vehicles, ranging from economy cars to luxury models.'
                        />

                        <OurServicesComp
                            recievedState={() => setServiceState(services.JetHire)}
                            imgClass='servBg8'
                            mainText1Half='Private Jet '
                            makeRight='makeRight'
                            mainText2Half='Hire Services'
                            fullText='Experience the pinnacle of luxury and convenience with our private jet charter service. Whether you are flying for business or leisure, our tailored solutions ensure you travel in comfort, style, and privacy.

                        Booking Your Private Jet
                        To arrange your private flight, kindly complete our form with the following details:

                        Personal Information: Share your surname, given name, company, contact number, email, address, and country. This information helps us provide a personalized service tailored to your needs.
                        Flight Details:
                        Departing From: Specify your departure location and date.
                        Destination: Let us know your destination and travel schedule, including departure and return times.
                        Preferred Aircraft: Choose your preferred aircraft type, from light jets to long-range options.
                        Number of Passengers: Indicate the number of travelers to ensure we provide a suitable aircraft.
                        Type of Flight: Let us know if your journey is for business or leisure.
                        Additional Information: Tell us how you heard about our service, helping us improve and expand our reach.
                        From efficient business travel to unforgettable leisure trips, our private jet service offers unparalleled comfort, discretion, and a personalized travel experience. Book your private jet today and redefine your journey.

                        '
                            subText='Experience the pinnacle of luxury and convenience with our private jet charter service. Whether you are flying for business or leisure, our tailored solutions ensure you travel in comfort, style, and privacy.

                        Booking Your Private Jet
                        To arrange your private flight, kindly complete our form with the following details:

                        Personal Information: Share your surname, given name, company, contact number, email, address, and country. This information helps us provide a personalized service tailored to your needs.
                        Flight Details:
                        Departing From: Specify your departure location and date.
                        Destination: Let us know your destination and travel schedule, including departure and return times.
                        Preferred Aircraft: '
                        />



                        <OurServicesComp
                            recievedState={() => setServiceState(services.CareGiver)}
                            imgClass='servBg9'
                            mainText1Half='Caregiver Training'
                            mainText2Half='Services'
                            fullText='The face-to-face HCA training is composed of classroom-based lectures and an internship in a care home.

                                At the end of the training,  you will be awarded a care certificate, reference letter,  CV, and a cover letter for your international job search.

                                The package fee is N500,000

                                Note
                                The classes will go live from a particular location, people can join the central location once the class starts.


                                We have physical training in all these places

                                Lagos
                                Jos
                                Enugu
                                Portharcourt
                                Abuja
                                Umuahia
                                Onicha
                                ABA
                                Ibadan
                                 '
                            subText='The face-to-face HCA training is composed of classroom-based lectures and an internship in a care home.

                                At the end of the training,  you will be awarded a care certificate, reference letter,  CV, and a cover letter for your international job search.

                                The package fee is N500,000

                                Note
                                The classes will go live from a particular location, people can join the central location once the class starts.


                                We have physical training in all these places '
                        />


                        <OurServicesComp
                            recievedState={() => setServiceState(services.HoneyMoon)}
                            imgClass='servBg10'
                            makeRight='makeRight'
                            mainText1Half='Wedding Destination and Honeymoon '
                            mainText2Half='Package / Vacation Services'
                            fullText='Planning your Vacation, Wedding Destination, and Honeymoon
                            Planning your vacation, wedding destination, and honeymoon can feel overwhelming. Our team is highly experienced, specialized, and knowledgeable about creating and managing our clients itineraries down to the last detail. When we plan travels our clients benefit from our years of experience and our long-standing industry relationships.

                            Our Services Include:

                            Destination wedding group travel coordination,
                            Destination wedding event planning services,
                            Group travel coordination,
                            Custom itineraries for vacations,
                            Honeymoon planning and management.
                            We Book and Manage:

                            Cruises,
                            All-inclusive resort vacations,
                            City stays,
                            Escorted and independent tours,
                            Custom travel itineraries,
                            Villas,
                            Yachts,
                            Corporate incentives,
                            Hotels,
                            Tours and excursions.
                                 '
                            subText='Planning your Vacation, Wedding Destination, and Honeymoon
                            Planning your vacation, wedding destination, and honeymoon can feel overwhelming. Our team is highly experienced, specialized, and knowledgeable about creating and managing our clients itineraries down to the last detail. When we plan travels our clients benefit from our years of experience and our long-standing industry relationships.

                        Our Services Include:

                        Destination wedding group travel coordination,
                        Destination wedding event planning services,
                        Group travel coordination,
                        Custom itineraries for vacations,
                        Honeymoon planning and management.
                        We Book and Manage:'
                        />

                        <OurServicesComp
                            recievedState={() => setServiceState(services.HotelReservation)}
                            imgClass='servBg11'
                            mainText1Half='Hotel Reservation'
                            mainText2Half='Services'
                            fullText='Our hotel reservation service assists in securing accommodations both locally and internationally, including serviced apartments tailored to your needs. Whether you are traveling for business or leisure, our goal is to ensure a seamless booking experience. Please fill out the form with your details, including your contact information, reservation dates, and any special requests. Our team will handle the rest to provide you with the best options for a comfortable stay.

                        If you have specific requirements or preferences, let us know in the Special Requests section, and we will do our best to accommodate them.
                                 '
                            subText='Our hotel reservation service assists in securing accommodations both locally and internationally, including serviced apartments tailored to your needs. Whether you are traveling for business or leisure, our goal is to ensure a seamless booking experience. Please fill out the form with your details, including your contact information, reservation dates, and any special requests. Our team will handle the rest to provide you with the best options for a comfortable stay.

                        If you have '
                        />

                        <OurServicesComp
                            recievedState={() => setServiceState(services.AirportPickup)}
                            imgClass='servBg7'
                            makeRight='makeRight'
                            mainText1Half='Airport Pickup '
                            mainText2Half='Reservation service'
                            fullText='Our Airport Pickup Reservation service is designed to provide you with a seamless and stress-free travel experience. Whether you’re booking a one-way or round trip, our service ensures timely pickups and drop-offs at your preferred locations.

                            Please fill out the form with your personal and trip information, including flight details, luggage count, and any special requests you may have. We aim to accommodate your specific needs and make your journey as comfortable and convenient as possible.

                            For any additional requirements or comments, kindly let us know in the "Comments/Special Requests" section, and our team will do their best to assist you.'
                            subText='Our Airport Pickup Reservation service is designed to provide you with a seamless and stress-free travel experience. Whether you’re booking a one-way or round trip, our service ensures timely pickups and drop-offs at your preferred locations.

                            Please fill out the form with your personal and trip information, including flight details, '
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
