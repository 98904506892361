import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import TitleDropdown from "../Visa/titleDropdown";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import {toast} from "react-toastify";

const CarHireForm = (props) => {
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('');
    const carTypes = [
        "Economy (e.g., Toyota Yaris, Hyundai Accent)",
        "Compact (e.g., Honda Civic, Ford Focus)",
        "Intermediate / Mid-Size (e.g., Toyota Corolla, Nissan Sentra)",
        "Full-Size (e.g., Chevrolet Malibu, Ford Fusion)",
        "Luxury (e.g., BMW 5 Series, Mercedes-Benz E-Class)",
        "SUV (e.g., Toyota RAV4, Ford Explorer)",
        "Premium SUV (e.g., Range Rover, BMW X5)",
        "Minivan (e.g., Honda Odyssey, Chrysler Pacifica)",
        "Convertible (e.g., Ford Mustang Convertible, Mazda MX-5)"
    ];


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {


            const CarHire ={
                'Full_Name': localStorage.getItem('FullName_CH')  ?  localStorage.getItem('FullName_CH'): '',
                'Email': localStorage.getItem('Email_CH')  ?  localStorage.getItem('Email_CH'): '',
                'Phone_Number': localStorage.getItem('Phone_number_CH')  ?  localStorage.getItem('Phone_number_CH'): '',
                'Date_of_birth': localStorage.getItem('Date_of_birth_CH')  ?  localStorage.getItem('Date_of_birth_CH'): '',
                'Pick_Up_Date': localStorage.getItem('Pick_Up_Date_CH')  ?  localStorage.getItem('Pick_Up_Date_CH'): '',
                'Pick_Up_Location': localStorage.getItem('Pick_Up_Location_CH')  ?  localStorage.getItem('Pick_Up_Location_CH'): '',
                'Drop_Off_Date': localStorage.getItem('Drop_Off_Date_CH')  ?  localStorage.getItem('Drop_Off_Date_CH'): '',
                'Drop_Off_Location': localStorage.getItem('Drop-Off_Location_CH')  ?  localStorage.getItem('Drop-Off_Location_CH'): '',
                'Car_Type': localStorage.getItem('CarType_CH')  ?  localStorage.getItem('CarType_CH'): '',


            }


            const form_data = {
                'CarHire': CarHire,
            };

            setPopUp('Loading.....')

                try {
                    const formData = new FormData();
                    formData.append('jarom', 'jaromtravels');
                    formData.append('jarom_subject', 'Permanent Residency in Canada(Our Services)');
                    formData.append('jarom_message', JSON.stringify(form_data));


                    const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );

                    if (response.status === 201) {
                        setPopUp('submitted');
                        toast.success("Form submitted successfully");
                        props.ReceiveCarHireForm(false)

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setPopUp('Failed')
                    toast.error("Failed to submit form");
                }

        }


    };

    return (
        <form ref={props.close}  onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Personal Information
            </div>
            <div className="IELTS1">

                <VisaInputField labelName='Full Name' star='*' type='text'
                                localStorageName='FullName_CH'
                                placeholder='Full name as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email' star='*' type='email'
                                localStorageName='Email_CH'
                                placeholder='jarom@gmail.com' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Phone Number' star='*' type='number'
                                localStorageName='Phone_number_CH'
                                placeholder='Phone Number' required={true}>

                </VisaInputField>

                    <VisaInputField labelName='Date of birth' star='*' type='date'
                                    localStorageName='Date_of_birth_CH'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>
                <div className="IELTSInner2">
                </div>
            </div>

            <div className="blueHeader">
                Car Hire Details
            </div>
            <div className="IELTS1">
                <div className="IELTSInner2">
                    <VisaInputField labelName='Pick-Up Date' star='*' type='date'
                                    localStorageName='Pick_Up_Date_CH'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Pick-Up Location' star='*' type='text'
                                    localStorageName='Pick_Up_Location_CH'
                                    placeholder='Los Angeles International Airport (LAX)' required={true}>

                    </VisaInputField>
                </div>

                <div className="IELTSInner2">
                    <VisaInputField labelName='Drop-Off Date' star='*' type='date'
                                    localStorageName='Pick_Up_Date_CH'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Drop-Off Location' star='*' type='text'
                                    localStorageName='Drop-Off_Location_CH'
                                    placeholder='San Francisco Downtown Office' required={true}>

                    </VisaInputField>
                </div>

                <div className='visa_inputField ' id='inputTop'>
                    <label>
                        Car Type<span>*</span>
                    </label>
                    <TitleDropdown name='Car Type' localStorageName='CarType_CH' mwidth='130px'
                                   mxfont='14px' mxwidth='100%' mfont='10px'
                                   options={carTypes} title="Select"
                                   className='visa_input'/>

                </div>

            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveStateIELTS(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>

    );
};

export default CarHireForm;