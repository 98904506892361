import React from "react";
import './cookiesUI.css'

export default function CookiesUI({handleClose,  isVisible}){






    return(
        <>
            {
                isVisible ?
                    <div className='altcookiescont'>
                        <div className="cookiesCont">
                            <div className="textCookies">
                                <div className="mobileCookies">
                                    🍪 Our website uses cookies
                                </div>
                                <img src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716022724/JaromTravels/gnqk31lt6i2o8e4vc1ve.svg" alt="cookies"/>
                                <span className="cookiesMainText">
                                    Our website use cookies. By continuing,<br className='displayNone'/> we assume your <br className='onlyat1440'/> permission to deploy <br className='displayNone'/>cookies as detailed in our
                                    <span className='cookiestermsSpan'> Privacy Policy</span>.
                                </span>

                            </div>

                            <div className="cookiesBtn">
                                <div className="acceptCookies"  onClick={() => handleClose('Accept')}>
                                    <img src="http://res.cloudinary.com/dk80ynx1w/image/upload/v1716022753/JaromTravels/oalmbp4gbgrl5pqibstd.svg" alt="cookies icon"/>
                                    Accept cookies
                                </div>
                                <div className="declineCookies">
                                    <div onClick={() => handleClose('Manage')}>Manage</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
        </>

    )
}