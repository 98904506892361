import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import DropdownAll from "../../components/Visa/visaCountryStateCity";
import {toast} from "react-toastify";


const HotelReservationForm = (props) => {

    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('');






    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {


            const HotelRes ={
                'Surname': localStorage.getItem('Surname_HR')  ?  localStorage.getItem('Surname_HR'): '',
                'Full_Name': localStorage.getItem('FullName_HR')  ?  localStorage.getItem('FullName_HR'): '',
                'Phone_Number': localStorage.getItem('Phone_HR')  ?  localStorage.getItem('Phone_HR'): '',
                'Email': localStorage.getItem('Email_HR')  ?  localStorage.getItem('Email_HR'): '',
                'Address': localStorage.getItem('Address_HR')  ?  localStorage.getItem('Address_HR'): '',
                'Country': localStorage.getItem('Country_HR')  ?  localStorage.getItem('Country_HR'): '',
                'City': localStorage.getItem('City_HR')  ?  localStorage.getItem('City_HR'): '',
                'State': localStorage.getItem('State_HR')  ?  localStorage.getItem('State_HR'): '',
                'ZipCode': localStorage.getItem('ZipCode_HR')  ?  localStorage.getItem('ZipCode_HR'): '',
                'Arrival_Date': localStorage.getItem('Arrival_Date_HR')  ?  localStorage.getItem('Arrival_Date_HR'): '',
                'Arrival_Time': localStorage.getItem('ArrivalTime_HR')  ?  localStorage.getItem('ArrivalTime_HR'): '',
                'Departure_Date': localStorage.getItem('Departure_Date_HR')  ?  localStorage.getItem('Departure_Date_HR'): '',
                'Departure_Time': localStorage.getItem('DepartureTime_HR')  ?  localStorage.getItem('DepartureTime_HR'): '',
                'Number_of_Adults': localStorage.getItem('number_of_Adults_HR')  ?  localStorage.getItem('number_of_Adults_HR'): '',
                'Number_of_Kids': localStorage.getItem('number_of_Kids_HR')  ?  localStorage.getItem('number_of_Kids_HR'): '',
                'Special_Request': localStorage.getItem('SpecialRequest_HR')  ?  localStorage.getItem('SpecialRequest_HR'): '',

            }


            const form_data = {
                'HotelReservation': HotelRes,
            };

            setPopUp('Loading.....')

            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Permanent Residency in Canada(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.status === 201) {
                    setPopUp('submitted');
                    toast.success("Form submitted successfully");
                    props.ReceiveJetHireForm(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed');
                toast.error("Failed to submit form");

            }

        }


    };

    return (
        <form ref={props.close}  onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Contact Information &
                Guest Information

            </div>
            <div className="IELTS1">

                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='Surname_HR'
                                placeholder='Surname as on intl. passport' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Full Name' star='*' type='text'
                                localStorageName='FullName_HR'
                                placeholder='Full name as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email' star='*' type='email'
                                localStorageName='Email_HR'
                                placeholder='jarom@gmail.com' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Phone Number' star='*' type='number'
                                localStorageName='Phone_number_HR'
                                placeholder='Phone Number' required={true}>

                </VisaInputField>


                <VisaInputField labelName='Address' star='*' type='text'
                                localStorageName='Address_HR'
                                placeholder='123 Main Street' required={true}>

                </VisaInputField>

                <DropdownAll mxwidth='321px' mfont='10px' mxfont='14px' mwidth='237px'
                             localStorageCountry='Country_HR' localStorageCity='City_HR'
                             localStorageState='State_HR' localStoragezip='ZipCode_HR'
                             Country_='Country' State_='State' City_='City'>

                </DropdownAll>
            </div>

            <div className="blueHeader">
                Reservation Details
            </div>
            <div className="IELTS1">
                <div className="IELTSInner2">

                    <VisaInputField labelName='Arrival Date' star='*' type='date'
                                    localStorageName='Arrival_Date_HR'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Arrival Time' star='*' type='text'
                                    localStorageName='ArrivalTime_HR'
                                    placeholder='4:00PM (G.M.T)' required={true}>

                    </VisaInputField>
                </div>

                <div className="IELTSInner2">
                    <VisaInputField labelName='Departure Date' star='*' type='date'
                                    localStorageName='Departure_Date_HR'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Departure Time' star='*' type='text'
                                    localStorageName='DepartureTime_HR'
                                    placeholder='4:00PM (G.M.T)' required={true}>

                    </VisaInputField>
                </div>


                <div className="IELTSInner2">
                    <VisaInputField labelName='Number of Adults' star='*' type='text'
                                    localStorageName='number_of_Adults_HR'
                                    placeholder='2' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Number of Kids (if any): ' star='*' type='text'
                                    localStorageName='Budget_HR'
                                    placeholder='1 (Age: 5)'>

                    </VisaInputField>
                </div>
<br/>
                <div className="subBlueHeader">
                    Special Requests
                </div>



                <VisaInputField labelName='Do you have any special requests? (Optional)' star='*' type='text'
                                localStorageName='SpecialRequest_HR'
                                placeholder=' Early check-in if available, a crib for the child, and a quiet room on a higher floor.' >

                </VisaInputField>







            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveJetHireForm(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>

    );
};

export default HotelReservationForm;