import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import RadioInput from "../Visa/radioDoubleOption";
import {uploadToCloudinary} from "../Visa/visa";
import envVariables from "../../dev";
import axiosWithAuth from "../Axios/axiosWithAuth";
import {toast} from "react-toastify";


export default function PermanentResidency (props){

    const [HaveyouWorkedinCanada, SethaveyouWorkedinCanada] = useState('');
    const [IELTS, SetIELTS] = useState('');
    const [WES , SetWES ] = useState('');
    const [French , SetFrench ] = useState('');
    const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('');

    const getUploadURL = (fileURL, fileName, urlKeyname) =>{
        if(fileURL){
            uploadToCloudinary(fileURL,fileName)
                .then((url1) => {
                    localStorage.setItem(urlKeyname, url1)
                    return url1
                })
                .catch((error) => {
                    console.error('Error uploading file1:', error);
                })
        }else{
            console.log('file undefined')
        }

    }





    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            getUploadURL('UploadCV_PR', 'UploadCV_PRname', 'CV_Link')
            getUploadURL('UploadIELTSCertificate_PR' , 'UploadIELTSCertificate_PRname', 'IELTS_Link')
            getUploadURL('UploadWESCertificate_PR' , 'UploadWESCertificate_PRname', 'WES_Link')
            getUploadURL('UploadScannedVisaPage_PR/DataPage_PR' , 'UploadScannedVisaPage_PR/DataPage_PRname', 'VisaPage_Upload')

            const VisaPage = localStorage.getItem('VisaPage_Upload')  ?  localStorage.getItem('VisaPage_Upload'): ''
            const IELTSFile = localStorage.getItem('IELTS_Link')  ?  localStorage.getItem('IELTS_Link'): ''
            const WESFile = localStorage.getItem('WES_Link')  ?  localStorage.getItem('WES_Link'): ''
            const cv_file = localStorage.getItem('CV_Link')  ?  localStorage.getItem('CV_Link'): ''
            console.log(cv_file, IELTSFile, WESFile, VisaPage)

            const PermanentResidency ={
                'Given_Name': localStorage.getItem('GivenName_PR')  ?  localStorage.getItem('GivenName_PR'): '',
                'Surname': localStorage.getItem('Surname_PR')  ?  localStorage.getItem('Surname_PR'): '',
                'date_of_birth': localStorage.getItem('Date_of_birth_PR')  ?  localStorage.getItem('Date_of_birth_PR'): '',
                'Last_degree_qualification': localStorage.getItem('Last_degree_qualification_PR')  ?  localStorage.getItem('Last_degree_qualification_PR'): '',
                'Years_of_work_experience': localStorage.getItem('Years_of_work_experience_PR')  ?  localStorage.getItem('Years_of_work_experience_PR'): '',
                'Have_you_Worked_in_Canada': HaveyouWorkedinCanada === 'Yes' ? {'Yes' : HaveyouWorkedinCanada, 'Number_of_Years_Worked': localStorage.getItem('Number_of_Years_Worked_PR')  ?  localStorage.getItem('Number_of_Years_Worked_PR') : ''  } : { 'No': HaveyouWorkedinCanada},
                'Family_member_in_Canada': localStorage.getItem('Family_member_in_Canada_PR')  ?  localStorage.getItem('Family_member_in_Canada_PR'): '',
                'Years_of_foreign_work_experience': localStorage.getItem('Years_of_foreign_work_experience_PR')  ?  localStorage.getItem('Years_of_foreign_work_experience_PR'): '',

                'IELTS': IELTS === 'Yes' ?{
                    'Yes':{
                        'Listening_Score': localStorage.getItem('Listening_IELTS_PR')  ?  localStorage.getItem('Listening_IELTS_PR'): '',
                        'Reading_Score': localStorage.getItem('Reading_IELTS_PR')  ?  localStorage.getItem('Reading_IELTS_PR'): '',
                        'Writing_Score': localStorage.getItem('Writing_IELTS_PR')  ?  localStorage.getItem('Writing_IELTS_PR'): '',
                        'Speaking_Score': localStorage.getItem('Speaking_IELTS_PR')  ?  localStorage.getItem('Speaking_IELTS_PR'): '',
                    }
                } : {'No': localStorage.getItem('IELTS_PR')  ?  localStorage.getItem('IELTS_PR'): '' },

                'French': French === 'Yes' ?{
                    'Yes':{
                        'Listening_Score': localStorage.getItem('Listening_French_PR')  ?  localStorage.getItem('Listening_French_PR'): '',
                        'Reading_Score': localStorage.getItem('Reading_French_PR')  ?  localStorage.getItem('Reading_French_PR'): '',
                        'Writing_Score': localStorage.getItem('Writing_French_PR')  ?  localStorage.getItem('Writing_French_PR'): '',
                        'Speaking_Score': localStorage.getItem('Speaking_French_PR')  ?  localStorage.getItem('Speaking_French_PR'): '',
                    }
                } : {'No': localStorage.getItem('French_PR')  ?  localStorage.getItem('French_PR'): '' },

                'WES': WES === 'Yes' ?{
                    'Yes':{
                        'What_Degree': localStorage.getItem('WESdegree_PR')  ?  localStorage.getItem('WESdegree_PR'): '',
                    }
                } : {'No': localStorage.getItem('Have_you_done_WES_PR')  ?  localStorage.getItem('Have_you_done_WES_PR'): '' },

                'VisaPage_Photo': localStorage.getItem('VisaPage_Upload')  ?  localStorage.getItem('VisaPage_Upload'): '',
                'IELTSCertificate_Photo': localStorage.getItem('IELTS_Link')  ?  localStorage.getItem('IELTS_Link'): '',
                'WESCertificate_Photo': localStorage.getItem('WES_Link')  ?  localStorage.getItem('WES_Link'): '',
                'CV_Photo': localStorage.getItem('CV_Link')  ?  localStorage.getItem('CV_Link'): '',
            }


            const form_data = {
                'Permanent Residency': PermanentResidency,
            };

            setPopUp('Loading.....')
            if(cv_file && IELTSFile && WESFile && VisaPage){

                try {
                    const formData = new FormData();
                    formData.append('jarom', 'jaromtravels');
                    formData.append('jarom_subject', 'Permanent Residency in Canada(Our Services)');
                    formData.append('jarom_message', JSON.stringify(form_data));


                    const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );

                    if (response.status === 201) {
                        setPopUp('submitted');
                        toast.success("Form submitted successfully");

                        props.ReceivePermanentResidency(false);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setPopUp('Failed');
                    toast.error("Failed to submit form");

                }
            }
        }


    };



    function handleFileChange (size){
        // const file = event.target.files[0];

        if (size > 0.5 * 1024 * 1024) {
            // console.log("i'm working")
            setFileSizeExceeded(true);
            // event.target.value = '';
        } else {
            setFileSizeExceeded(false);
        }
        return fileSizeExceeded
    };

    const radioUpdate__ = () => {
        const storedValue = localStorage.getItem('Have_you_Worked_in_Canada_PR');
        if (storedValue) {
            SethaveyouWorkedinCanada(storedValue);
        }
    };

    const radioUpdate___ = () => {
        const storedValue = localStorage.getItem('Have_you_done_WES_PR');
        if (storedValue) {
            SetWES(storedValue);
        }
    };

    const radioUpdate_ = () => {
        const storedValue = localStorage.getItem('IELTS_PR');
        if (storedValue) {
            SetIELTS(storedValue);
        }
    };

    const radioUpdate = () => {
        const storedValue = localStorage.getItem('French_PR');
        if (storedValue) {
            SetFrench(storedValue);
        }
    };
    return(
        <form ref={props.close}  onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Registration Form
            </div>
            <div className="IELTS1">


                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='Surname_PR'
                                placeholder='Surname as on intl. passport' required={true}>
                </VisaInputField>

                <VisaInputField labelName='Given Name' star='*' type='text'
                                localStorageName='GivenName_PR'
                                placeholder='Given name as on intl. passport' required={true}>

                </VisaInputField>


                <div className="IELTSInner2">
                    <VisaInputField labelName='Date of birth' star='*' type='date'
                                    localStorageName='Date_of_birth_PR'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>
                </div>

                <VisaInputField labelName='Last degree qualification' star='*' type='text'
                                localStorageName='Last_degree_qualification_PR'
                                placeholder='Last degree qualification' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Years of work experience' star='*' type='text'
                                localStorageName='Years_of_work_experience_PR'
                                placeholder='Years of work experience' required={true}>

                </VisaInputField>

                <RadioInput onClick={radioUpdate__} text="Ever Worked in Canada "
                            localStorageName="Have_you_Worked_in_Canada_PR"/>
                {HaveyouWorkedinCanada === 'Yes' && (
                    <div>
                        <VisaInputField id='visa-Input'
                                        localStorageName='Number_of_Years_Worked_PR'
                                        labelName='Number of Years Worked'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>
                    </div>
                )}

                <RadioInput text="Family member in Canada?"
                            localStorageName="Family_member_in_Canada_PR"/>
                {/*<div className="IELTS1">*/}
                <VisaInputField
                                localStorageName='Years_of_foreign_work_experience_PR'
                                labelName='Years of foreign work experience'
                                star='*' type='text' placeholder='Years of foreign work experience'
                                required={true}>
                </VisaInputField>


                <RadioInput onClick={radioUpdate_} text="IELTS"
                            localStorageName="IELTS_PR"/>
                {IELTS === 'Yes' && (
                    <div>
                        <VisaInputField id='visa-Input'
                                        localStorageName='Listening_IELTS_PR'
                                        labelName='Scores for Listening'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>

                        <VisaInputField id='visa-Input'
                                        localStorageName='Reading_IELTS_PR'
                                        labelName='Scores for Reading'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>

                        <VisaInputField id='visa-Input'
                                        localStorageName='Writing_IELTS_PR'
                                        labelName='Scores for Writing'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>

                        <VisaInputField id='visa-Input'
                                        localStorageName='Speaking_IELTS_PR'
                                        labelName='Scores for Speaking'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>
                    </div>
                )}


                <RadioInput onClick={radioUpdate} text="French"
                            localStorageName="French_PR"/>
                {French  === 'Yes' && (
                    <div>
                        <VisaInputField id='visa-Input'
                                        localStorageName='Listening_French_PR'
                                        labelName='Scores for Listening'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>

                        <VisaInputField id='visa-Input'
                                        localStorageName='Reading_French_PR'
                                        labelName='Scores for Reading'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>

                        <VisaInputField id='visa-Input'
                                        localStorageName='Writing_French_PR'
                                        labelName='Scores for Writing'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>

                        <VisaInputField id='visa-Input'
                                        localStorageName='Speaking_French_PR'
                                        labelName='Scores for Speaking'
                                        star='*' type='text' placeholder='Number of Years Worked'
                                        required={true}>
                        </VisaInputField>
                    </div>
                )}

                <RadioInput onClick={radioUpdate___} text="Ever done WES?"
                            localStorageName="Have_you_done_WES_PR"/>
                {WES === 'Yes' && (
                    <div>
                        <VisaInputField id='visa-Input'
                                        localStorageName='WESdegree_PR'
                                        labelName='What Degree?'
                                        star='*' type='text' placeholder='What Degree?'
                                        required={true}>
                        </VisaInputField>
                    </div>
                )}


                <div>
                    <VisaInputField localStorageName='UploadScannedVisaPage_PR/DataPage_PR'
                                    labelName='Upload scanned Visa page/Data page' star='*'
                                    type='file'
                                    placeholder=''
                                    additionalFunction={handleFileChange}
                                    required={true}>

                    </VisaInputField>

                    <VisaInputField localStorageName='UploadIELTSCertificate_PR'
                                    labelName='IELTS certificate (if applicable)' star='*'
                                    type='file'
                                    placeholder=''
                                    additionalFunction={handleFileChange}
                                    required={true}>

                    </VisaInputField>

                    <VisaInputField localStorageName='UploadWESCertificate_PR'
                                    labelName='WES certificate (if applicable)' star='*'
                                    type='file'
                                    placeholder=''
                                    additionalFunction={handleFileChange}
                                    required={true}>

                    </VisaInputField>

                    <VisaInputField localStorageName='UploadCV_PR'
                                    labelName='Upload CV' star='*'
                                    type='file'
                                    placeholder=''
                                    additionalFunction={handleFileChange}
                                    required={true}>

                    </VisaInputField>
                    {fileSizeExceeded && (
                        <div className='visa_sizeWarning'>
                            All file upload must not exceed 500kB
                        </div>
                    )}
                </div>

            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceivePermanentResidency(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>
    )
}