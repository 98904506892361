import React, {useEffect, useState} from "react";
import "react-lazy-load-image-component/src/effects/blur.css"
import './visa.css';
import 'animate.css';
import VisaInputField from "./visaInput";
import VisaDoubleInputField from "./visaDoubleInput";
import TitleDropdown from "./titleDropdown";
import VisaDoubleDropdownInputField from "./visaDoubleDropDown";
import DropdownAll from "./visaCountryStateCity";
import MultipleFileInputs from "./multipleFileInput";
import RadioInput from "./radioDoubleOption";
import RelationshipRadioInput from "./relationshipRadio";
import EmploymentRadioInput from "./employmentRadio";
import VisaSidder from "./visaSider";
import Terms from "./termsSplit";
import axios from "axios";
import MyCountries from "./countries";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import {Helmet} from "react-helmet-async";


export function uploadToCloudinary(fileURI, fileName) {
    // Check if parameters are defined
    if (!fileURI || !fileName) {
        // console.error("Missing required parameters");
        return Promise.reject("Missing required parameters");
    }

    // Get file data URI and name from localStorage
    const fileDataURI = localStorage.getItem(fileURI);
    const fileDataName = localStorage.getItem(fileName);



    // Check if file data URI and name are defined
    if (!fileDataURI || !fileDataName) {
        // console.error("File data not found in localStorage");
        return Promise.reject("File data not found in localStorage");
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString(); // Format the date if needed
    const fullFileName = fileDataName + formattedDate; // Append current date to file name

    const formData = new FormData();
    formData.append('file', fileDataURI);
    formData.append('upload_preset', 'designguy_jarom');
    formData.append('public_id', fullFileName);
    formData.append('api_key', '377582584657773');

    return axios
        .post(`https://api.cloudinary.com/v1_1/dk80ynx1w/image/upload`, formData)
        .then((response) => {
            if(response.status === 200){
                return response.data.url
                console.log(response.data.url)
            }
        })
        .catch((error) => {
            console.error(error)
        });
};


//example uploadToCloudinary('uploadPassportPhoto','uploadPassportPhotoname') use after uploading pictures in the first view of the visa
export default function Visa() {
    const [step, setStep] = useState(1);
    const [showPop, setShowPop] = useState(false);

    const getUploadURL = (fileURL, fileName, urlKeyname) =>{
        if(fileURL){
            uploadToCloudinary(fileURL,fileName)
                .then((url1) => {
                    localStorage.setItem(urlKeyname, url1)
                    // console.log(url1, urlKeyname)
                    return url1
                })
                .catch((error) => {
                    console.error('Error uploading file1:', error);
                })
        }else{
            console.log('file undefined')
        }

    }

    const Stratified_data = JSON.parse(localStorage.getItem('Stratified')) || [];
    const Squamous_data = JSON.parse(localStorage.getItem('Squamous')) || [];

    let data_urls = [];
    let passport_urls = [];

    useEffect(() => {
        for (let i = 1; i <= Squamous_data.length; i++) {
            const data1 = localStorage.getItem(`visa_data_${i}`);
            data_urls.push({ [` Data_Page_${i}`]: data1 });
        }
        for (let i = 1; i <= Stratified_data.length; i++) {
            const data2 = localStorage.getItem(`visa_Passport_${i}`);
            passport_urls.push({ [`Passport_Photograph_${i}`]: data2 });

        }
        // console.log(data_urls, passport_urls)
    }, [Squamous_data.length, Stratified_data.length, data_urls, passport_urls, Stratified_data, Squamous_data]);




    const handleNext = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {

            if (step === 8) {
                handleSubmit();
            } else{
                if(step === 1){

                    getUploadURL('uploadPassportPhoto', 'uploadPassportPhotoname', 'visa_passport_photo1')
                }
                if(step === 3){
                    getUploadURL('UploadScannedVisaPage/DataPage', 'UploadScannedVisaPage/DataPagename', 'visa_passport_photo2')
                }
                setStep(step + 1);
                scrollToTop();
            }
            // e.target.reset();
        }

    };
    const handlePrevious = () => {
        setStep(step - 1);
        scrollToTop();
    };
    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
    const countries = MyCountries;
    const gender = ['Male', 'Female', 'Others']
    const maritalStatus = ['Single', 'Married', 'Divorced']
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [color, setColor] = useState("blue");
    const [areOthersTravellingWithYou, setAreOthersTravellingWithYou] = useState('');
    const [areOthersTravellingWithYou_, setAreOthersTravellingWithYou_] = useState('');
    const [areOthersTravellingWithYou__, setAreOthersTravellingWithYou__] = useState('');
    const [areOthersTravellingWithYou___, setAreOthersTravellingWithYou___] = useState('');
    const [areOthersTravellingWithYou____, setAreOthersTravellingWithYou____] = useState('');
    const [areOthersTravellingWithYou_____, setAreOthersTravellingWithYou_____] = useState('');
    const [areOthersTravellingWithYou______, setAreOthersTravellingWithYou______] = useState('');
    const [areOthersTravellingWithYou_______, setAreOthersTravellingWithYou_______] = useState('');
    const [areOthersTravellingWithYou________, setAreOthersTravellingWithYou________] = useState('');
    const [additionalClass, setAdditionalClass] = useState('');
    const [fileSizeExceeded, setFileSizeExceeded] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showStep, setShowStep] = useState(true);
    const [PopUp, setPopUp] = useState(null);

    function handleFileChange (size){
        // const file = event.target.files[0];

        if (size > 0.5 * 1024 * 1024) {
            // console.log("i'm working")
            setFileSizeExceeded(true);
            // event.target.value = '';
        } else {
            setFileSizeExceeded(false);
        }
        return fileSizeExceeded
    };
    const radioUpdate = () => {
        const storedValue = localStorage.getItem('areOthersTravellingWithYou');
        if (storedValue) {
            setAreOthersTravellingWithYou(storedValue);
        }
        // console.log('selected', storedValue)
    };
    const radioUpdate_ = () => {
        const storedValue = localStorage.getItem('haveYouBeenToThisLocationBefore');
        if (storedValue) {
            setAreOthersTravellingWithYou_(storedValue);
        }
    };
    const radioUpdate__ = () => {
        const storedValue = localStorage.getItem('areYouThePartyPayingForTheTrip');
        if (storedValue) {
            setAreOthersTravellingWithYou__(storedValue);
        }
    };
    const radioUpdate___ = () => {
        const storedValue = localStorage.getItem('didYouAttendAnyEducationalInstitutionAtASecondaryLevelOrAbove');
        if (storedValue) {
            setAreOthersTravellingWithYou___(storedValue);
        }
    };
    const radioUpdate____ = () => {
        const storedValue = localStorage.getItem('haveTraveledToAnyCountryInTheLastFive');
        if (storedValue) {
            setAreOthersTravellingWithYou____(storedValue);
        }
    };
    const radioUpdate_____ = () => {
        const storedValue = localStorage.getItem('doYouBelongToAnyTribe');
        if (storedValue) {
            setAreOthersTravellingWithYou_____(storedValue);
        }
    };
    const radioUpdate______ = () => {
        const storedValue = localStorage.getItem('haveYouEverLostYourPassportOrHadOneStolen');
        if (storedValue) {
            setAreOthersTravellingWithYou______(storedValue);
        }
    };

    const radioUpdate_______ = () => {
        const storedValue = localStorage.getItem('doYouHaveImmediateRelativesInTheCountryOfChoice');
        if (storedValue) {
            setAreOthersTravellingWithYou_______(storedValue);
        }
    };

    const radioUpdate________ = () => {
        const storedValue = localStorage.getItem('employmentInfo');
        if (storedValue) {
            setAreOthersTravellingWithYou________(storedValue);
              }
    };

    const handleCheckboxChange = (event) => {
        setCheckboxChecked(event.target.checked);
    };
    const apikey = envVariables.APIKEY;

    const RelationshipStatus = localStorage.getItem('MaritalStatus')  ?  localStorage.getItem('MaritalStatus'): '';
    const relativeInCountry = localStorage.getItem('doYouHaveImmediateRelativesInTheCountryOfChoice') ?  localStorage.getItem('doYouHaveImmediateRelativesInTheCountryOfChoice'): '';

    const handleSubmit = async () => {

        const Personal_Information ={
            'Visa_Type': localStorage.getItem('VisaType')  ?  localStorage.getItem('VisaType'): '',
            'Title': localStorage.getItem('FirstNameTitle')  ?  localStorage.getItem('FirstNameTitle'): '',
            'First_Name': localStorage.getItem('FirstName')  ?  localStorage.getItem('FirstName'): '',
            'Last_Name': localStorage.getItem('LastName')  ?  localStorage.getItem('LastName'): '',
            'middle_Name': localStorage.getItem('middleName')  ?  localStorage.getItem('middleName'): '',
            'Nationality': localStorage.getItem('Nationality')  ?  localStorage.getItem('Nationality'): '',
            'Gender': localStorage.getItem('Gender')  ?  localStorage.getItem('Gender'): '',
            'date_of_birth': localStorage.getItem('dateOOfBirth')  ?  localStorage.getItem('dateOOfBirth'): '',
            'Marital_Status': localStorage.getItem('MaritalStatus')  ?  localStorage.getItem('MaritalStatus'): '',
            'place_of_birth': localStorage.getItem('placeOfBirth')  ?  localStorage.getItem('placeOfBirth'): '',
            'Upload_Passport_Photo': localStorage.getItem('visa_passport_photo1')  ?  localStorage.getItem('visa_passport_photo1'): '',
        }

        const Contact_Information ={
            'home_phone_number_code':localStorage.getItem('homePhoneNumberCode')  ?  localStorage.getItem('homePhoneNumberCode'): '',
            'home_phone_number':localStorage.getItem('homePhoneNumber')  ?  localStorage.getItem('homePhoneNumber'): '',
            'work_phone_number_code':localStorage.getItem('workPhoneNumberCode')  ?  localStorage.getItem('workPhoneNumberCode'): '',
            'work_phone_number':localStorage.getItem('workPhoneNumber')  ?  localStorage.getItem('workPhoneNumber'): '',
            'address':localStorage.getItem('address')  ?  localStorage.getItem('address'): '',
            'country':localStorage.getItem('applicantCountry')  ?  localStorage.getItem('applicantCountry'): '',
            'state':localStorage.getItem('applicantState')  ?  localStorage.getItem('applicantState'): '',
            'city':localStorage.getItem('applicantCity')  ?  localStorage.getItem('applicantCity'): '',
            'zip_code':localStorage.getItem('applicatZipCode')  ?  localStorage.getItem('applicatZipCode'): '',
            'email':localStorage.getItem('email')  ?  localStorage.getItem('email'): '',
        }

        const Travel_Information ={
            'principal_applicant':localStorage.getItem('areYouThePrincipalApplicant')  ?  localStorage.getItem('areYouThePrincipalApplicant'): '',
            'intended_departure_date':localStorage.getItem('intendedDepartureDate')  ?  localStorage.getItem('intendedDepartureDate'): '',
            'intended_arrival_date':localStorage.getItem('intendedArrivalDte')  ?  localStorage.getItem('intendedArrivalDte'): '',
            'purpose_of_trip':localStorage.getItem('PurposeOfTrip')  ?  localStorage.getItem('PurposeOfTrip'): '',
            'no_of_travelers':localStorage.getItem('NoOfTravelers')  ?  localStorage.getItem('NoOfTravelers'): '',
            'address_where_you_stay':localStorage.getItem('AddressWhereYou')  ?  localStorage.getItem('AddressWhereYou'): '',
            'entity_paying':localStorage.getItem('Person/EntityPayingForTrip')  ?  localStorage.getItem('Person/EntityPayingForTrip'): '',
            'name_of_person_paying_for_trip':localStorage.getItem('NameOfPersonPayingForTrip')  ?  localStorage.getItem('NameOfPersonPayingForTrip'): '',
            'home_phone_number_code_entity_paying': localStorage.getItem('personPayingForTripPhoneNumberCode')  ?  localStorage.getItem('personPayingForTripPhoneNumberCode'): '',
            'home_phone_number_entity_paying': localStorage.getItem('personPayingForTripPhoneNumber')  ?  localStorage.getItem('personPayingForTripPhoneNumber'): '',
            'relationship_to_you':localStorage.getItem('RelationshipToYou')  ?  localStorage.getItem('RelationshipToYou'): '',
            'are_you_the_party_paying_for_the_trip' :
                areOthersTravellingWithYou__ === 'Yes' ? localStorage.getItem('areYouThePartyPayingForTheTrip')  ?  localStorage.getItem('areYouThePartyPayingForTheTrip'): ''
                    :
                    {
                        'No': {
                            'address_party_paying': localStorage.getItem('addressOfPartyPayingForTrip') ? localStorage.getItem('addressOfPartyPayingForTrip') : '',
                            'country_party_paying': localStorage.getItem('PartyPayingForTripCountry') ? localStorage.getItem('PartyPayingForTripCountry') : '',
                            'state_party_paying': localStorage.getItem('PartyPayingForTripState') ? localStorage.getItem('PartyPayingForTripState') : '',
                            'city_party_paying': localStorage.getItem('PartyPayingForTripCity') ? localStorage.getItem('PartyPayingForTripCity') : '',
                            'zip_code_party_paying': localStorage.getItem('PartypayingforTripZipCode') ? localStorage.getItem('PartypayingforTripZipCode') : '',
                            'email_party_paying': localStorage.getItem('emailOfPartyPayingForTrip') ? localStorage.getItem('emailOfPartyPayingForTrip') : '',
                        },
                    },
            'Others_traveling_with_you':
                areOthersTravellingWithYou !== 'No' ?
                    {
                        'Yes': {
                            'Relationship_to_you': localStorage.getItem('relationshipToYou') ? localStorage.getItem('relationshipToYou') : '',
                            'Scanned_Copy_of_Data_Page': data_urls,
                            'Scanned_Passport_Photograph': passport_urls,
                        }
                    }
                    :
                    localStorage.getItem('areOthersTravellingWithYou'),
            'been_to_this_location_before': areOthersTravellingWithYou_ === 'Yes'?
                {
                    'Yes':{'passport_image': localStorage.getItem('visa_passport_photo2') ? localStorage.getItem('visa_passport_photo2') : ''}
                }
                :
                localStorage.getItem('haveYouBeenToThisLocationBefore'),
        }

        const Passport_Details={
            'Passport Number': localStorage.getItem('PassportNumber'),
            'Passport Book Number': localStorage.getItem('PassportBookNumber'),
            'Country/Authority that issued passport': localStorage.getItem('issuedpassportCountry'),
            'State where issued': localStorage.getItem('issuedpassportState'),
            'City where issued': localStorage.getItem('issuedpassportCity'),
            'Postal Zone/Zip Code': localStorage.getItem('issuedpassportZipCode'),
            'Issuance Date': localStorage.getItem('IssuanceDate'),
            'Expiry Date': localStorage.getItem('ExpiryDate'),
            'Have you ever lost your passport or had one stolen?':
                areOthersTravellingWithYou______ !== 'No' ?
                    {
                        'Yes':{
                            'Passport Number(Stolen)': localStorage.getItem('PassportNumberOfStolenPassPort') ? localStorage.getItem('PassportNumberOfStolenPassPort') : '',
                            'How did it get lost?': localStorage.getItem('HowDidItGetLost') ? localStorage.getItem('HowDidItGetLost') : '',
                        }
                    }:
                    localStorage.getItem('haveYouEverLostYourPassportOrHadOneStolen'),
        }

        const Point_of_Contact_Information = {
            'Title': localStorage.getItem('PointOfContactFirstNameTitle'),
            'First name': localStorage.getItem('PointOfContactFirstName'),
            'Last name': localStorage.getItem('PointOfContactLastName'),
            'Organization(if any)': localStorage.getItem('PointOfContactOrganization'),
            'Relationship to you': localStorage.getItem('PointOfContactRelationshipToYou'),
            'Country Contact Address': localStorage.getItem('PointOfContactCountryContactAddress'),
            'Zip Code': localStorage.getItem('PointOfContactZipCode'),
            'Home Phone Number code': localStorage.getItem('pointOfContactPhoneNumberCode'),
            'Home Phone Number': localStorage.getItem('pointOfContactPhoneNumber'),
            'Email': localStorage.getItem('PointOfContactEmail'),
        }

        const spouseInformation = RelationshipStatus === 'Married' ? {
            'Spouse title': localStorage.getItem('SpouseNameTitle'),
            'Spouse First name': localStorage.getItem('SpouseFirstName'),
            'Spouse Last name': localStorage.getItem('SpouseLastName'),
            'Spouse Date of Birth': localStorage.getItem('SpouseDateOfBirth'),
            'Spouse Nationality': localStorage.getItem('SpouseNationality'),
            'Spouse Place of Birth': localStorage.getItem('SpousePlaceOfBirth'),
            'Spouse Home Address': localStorage.getItem('SpouseAddress'),
        } : {RelationshipStatus};

        const Family_Information = {
            'Fathers title': localStorage.getItem('FatherNameTitle'),
            'Fathers First name': localStorage.getItem('FatherFirstName'),
            'Fathers Last name': localStorage.getItem('FatherLastName'),
            'Fathers Date of Birth': localStorage.getItem('FatherDateOfBirth'),
            'Is your father in the country of choice?': localStorage.getItem('fatherInCountry'),
            'Mothers Title': localStorage.getItem('MotherNameTitle'),
            'Mothers First name': localStorage.getItem('MotherFirstName'),
            'Mothers Last name': localStorage.getItem('MotherLastName'),
            'Mothers Date of Birth': localStorage.getItem('MotherDateOfBirth'),
            'Is your Mother in the country of choice?': localStorage.getItem('isYourMotherInTheCountryOfChoice'),
            'SpouseInformation' : spouseInformation,
            'Do you have immediate relatives in the country of choice(not parents)': localStorage.getItem('doYouHaveImmediateRelativesInTheCountryOfChoice'),
            'Relative title': localStorage.getItem('RelativeNameTitle'),
            'Relative First name': localStorage.getItem('RelativeFirstName'),
            'Relative Last name': localStorage.getItem('RelativeLastName'),
            'Relative Date of Birth': localStorage.getItem('RelativeDateOfBirth'),
            'Relatives Country residence status': localStorage.getItem('RelativeCountryresidenceStatus'),
        }

        const Employment_Information = {
            'Employment Information': localStorage.getItem('employmentInfo'),
            'Present/Previous Employer': localStorage.getItem('PresentEmployer'),
            'Monthly salary in local country': localStorage.getItem('MonthlySalaryInLocalCountry'),
            'Country': localStorage.getItem('EmploymentAddressCountry'),
            'State/Province': localStorage.getItem('EmploymentAddressState'),
            'City': localStorage.getItem('EmploymentAddressCity'),
            'Postal Zone/Zip Code': localStorage.getItem('employmentaddressZipCode'),
            'Employment Address': localStorage.getItem('EmploymentAddress'),
            'Name of the person paying for the trip(if any)': localStorage.getItem('NameOfThePersonPayingForTheTripAtEmployment'),
            'Office/Work Contact Number Code': localStorage.getItem('officePhoneNumberCode'),
            'Office/Work Contact Number': localStorage.getItem('officePhoneNumber'),
            'Describe your Duties': localStorage.getItem('DescribeYourDuties'),
        }

        const Academic_Information = {
            'Did you attend any educational institution at a secondary level or above':
                areOthersTravellingWithYou___ !== 'No' ?
                    {
                        'Yes':
                            {
                                'Name of Institution': localStorage.getItem('NameOfInstitution'),
                                'Course of study': localStorage.getItem('CourseOfStudy'),
                                'State/Province': localStorage.getItem('academicState'),
                                'City': localStorage.getItem('academicCity'),
                                'Address': localStorage.getItem('academicAddress'),
                                'Date attended from': localStorage.getItem('academicDAteAttendedFrom'),
                                'Date finished': localStorage.getItem('academicDateFinished'),
                             }
                    } :
                    localStorage.getItem('didYouAttendAnyEducationalInstitutionAtASecondaryLevelOrAbove'),
        }

        const Security_and_Background_Information = {
            'Have traveled to any country in the last five(5) years?':
                areOthersTravellingWithYou____ !== 'No' ?
                    {
                        'Yes':
                            {
                                'Name of Country': localStorage.getItem('NameOfCountry'),
                            }
                    }
                    :
                     localStorage.getItem('haveTraveledToAnyCountryInTheLastFive'),


            'Do you belong to any Tribe?':
                areOthersTravellingWithYou_____ !== 'No'  ?
                    {
                        'Yes':
                            {
                                'The name of Clans Tribe': localStorage.getItem('TheNameOfClanTribe'),
                            }
                    }
                    :
                     localStorage.getItem('doYouBelongToAnyTribe'),

            'List of languages you speak including English': localStorage.getItem('ListOfLanguagesYouSpeakIncludingEnglish'),
            'I understand and agree with the Terms and Conditions.': checkboxChecked,
        }





        const visa_data = {
            'Personal Information': Personal_Information,
             //step 2
            'Contact Information': Contact_Information,
            // step 3
            'Travel Information': Travel_Information,
            //step 4
            'Passport Details': Passport_Details,
            //step 5
            'Point of Contact Information': Point_of_Contact_Information,
            //step 6
            'Family Information': Family_Information,
            // step 7
            'Employment Information': Employment_Information,
            // step 8
            'Academic Information': Academic_Information,
            'Security and Background Information': Security_and_Background_Information,
    };


        setPopUp('Loading.....')

        try {
            const formData = new FormData();
            formData.append('jarom', 'jaromtravels');
            formData.append('jarom_subject', 'visa');
            formData.append('jarom_message', JSON.stringify(visa_data));


            const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 201) {
                setPopUp('Success ✓')
                // console.log(response);
                // setTimeout(setPopUp(false), 20000)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setPopUp('Failed ×')
        }
    };

    useEffect(() => {
        if (showPop) {
            setTimeout(() => {
                setAdditionalClass('animate__fadeOut');
            }, 4000);
            setTimeout(() => {
                setShowPop(false);
            }, 5000);
        }
    }, [showPop]);
    const handleClick = () => {
        setShowPop(true);
        setAdditionalClass('');
    };
    const screenWidth = window.innerWidth;
    const handleFirstDivClick = () => {
        setShowTerms(true);
        setShowStep(false)
        scrollToTop();
    };

    const handleSecondDivClick = () => {
        setShowTerms(false);
        setShowStep(true)
        scrollToTop();
    };


    const callAllRadio=()=>{
        radioUpdate________()
        radioUpdate()
        radioUpdate_()
        radioUpdate__()
        radioUpdate___()
        radioUpdate____()
        radioUpdate_____()
        radioUpdate______()
        radioUpdate_______()
    }


useEffect(() => {
    callAllRadio();
}, [
            areOthersTravellingWithYou,
            areOthersTravellingWithYou_,
            areOthersTravellingWithYou__,
            areOthersTravellingWithYou___,
            areOthersTravellingWithYou____,
            areOthersTravellingWithYou_____,
            areOthersTravellingWithYou______,
            areOthersTravellingWithYou_______,
            areOthersTravellingWithYou________,
            ]);





    return (
        <>
            <Helmet>
                <title>Visa Application - Jarom Travel Agency</title>
                <meta name="robots" content="index" />
                <meta name="description" content="Apply for a visa with Jarom travel agency. Fill out the form to start your visa application process quickly and easily." />
                <meta name="keywords" content="visa application, travel visa, apply for visa, travel agency" />
                <link rel="canonical" href="https://jaromtravels.com/visa" />
                <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
                </script>
            </Helmet>
            <div style={{paddingTop: '10px'}}>
                <form onSubmit={handleNext}>
                    {step === 1 && (
                        <div>
                            <div className='visa_progressBar'>
                                <div className='visa_progress' style={{width: '1%'}}></div>
                                <div className='visa_percent'>
                                    1% completed
                                </div>
                            </div>
                            <div className='visaDivider'>
                                <div className='visaLeft'>
                                    <div className="visa_head">Visa</div>
                                    {screenWidth < 760 ? (
                                        <VisaSidder name='FirstVisaType' className='visaTopper'/>
                                    ) : null}
                                    <div className="visa_information">
                                        <h1 className='heading'>
                                            Applicants Information <span className='ssl'><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685931761/div_wljwjn.svg'/></span>
                                        </h1>
                                        <div className='more-info'>
                                            Personal Information<br/>
                                            <div className='visa_cc' id='correct_'>
                         <span>
                        Use all given information and biodata exactly as they appear on your passport/ID to avoid complications.
                    </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='visa_details'>
                                        <div className='visaFirstLast'>
                                            <VisaDoubleDropdownInputField localStorageName='FirstNameTitle'
                                                                          localStorageNameInput="FirstName"
                                                                          labelName='First name'
                                                                          star='*'
                                                                          firstPlaceHolder='Title'
                                                                          type='text'
                                                                          required={true}
                                                                          name='FirstName'
                                                                          placeholder='First Name as on intl. passport'>

                                            </VisaDoubleDropdownInputField>

                                            <VisaInputField labelName='Last name' star='*' type='text'
                                                            localStorageName='LastName'
                                                            placeholder='Surname as on intl. passport' required={true}>

                                            </VisaInputField>
                                        </div>
                                        <div className='visaFirstLast'>
                                            <div>
                                                <VisaInputField localStorageName='middleName' labelName='Middle Name'
                                                                type='text'
                                                                placeholder='Other Name as on intl. passport'
                                                                required={true}>

                                                </VisaInputField>
                                            </div>
                                            <div className='visaIn'>
                                                <div className='visa_inputField ' id='inputTop'>
                                                    <label>
                                                        Nationality<span>*</span>
                                                    </label>
                                                    <TitleDropdown name='Nationality' mfont='10px' mxfont='14px'
                                                                   mwidth='113px' mxwidth='158px'
                                                                   localStorageName='Nationality' options={countries}
                                                                   title="Country"
                                                                   className='visa_input'/>
                                                </div>
                                                <div className='visa_inputField ' id='inputTop'>
                                                    <label>
                                                        Gender<span>*</span>
                                                    </label>
                                                    <TitleDropdown name='Gender' localStorageName='Gender' mwidth='113px'
                                                                   mxfont='14px' mxwidth='158px' mfont='10px'
                                                                   options={gender} title="Select"
                                                                   className='visa_input'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='visaFirstLast'>
                                            <VisaInputField localStorageName='dateOOfBirth' labelName='Date of Birth'
                                                            star='*'
                                                            type='date'
                                                            placeholder=''
                                                            required={true}>

                                            </VisaInputField>
                                            <div className='visa_inputField ' id='inputTop'>
                                                <label>
                                                    Marital Status<span>*</span>
                                                </label>
                                                <TitleDropdown name='MaritalStatus' localStorageName='MaritalStatus'
                                                               mxfont='14px' mfont="10px" mxwidth='325px' mwidth='236px'
                                                               options={maritalStatus}
                                                               title="Select"
                                                               required={undefined}
                                                               className='visa_input'/>
                                            </div>
                                        </div>

                                        <VisaInputField localStorageName='placeOfBirth' labelName='Place of Birth' star='*'
                                                        type='text'
                                                        placeholder='Place of Birth'
                                                        required={true}>

                                        </VisaInputField>
                                        <div className='visaFirstLast' id='visa_sizeWarning'>
                                            <VisaInputField localStorageName='uploadPassportPhoto'
                                                            additionalFunction={handleFileChange}
                                                            labelName='Upload Passport Photo'
                                                            star='*' type='file' placeholder=''
                                                            required={true}>

                                            </VisaInputField>
                                            {fileSizeExceeded && (
                                                <div className='visa_sizeWarning'>
                                                    All file upload must not exceed 500kB
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className='visa_casing'>
                                        <button type='submit' className='visa_next' >
                                            Next page<span><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                        </button>
                                    </div>
                                </div>
                                <div className='visaRight'>
                                    {screenWidth > 760 ? (
                                        <VisaSidder name='VisaType' className='visaSidder'/>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                    )}
                    {step === 2 && (
                        <div>
                            <div className='visa_progressBar'>
                                <div className='visa_progress' style={{width: '11%'}}></div>
                                <div className='visa_percent'>
                                    11% completed
                                </div>
                            </div>
                            <div className="visaDivider">
                                <div className='visaLeft'>
                                    <div className="visa_head visaSidder">Visa</div>
                                    <div className="visa_information">
                                        <div className='heading'>
                                            Applicants Information<span className='ssl'><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685931761/div_wljwjn.svg'/></span>
                                        </div>
                                        <div className='more-info'>
                                            Contact Information
                                        </div>
                                    </div>
                                    <div className='visa_details'>
                                        <div className='visaFirstLast'>
                                            <VisaDoubleInputField localStorageName='homePhoneNumber'
                                                                  localTitleStorage='homePhoneNumberCode'
                                                                  labelName='Home Phone Number' star='*'
                                                                  firstPlaceHolder='+234' type='tel'
                                                                  placeholder='Phone Number' required={true} value_i='+234'>
                                            </VisaDoubleInputField>
                                            <VisaDoubleInputField localStorageName='workPhoneNumber'
                                                                  localTitleStorage='workPhoneNumberCode'
                                                                  labelName='Work Phone Number' star='*'
                                                                  firstPlaceHolder='+234' type='tel'
                                                                  placeholder='Phone Number' value_i='+234' required={true}>
                                            </VisaDoubleInputField>
                                        </div>

                                        <VisaInputField localStorageName='address' id='visa-Input' labelName='Address'
                                                        star='*' type='text'
                                                        placeholder='Address' required={true}>
                                        </VisaInputField>
                                        <DropdownAll mxwidth='321px' mfont='10px' mxfont='14px' mwidth='237px'
                                                     localStorageCountry='applicantCountry' localStorageCity='applicantCity'
                                                     localStorageState='applicantState' localStoragezip='applicatZipCode'
                                                     Country_='Country' State_='State' City_='City'/>

                                        <VisaInputField localStorageName='email' labelName='Email' star='*' type='text'
                                                        placeholder='example@gmail.com'
                                                        required={true}>
                                        </VisaInputField>
                                    </div>
                                    <div className='visa_casing'>
                                        <button className='visa_next' type='submit'>
                                            Next page<span><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                        </button>
                                        <button className='visa_next visaCent' id='timiButton' onClick={handlePrevious}>
                                            <img id='visaPrev'
                                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Previous page</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='visaRight'>
                                    {screenWidth > 760 ? (
                                        <VisaSidder required={false} name='VisaType' className='visaSidder'/>
                                    ) : null}
                                </div>

                            </div>

                        </div>
                    )}
                    {step === 3 && (
                        <div>
                            <div className='visa_progressBar'>
                                <div className='visa_progress' style={{width: '21%'}}></div>
                                <div className='visa_percent'>
                                    21% completed
                                </div>
                            </div>
                            <div className="visaDivider">
                                <div className="visaLeft">
                                    <div className="visa_head visaSidder">Visa</div>
                                    <div className="visa_information">
                                        <div className='heading'>
                                            Applicants Information <span className='ssl'><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685931761/div_wljwjn.svg'/></span>
                                        </div>
                                        <div className='more-info'>
                                            Travel Information
                                        </div>
                                    </div>
                                    <div className='visa_details'>
                                        <RadioInput text="Are you the principal applicant?"
                                                    localStorageName="areYouThePrincipalApplicant"/>
                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName='intendedDepartureDate'
                                                            labelName='Intended Departure Date'
                                                            star='*' type='date' placeholder=''
                                                            required={true}>
                                            </VisaInputField>
                                            <VisaInputField localStorageName='intendedArrivalDte'
                                                            labelName='Intended Arrival Date' star='*'
                                                            type='date' placeholder=''
                                                            required={true}>

                                            </VisaInputField>
                                        </div>

                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName='PurposeOfTrip' labelName='Purpose of Trip'
                                                            star='*'
                                                            type='textarea' placeholder=''
                                                            required={true}>

                                            </VisaInputField>
                                            <VisaInputField localStorageName='NoOfTravelers' labelName='No. of Travelers'
                                                            star='*'
                                                            type='text' placeholder=''
                                                            required={true}>
                                            </VisaInputField>
                                        </div>
                                        <VisaInputField id='visa-Input' localStorageName='AddressWhereYou'
                                                        labelName='Address where you stay' star='*'
                                                        type='text' placeholder=''
                                                        required={true}>
                                        </VisaInputField>
                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName='Person/EntityPayingForTrip'
                                                            labelName='Person/Entity paying for Trip' star='*' type='text'
                                                            placeholder=''
                                                            required={true}>
                                            </VisaInputField>
                                            <VisaInputField localStorageName='NameOfPersonPayingForTrip'
                                                            labelName='Name of person paying for Trip(If any)' star='*'
                                                            type='text'
                                                            placeholder=''
                                                            required={true}>
                                            </VisaInputField>
                                        </div>

                                        <div className="visaFirstLast">
                                            <VisaDoubleInputField localStorageName='personPayingForTripPhoneNumber'
                                                                  localTitleStorage='personPayingForTripPhoneNumberCode'
                                                                  labelName='Home Phone Number' star='*'
                                                                  firstPlaceHolder='+234' type='tel'
                                                                  placeholder='Phone Number' value_i='+234'>
                                            </VisaDoubleInputField>

                                            <VisaInputField localStorageName='RelationshipToYou'
                                                            labelName='Relationship to you' star='*'
                                                            type='text'
                                                            placeholder='' required={true}>
                                            </VisaInputField>
                                        </div>

                                        <RadioInput onClick={radioUpdate__} text="Are you the party paying for the trip?"
                                                    localStorageName="areYouThePartyPayingForTheTrip"/>
                                        {areOthersTravellingWithYou__ !== 'Yes' && (
                                            <div>
                                                <VisaInputField id='visa-Input'
                                                                localStorageName='addressOfPartyPayingForTrip'
                                                                labelName='Address'
                                                                star='*' type='text' placeholder='Home Address'
                                                                required={true}>
                                                </VisaInputField>
                                                <DropdownAll mxwidth='321px' mfont='10px' mxfont='14px' mwidth='237px'
                                                             localStorageCountry='PartyPayingForTripCountry'
                                                             localStorageCity='PartyPayingForTripCity'
                                                             localStoragezip='PartypayingforTripZipCode'
                                                             localStorageState='PartyPayingForTripState' Country_='Country'
                                                             State_='State' City_='City'/>
                                                <VisaInputField localStorageName='emailOfPartyPayingForTrip'
                                                                labelName='Email' star='*'
                                                                type='email' placeholder='example@gmail.com'
                                                                required={true}>
                                                </VisaInputField>
                                            </div>
                                        )}
                                        <RadioInput onClick={radioUpdate} text="Others traveling with you?"
                                                    localStorageName="areOthersTravellingWithYou"/>
                                        {areOthersTravellingWithYou !== 'No' && (
                                            <div>
                                                <RelationshipRadioInput localStorageName='relationshipToYou'/>
                                                <div className="visaFirstLast">
                                                    <MultipleFileInputs heading='Scanned Copy of Data Page' diff='squamous' buttonName='' fileExceeded={fileSizeExceeded} getUploadURL={getUploadURL} additionalFunction={handleFileChange}/>
                                                    <MultipleFileInputs heading='Scanned Passport Photograph' diff='startified' buttonName='Add Passport Photograph' fileExceeded={fileSizeExceeded} getUploadURL={getUploadURL} additionalFunction={handleFileChange}/>
                                                </div>
                                            </div>
                                        )}

                                        <br/>
                                        <RadioInput onClick={radioUpdate_} text="Have you been to this location before?"
                                                    localStorageName="haveYouBeenToThisLocationBefore"/>
                                        {areOthersTravellingWithYou_ !== 'No' && (
                                            <div>
                                                <VisaInputField localStorageName='UploadScannedVisaPage/DataPage'
                                                                labelName='Upload scanned Visa page/Data page' star='*'
                                                                type='file'
                                                                placeholder=''
                                                                additionalFunction={handleFileChange}
                                                                required={true}>

                                                </VisaInputField>
                                                {fileSizeExceeded && (
                                                    <div className='visa_sizeWarning'>
                                                        All file upload must not exceed 500kB
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className='visa_casing'>
                                        <button className='visa_next' type='submit'>
                                            Next page<span><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                        </button>
                                        <button className='visa_next visaCent' id='timiButton' onClick={handlePrevious}>
                                            <img id='visaPrev'
                                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Previous page</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='visaRight'>
                                    {screenWidth > 760 ? (
                                        <VisaSidder name='VisaType' required={false} className='visaSidder'/>
                                    ) : null}
                                </div>
                            </div>


                        </div>
                    )}
                    {step === 4 && (
                        <div>
                            <div className='visa_progressBar'>
                                <div className='visa_progress' style={{width: '51%'}} id='visaProg'></div>
                                <div className='visa_percent'>
                                    51% completed
                                </div>
                            </div>
                            <div className="visaDivider">
                                <div className='visaLeft'>
                                    <div className="visa_head visaSidder">Visa</div>
                                    <div className="visa_information">
                                        <div className='heading'>
                                            Applicants Information <span className='ssl'><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685931761/div_wljwjn.svg'/></span>
                                        </div>
                                        <div className='more-info'>
                                            Passport Details
                                        </div>
                                    </div>
                                    <div className='visa_details'>

                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName='PassportNumber' labelName='Passport Number'
                                                            star='*'
                                                            type='number'
                                                            placeholder='e.g A12356789' value_i='+234' required={true}>
                                            </VisaInputField>
                                            <VisaInputField localStorageName='PassportBookNumber'
                                                            labelName='Passport Book Number'
                                                            value_i='+234' star='*' type='number'
                                                            placeholder='e.g C11163131' required={true}>
                                            </VisaInputField>
                                        </div>
                                        <DropdownAll mxwidth='321px' mfont='10px' mxfont='14px' mwidth='237px'
                                                     localStorageCountry='issuedpassportCountry'
                                                     localStorageCity='issuedpassportCity'
                                                     localStoragezip='issuedpassportZipCode'
                                                     localStorageState='issuedpassportState'
                                                     Country_='Country/Authority that issued passport'
                                                     State_='State where issued'
                                                     City_='City where issued'/>
                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName='IssuanceDate' labelName='Issuance Date'
                                                            star='*' type='date'
                                                            placeholder='DD/MM/YYY' required={true}>
                                            </VisaInputField>
                                            <VisaInputField localStorageName='ExpiryDate' labelName='Expiry Date' star='*'
                                                            type='date'
                                                            placeholder='DD/MM/YYY' required={true}>
                                            </VisaInputField>
                                        </div>
                                    </div>
                                    <div className="visa_information" id='visa_info'>
                                        <div className='heading'>
                                            Applicants Information
                                        </div>
                                        <div className='more-info'>
                                            Passport Details
                                        </div>
                                        <RadioInput onClick={radioUpdate______}
                                                    text="Have you ever lost your passport or had one stolen?"
                                                    localStorageName="haveYouEverLostYourPassportOrHadOneStolen"/>
                                        {areOthersTravellingWithYou______ !== 'No' && (
                                            <div>
                                                <VisaInputField localStorageName='PassportNumberOfStolenPassPort'
                                                                labelName='Passport Number(Stolen)' star='*' type='number'
                                                                placeholder='e.g A123456789' required={true}>
                                                </VisaInputField>
                                                <VisaInputField id='visa-Input' localStorageName='HowDidItGetLost'
                                                                labelName='How did it get lost?' star='*'
                                                                type='text'
                                                                placeholder='' required={true}>
                                                </VisaInputField>

                                            </div>
                                        )}
                                    </div>
                                    <div className='visa_casing'>
                                        <button className='visa_next' type='submit'>
                                            Next page<span><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                        </button>
                                        <button className='visa_next visaCent' id='timiButton' onClick={handlePrevious}>
                                            <img id='visaPrev'
                                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Previous page</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='visaRight'>
                                    {screenWidth > 760 ? (
                                        <VisaSidder name='VisaType' required={false} className='visaSidder'/>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}
                    {step === 5 && (
                        <div>
                            <div className='visa_progressBar'>
                                <div className='visa_progress' style={{width: '61%'}}></div>
                                <div className='visa_percent'>
                                    61% completed
                                </div>
                            </div>
                            <div className="visaDivider">
                                <div className='visaLeft'>
                                    <div className="visa_head visaSidder">Visa</div>
                                    <div className="visa_information">
                                        <div className='heading'>
                                            Applicants Information
                                        </div>
                                        <div className='more-info'>
                                            Point of Contact Information<br/>
                                            <div className='visa_cc' id='correct_'>
                         <span>
                        The information to be provided in this section is for the person or entity you will be meeting in your abroad country of choice.
                    </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='visa_details'>
                                        <div className="visaFirstLast">
                                            <VisaDoubleDropdownInputField localStorageName="PointOfContactFirstNameTitle"
                                                                          localStorageNameInput="PointOfContactFirstName"
                                                                          labelName='First name' star='*'
                                                                          firstPlaceHolder='Title'
                                                                          type='text'
                                                                          placeholder='First Name '>
                                            </VisaDoubleDropdownInputField>

                                            <VisaInputField localStorageName='PointOfContactLastName' labelName='Last name'
                                                            star='*'
                                                            type='text'
                                                            placeholder='Last Name ' required={true}>

                                            </VisaInputField>
                                        </div>
                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName='PointOfContactOrganization'
                                                            labelName='Organization(if any)'
                                                            type='text'
                                                            placeholder='Organization'>

                                            </VisaInputField>
                                            <VisaInputField localStorageName='PointOfContactRelationshipToYou'
                                                            labelName='Relationship to you' star='*' type='text'
                                                            placeholder='' required={true}>

                                            </VisaInputField>
                                        </div>

                                        <VisaInputField id='visa-Input'
                                                        localStorageName='PointOfContactCountryContactAddress'
                                                        labelName='Country Contact Address' star='*' type='text'
                                                        placeholder='Home Address' required={true}>

                                        </VisaInputField>
                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName='PointOfContactZipCode' labelName='Zip Code'
                                                            star='*'
                                                            type='text'
                                                            placeholder='Zip Code' required={true}>

                                            </VisaInputField>
                                            <VisaDoubleInputField localStorageName='pointOfContactPhoneNumber'
                                                                  localTitleStorage='pointOfContactPhoneNumberCode'
                                                                  labelName='Home Phone Number' star='*'
                                                                  firstPlaceHolder='+234' type='tel'
                                                                  placeholder='Phone Number' value_i='+234'>
                                            </VisaDoubleInputField>
                                        </div>

                                        <VisaInputField localStorageName='PointOfContactEmail' labelName='Email' star='*'
                                                        type='text'
                                                        placeholder='example@gmail.com' required={true}>

                                        </VisaInputField>

                                    </div>

                                    <div className='visa_casing'>
                                        <button className='visa_next' type='submit'>
                                            Next page<span><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                        </button>
                                        <button className='visa_next visaCent' id='timiButton' onClick={handlePrevious}>
                                            <img id='visaPrev'
                                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Previous page</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='visaRight'>
                                    {screenWidth > 760 ? (
                                        <VisaSidder name='VisaType' required={false} className='visaSidder'/>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                    )}
                    {step === 6 && (
                        <div>
                            <div className='visa_progressBar'>
                                <div className='visa_progress' style={{width: '71%'}}></div>
                                <div className='visa_percent'>
                                    71% completed
                                </div>
                            </div>
                            <div className="visaDivider">
                                <div className='visaLeft'>
                                    <div className="visa_head visaSidder">Visa</div>
                                    <div className="visa_information">
                                        <div className='heading'>
                                            Applicants Information
                                        </div>
                                        <div className='more-info'>
                                            Family Information<br/>
                                        </div>
                                    </div>
                                    <div className='visa_details'>
                                        <div className="visaFirstLast">
                                            <VisaDoubleDropdownInputField localStorageName="FatherNameTitle"
                                                                          localStorageNameInput="FatherFirstName"
                                                                          labelName="Father's First name" star='*'
                                                                          firstPlaceHolder='Title'
                                                                          type='text'
                                                                          placeholder='First Name '>
                                            </VisaDoubleDropdownInputField>

                                            <VisaInputField localStorageName="FatherLastName" labelName="Father's Last name"
                                                            star='*'
                                                            type='text'
                                                            placeholder='Surname' required={true}>

                                            </VisaInputField>
                                        </div>
                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName="FatherDateOfBirth"
                                                            labelName="Father's Date of Birth" star='*'
                                                            type='date'
                                                            placeholder=''>

                                            </VisaInputField>
                                            <RadioInput text="Is your father in the country of choice?"
                                                        localStorageName="fatherInCountry"/>
                                        </div>
                                        <div className="visaFirstLast">
                                            <VisaDoubleDropdownInputField localStorageName="MotherNameTitle"
                                                                          localStorageNameInput="MotherFirstName"
                                                                          labelName="Mother's First name" star='*'
                                                                          firstPlaceHolder='Title'
                                                                          type='text'
                                                                          placeholder='First Name '>
                                            </VisaDoubleDropdownInputField>
                                            <VisaInputField localStorageName="MotherLastName" labelName="Mother's Last name"
                                                            star='*'
                                                            type='text'
                                                            placeholder='Surname' required={true}>

                                            </VisaInputField>
                                        </div>
                                        <div className="visaFirstLast">
                                            <VisaInputField localStorageName="MotherDateOfBirth"
                                                            labelName="Mother's Date of Birth" star='*'
                                                            type='date'
                                                            placeholder=''>
                                            </VisaInputField>
                                            <RadioInput text="Is your mother in the country of choice?"
                                                        localStorageName="isYourMotherInTheCountryOfChoice"/>
                                        </div>
                                        {RelationshipStatus === 'Married' && (
                                            <>
                                                <div className="visaFirstLast">
                                                    <VisaDoubleDropdownInputField localStorageName="SpouseNameTitle"
                                                                                  localStorageNameInput="SpouseFirstName"
                                                                                  labelName="Spouse First name" star='*'
                                                                                  firstPlaceHolder='Title'
                                                                                  type='text'
                                                                                  placeholder='First Name '>
                                                    </VisaDoubleDropdownInputField>
                                                    <VisaInputField localStorageName="SpouseLastName" labelName="Spouse Last name"
                                                                    star='*'
                                                                    type='text'
                                                                    placeholder='Surname' required={true}>

                                                    </VisaInputField>
                                                </div>
                                                <div className="visaFirstLast">
                                                    <VisaInputField localStorageName="SpouseDateOfBirth"
                                                                    labelName="Spouse Date of Birth" star='*'
                                                                    type='date'
                                                                    placeholder=''>
                                                    </VisaInputField>
                                                    <div className='visa_inputField ' id='inputTop'>
                                                        <label>
                                                            Spouse Nationality<span>*</span>
                                                        </label>
                                                        <TitleDropdown mfont='10px' mxfont='14px'
                                                                       localStorageName='SpouseNationality' options={countries}
                                                                       title="Nigeria"
                                                                       className='visa_input'/>
                                                    </div>
                                                </div>
                                                <VisaInputField localStorageName="SpousePlaceOfBirth"
                                                                labelName="Spouse Place of Birth" star='*'
                                                                type='text'
                                                                placeholder='Place of birth'>
                                                </VisaInputField>
                                                <VisaInputField id='visa-Input' localStorageName="SpouseAddress"
                                                                labelName="Spouse Home Address" star='*'
                                                                type='text'
                                                                placeholder='Address'>
                                                </VisaInputField>
                                            </>
                                        )}



                                    </div>
                                    <div className='visa_details'>
                                        <RadioInput
                                            onClick={radioUpdate_______}
                                            text="Do you have immediate relatives in the country of choice(not parents)?"
                                            localStorageName="doYouHaveImmediateRelativesInTheCountryOfChoice"/>
                                        {/*show tthe rest if only the person has a relative*/}

                                        {areOthersTravellingWithYou_______ === 'Yes' &&
                                            <div className='visa_inputField ' id='inputTop'>
                                                <div className="visaFirstLast">
                                                    <VisaDoubleDropdownInputField localStorageName="RelativeNameTitle"
                                                                                  localStorageNameInput="RelativeFirstName"
                                                                                  labelName="Relative First name" star='*'
                                                                                  firstPlaceHolder='Title'
                                                                                  type='text'
                                                                                  placeholder='First Name '>
                                                    </VisaDoubleDropdownInputField>
                                                    <VisaInputField localStorageName="RelativeLastName"
                                                                    labelName="Relative Last name" star='*'
                                                                    type='text'
                                                                    placeholder='Surname' required={true}>

                                                    </VisaInputField>
                                                </div>
                                                <div className="visaFirstLast">
                                                    <VisaInputField localStorageName="RelativeDateOfBirth"
                                                                    labelName="Relative Date of Birth"
                                                                    star='*' type='date'
                                                                    placeholder=''>
                                                    </VisaInputField>
                                                    <VisaInputField localStorageName="RelativeCountryresidenceStatus"
                                                                    labelName="Relative's Country residence status"
                                                                    star='*' type='text'
                                                                    placeholder='Status'
                                                                    onClick={handleClick}>
                                                    </VisaInputField>
                                                </div>

                                                {showPop && <div
                                                    className={`visaPop animate__animated animate__fadeIn ${additionalClass} `}>
                                                    Indicate the status of the person in the country of choice, such as work,
                                                    Education, Business, Profession, Training Information
                                                </div>}

                                            </div>
                                        }

                                    </div>
                                    <div className='visa_casing'>
                                        <button className='visa_next' type='submit'>
                                            Next page<span><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                        </button>
                                        <button className='visa_next visaCent' id='timiButton' onClick={handlePrevious}>
                                            <img id='visaPrev'
                                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Previous page</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='visaRight'>
                                    {screenWidth > 760 ? (
                                        <VisaSidder name='VisaType' required={false} className='visaSidder'/>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                    )}
                    {step === 7 && (
                        <div>
                            <div className='visa_progressBar'>
                                <div className='visa_progress' style={{width: '81%'}}></div>
                                <div className='visa_percent'>
                                    81% completed
                                </div>
                            </div>
                            <div className="visaDivider">
                                <div className='visaLeft'>
                                    <div className="visa_head visaSidder">Visa</div>
                                    <div className="visa_information">
                                        <div className='heading'>
                                            Applicants Information
                                        </div>
                                        <div className='more-info'>
                                            Employment Information<br/>
                                        </div>
                                    </div>

                                    <div className='visa_details'>
                                        <EmploymentRadioInput onClick={radioUpdate________} localStorageName='employmentInfo'/>

                                        {areOthersTravellingWithYou________ !== 'Unemployed' &&
                                            <>
                                                <div className="visaFirstLast">
                                                    <VisaInputField localStorageName="PresentEmployer"
                                                                    labelName="Present/Previous Employer"
                                                                    star='*'
                                                                    type='text'
                                                                    placeholder='' required={true}>

                                                    </VisaInputField>
                                                    <VisaInputField localStorageName="MonthlySalaryInLocalCountry"
                                                                    labelName="Monthly salary in local country" star='*' type='text'
                                                                    placeholder='' required={true}>

                                                    </VisaInputField>
                                                </div>
                                                <DropdownAll mxwidth='321px' mfont='10px' mxfont='14px' mwidth='237px'
                                                             localStorageCountry='EmploymentAddressCountry'
                                                             localStoragezip='employmentaddressZipCode'
                                                             localStorageCity='EmploymentAddressCity'
                                                             localStorageState='EmploymentAddressState' Country_='Country'
                                                             State_='State/Province' City_='City'/>
                                                <div className="visaFirstLast">
                                                    <VisaInputField localStorageName="EmploymentAddress"
                                                                    labelName="Employment Address" star='*'
                                                                    type='text'
                                                                    placeholder='' required={true}>

                                                    </VisaInputField>
                                                    <VisaInputField localStorageName="NameOfThePersonPayingForTheTripAtEmployment"
                                                                    labelName="Name of the person paying for the trip(if any)"
                                                                    star='*' type='text'
                                                                    placeholder='' required={true}>
                                                    </VisaInputField>
                                                </div>
                                                <div className="visaFirstLast">
                                                    <VisaDoubleInputField localStorageName='officePhoneNumber'
                                                                          localTitleStorage='officePhoneNumberCode'
                                                                          labelName='Office/Work Contact Number' star='*'
                                                                          firstPlaceHolder='+234'
                                                                          type='tel'
                                                                          placeholder='Phone Number' value_i='+234'>
                                                    </VisaDoubleInputField>
                                                    <VisaInputField localStorageName="DescribeYourDuties"
                                                                    labelName="Describe your Duties" star='*'
                                                                    type='text'
                                                                    placeholder='' required={true}>
                                                    </VisaInputField>
                                                </div>
                                            </>
                                        }


                                    </div>
                                    <div className='visa_casing'>
                                        <button className='visa_next' type='submit'>
                                            Next page<span><img
                                            src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                                        </button>
                                        <button className='visa_next visaCent' id='timiButton' onClick={handlePrevious}>
                                            <img id='visaPrev'
                                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Previous page</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='visaRight'>
                                    {screenWidth > 760 ? (
                                        <VisaSidder name='VisaType' required={false} className='visaSidder'/>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                    )}
                    {step === 8 && (
                        <div>
                            <div>
                                {showTerms &&
                                    <div>
                                        <div className='terms'>
                                            Terms and Conditions
                                            <div className='group'>
                                                These Terms & Conditions govern visa application and other related services
                                                provided by Wakanow for their clients.
                                                <Terms number='1.'
                                                       text='Jaromtravels acts as an agent on behalf of their clients in the submission of applications for visa and ancillary services to the appropriate institutions and/or immigration authorities. '/>
                                                <Terms number='2.'
                                                       text='Jaromtravels shall not be held responsible for costs (directly or consequential) incurred due to any delay arising from incomplete visa application form, inaccurate/false information or documentation provided, or as a result of circumstances outside Jaromtravel’s control.'/>
                                                <Terms number='3.'
                                                       text='Jaromtravels reserves the right to return any passport without the requested visa if the passport or the documents provided do not comply with the requirements or if there is insufficient time to process the visa. '/>
                                                <Terms number='4.'
                                                       text='Jaromtravels bears no responsibility for costs incurred by an applicant or any secondary applicants while waiting for a visa to be finalised by the immigration authorities. These costs may include but are not limited to cost of flights, rent of accommodation, and loss of earnings or any other costs.'/>
                                                <Terms number='5.'
                                                       text='Jaromtravels will not be held responsible should the immigration authorities or any other third party change their rules, regulations or processing requirements if that change has a direct effect on the timeline for delivery. However, Wakanow will use its best endeavours to minimise any delay or inconvenience caused in this respect.'/>
                                                <Terms number='6.'
                                                       text='Jaromtravels is a private company and do not have authority to grant a visa of any kind. We cannot guarantee a positive result on a visa application. The final decision on all applications rests with the relevant organization responsible for issuing that result.'/>
                                                <Terms number='7.'
                                                       text='Jaromtravels cannot influence any decision made by an immigration authority; any delay by an immigration authority in the issuing of a visa; or a decision to refuse to grant a visa.'/>
                                                <Terms number='8.'
                                                       text="It is the client's responsibility to provide Jaromtravels with all required information and documentation concerning the application. However, Jaromtravels may assist, where necessary, to provide documentation subject to clients request."/>
                                                <Terms number='9.'
                                                       text='Jaromtravels cannot lodge a visa application until it has complete documentation and any subsequent documentation requested by Jaromtravels from the applicant.'/>
                                                <Terms number='10.'
                                                       text='Jaromtravels will use and rely on information provided by the clients in the provision of services to them. Jaromtravels will not independently verify or assume responsibility for the accuracy or completeness of such information.'/>
                                                <Terms number='11.'
                                                       text='Jaromtravels shall not be held responsible for any changes in immigration policy which affect the eligibility of a visa application at any stage while a visa application is being processed by the Immigration Authorities.'/>
                                                <Terms number='12.'
                                                       text='Jaromtravels shall not be responsible to provide the relevant medical forms/vaccination cards to a client, should health examinations be required. Hence Jaromtravels has no part in the process of carrying out these medicals or providing these medical results to the Immigration Authorities for further processing.'/>
                                                <Terms number='13.'
                                                       text='Jaromtravels will not proceed with visa application services until payment has been made.'/>
                                                <Terms number='14.'
                                                       text='Our prices may need to be changed without prior notice as our prices are governed by exchange rates and changes in immigration costs.'/>
                                                <Terms number='15'
                                                       text='As soon as payment is received, the client is deemed to have engaged the service of Jaromtravels. Once the service has been provided to the client it cannot be returned. Where visa was denied, visa processing fees are non-refundable.'/>
                                                <Terms number='16.'
                                                       text='If, after requesting for visa application with Jaromtravels you wish to withdraw the visa application and that application has not been submitted to the Immigration Authorities, 10% of the amount paid shall be deducted as assessment and/or administration fees.'/>
                                                <Terms number='17.'
                                                       text='Where an applicant fails to honour a scheduled appointment at the embassy, Jaromtravels shall charge N5,000 (Five thousand naira) only as penalty and to secure subsequent appointment at the appropriate embassy.'/>
                                                <Terms number='18.'
                                                       text='In the event of loss or damage to any passport or documents received, Jaromtravels liability will be limited to the actual value of the passport and documents up to a maximum of N10,000 (Ten Thousand Naira) per applicant. Jaromtravels accepts no liability for consequential loss.'/>
                                                <Terms number='19.'
                                                       text='Jaromtravels reserves the right not to provide any service without the client agreeing to our terms and conditions as set out herein.'/>
                                                <Terms number='20.'
                                                       text='Jaromtravels is entitled to include additional Terms & Conditions should the need arise. The request for lodgement of an application to Jaromtravels by web, post, phone, email, constitutes agreement to these Terms & Conditions, including amendments thereto, by the customer.'/>
                                                <Terms number='21.'
                                                       text='Jaromtravels will not be held responsible for any loss or damage caused by an embassy/consulate issuing an incorrect visa or incorrect details on a visa as this is out of our control.'/>
                                                <Terms number='22.'
                                                       text='Jaromtravels shall not be held responsible under any circumstance, to compensate for any injury, accident, loss, damage or delay (whether directly or consequential), of any passports or travel documents by any third party courier/delivery company.'/>
                                            </div>
                                            <button className='terms-button' onClick={handleSecondDivClick}>
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                {showStep &&
                                    <div>
                                        <div className='visa_progressBar'>
                                            <div className='visa_progress' style={{width: '100%'}}></div>
                                            <div className='visa_percent'>
                                                100% completed
                                            </div>
                                        </div>
                                        <div className="visaDivider">
                                            <div className='visaLeft'>
                                                <div className="visa_head visaSidder">Visa</div>
                                                <div className="visa_information">
                                                    <div className='heading'>
                                                        Applicants Information <span className='ssl'><img
                                                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685931761/div_wljwjn.svg'/></span>
                                                    </div>
                                                    <div className='more-info'>
                                                        Academic Information<br/>
                                                    </div>
                                                </div>
                                                <div className='visa_details'>
                                                    <RadioInput onClick={radioUpdate___}
                                                                text="Did you attend any educational institution at a secondary  level or above"
                                                                localStorageName="didYouAttendAnyEducationalInstitutionAtASecondaryLevelOrAbove"/>
                                                    {areOthersTravellingWithYou___ !== 'No' && (
                                                        <div>
                                                            <div className='visaFirstLast'>
                                                                <VisaInputField localStorageName="NameOfInstitution"
                                                                                labelName="Name of Institution" star='*'
                                                                                type='text'
                                                                                placeholder='' required={true}>
                                                                </VisaInputField>
                                                                <VisaInputField localStorageName="CourseOfStudy"
                                                                                labelName="Course of study" star='*'
                                                                                type='text'
                                                                                placeholder='' required={true}>

                                                                </VisaInputField>
                                                            </div>
                                                            <div className='visaFirstLast'>
                                                                <VisaInputField localStorageName="academicState"
                                                                                labelName="State/Province" star='*'
                                                                                type='text'
                                                                                placeholder='' required={true}>

                                                                </VisaInputField>
                                                                <VisaInputField localStorageName="academicCity"
                                                                                labelName="City"
                                                                                star='*' type='text'
                                                                                placeholder='' required={true}>

                                                                </VisaInputField>
                                                            </div>


                                                            <VisaInputField id='visa-Input'
                                                                            localStorageName="academicAddress"
                                                                            labelName="Address" star='*' type='text'
                                                                            placeholder='' required={true}>
                                                            </VisaInputField>
                                                            <div className='visaFirstLast'>
                                                                <VisaInputField localStorageName="academicDAteAttendedFrom"
                                                                                labelName="Date attended from"
                                                                                star='*' type='date'
                                                                                placeholder='' required={true}>
                                                                </VisaInputField>
                                                                <VisaInputField localStorageName="academicDateFinished"
                                                                                labelName="Date finished" star='*'
                                                                                type='date'
                                                                                placeholder='' required={true}>
                                                                </VisaInputField>
                                                            </div>

                                                        </div>
                                                    )}
                                                </div>

                                                <div className="visa_information">
                                                    <div className='more-info'>
                                                        Security and Background Information<br/>
                                                    </div>
                                                </div>
                                                <div className='visa_details'>
                                                    <RadioInput onClick={radioUpdate____}
                                                                text="Have traveled to any country in the last five(5) years?"
                                                                localStorageName="haveTraveledToAnyCountryInTheLastFive"/>
                                                    {areOthersTravellingWithYou____ !== 'No' && (
                                                        <div>
                                                            <VisaInputField localStorageName="NameOfCountry"
                                                                            labelName="Name of Country"
                                                                            star='*'
                                                                            type='text'
                                                                            placeholder='' required={true}>
                                                            </VisaInputField>
                                                        </div>
                                                    )}

                                                    <RadioInput onClick={radioUpdate_____}
                                                                text="Do you belong to any Tribe?"
                                                                localStorageName="doYouBelongToAnyTribe"/>
                                                    {areOthersTravellingWithYou_____ !== 'No' && (
                                                        <div>
                                                            <VisaInputField localStorageName="TheNameOfClanTribe"
                                                                            labelName="The name of Clan's Tribe"
                                                                            star='*' type='text'
                                                                            placeholder='' required={true}>
                                                            </VisaInputField>
                                                        </div>
                                                    )}

                                                    <VisaInputField
                                                        localStorageName="ListOfLanguagesYouSpeakIncludingEnglish"
                                                        labelName="List of languages you speak including English"
                                                        star='*'
                                                        type='textarea'
                                                        placeholder='' required={true} id='visa_area'>
                                                    </VisaInputField>
                                                    <label className='visa_lcheck'>
                                                        <input type="checkbox" className="custom-checkbox"
                                                               onChange={handleCheckboxChange} required={true}/>
                                                        <span className="checkmark" id='visa_checkmark'></span>
                                                        <span className='logged'
                                                              id='visa_tick'>I understand and agree with the<br/>
                                            <span onClick={handleFirstDivClick} className='visa_ttick'>&nbsp;Terms and Conditions.</span>
                                                            {/*{PopUp && <div>Submitted</div>}*/}


                                        </span>
                                                    </label>
                                                </div>
                                                <div className='visa_casing'>

                                                    <button className='visa_next visaCent'
                                                            type='submit'
                                                            style={{background : !checkboxChecked ? 'grey': '' }}
                                                            disabled={!checkboxChecked}>
                                                        {PopUp ? PopUp : 'Submit Form'}
                                                    </button>
                                                    <button className='visa_next visaCent' id='timiButton'
                                                            onClick={handlePrevious}>
                                                        <img id='visaPrev'
                                                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Previous page</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='visaRight'>
                                                {screenWidth > 760 ? (
                                                    <VisaSidder name='VisaType' required={false} className='visaSidder'/>
                                                ) : null}
                                            </div>

                                        </div>

                                    </div>
                                }
                            </div>

                        </div>
                    )}
                </form>
                {/*<MyFooter/>*/}
            </div>
        </>
    );
}