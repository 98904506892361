import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../Support/SupportPage.css';


export default function SupportMainSkeleton (){
    return(
        <div style={{padding: 'none', background: 'transparent', margin: '20px'}} >
            {<Skeleton height={50} width={150}/>}
        </div>
    )
}