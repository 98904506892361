export default function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if the cookie starts with the name we're looking for
        if (cookie.startsWith(`${name}=`)) {
            // Return everything after the '=' sign
            return decodeURIComponent(cookie.substring(name.length + 1));
        }
    }
    return null; // If cookie is not found
}


