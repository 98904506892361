import React, {useState} from "react";
import './Form.css'
import VisaInputField from "../Visa/visaInput";
import TitleDropdown from "../Visa/titleDropdown";
import axiosWithAuth from "../Axios/axiosWithAuth";
import envVariables from "../../dev";
import {toast} from "react-toastify";

const JetHireForm = (props) => {
    const[comfort, setComfort] = useState('');
    const apikey = envVariables.APIKEY;
    const [PopUp, setPopUp] = useState('');
    const flightTypes = [
        "Non-Stop (e.g., New York (JFK) to Los Angeles (LAX))",
        "Direct (e.g., New York (JFK) to San Francisco (SFO) with a stop in Denver (DEN))",
        "One-Way (e.g., New York (JFK) to Miami (MIA))",
        "Round-Trip (e.g., New York (JFK) to Los Angeles (LAX) and back)",
        "Multi-City (e.g., New York (JFK) to Chicago (ORD), then to Denver (DEN), and finally to San Francisco (SFO))",
        "Charter (e.g., Private charter from New York (JFK) to Aspen (ASP))",
        "Connecting (e.g., New York (JFK) to Miami (MIA) with a connection in Atlanta (ATL))",
        "Red-Eye (e.g., Los Angeles (LAX) to New York (JFK) departing at 11:00 PM and arriving at 7:00 AM)"
    ];

    const preferredAircraft = [
        "Boeing 737 (e.g., Boeing 737-800, Boeing 737 MAX 8)",
        "Airbus A320 (e.g., Airbus A320neo, Airbus A320ceo)",
        "Boeing 777 (e.g., Boeing 777-200, Boeing 777-300ER)",
        "Airbus A350 (e.g., Airbus A350-900, Airbus A350-1000)",
        "Boeing 787 (e.g., Boeing 787-8, Boeing 787-9)",
        "Airbus A380 (e.g., Airbus A380-800)",
        "Embraer E-Jet (e.g., Embraer E190, Embraer E195)",
        "Bombardier CRJ (e.g., CRJ-700, CRJ-900)",
        "Gulfstream G650 (e.g., Gulfstream G650ER)",
        "Cessna Citation (e.g., Citation X, Citation CJ4)"
    ];

    const ComfortList = [
        "Business",
        "Leisure",
    ];




    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {


            const JetHire ={
                'Surname': localStorage.getItem('Surname_JH')  ?  localStorage.getItem('Surname_JH'): '',
                'Full_Name': localStorage.getItem('FullName_JH')  ?  localStorage.getItem('FullName_JH'): '',
                'Email': localStorage.getItem('Email_JH')  ?  localStorage.getItem('Email_JH'): '',
                'Phone_Number': localStorage.getItem('Phone_number_JH')  ?  localStorage.getItem('Phone_number_JH'): '',
                'Company': localStorage.getItem('Company_JH')  ?  localStorage.getItem('Company_JH'): '',
                'Address': localStorage.getItem('Address_JH')  ?  localStorage.getItem('Address_JH'): '',
                'Country': localStorage.getItem('Country_JH')  ?  localStorage.getItem('Country_JH'): '',
                'Departing_From': localStorage.getItem('Departing_From_JH')  ?  localStorage.getItem('Departing_From_JH'): '',
                'Going_To': localStorage.getItem('Going_To_JH')  ?  localStorage.getItem('Going_To_JH'): '',
                'Depature_Date': localStorage.getItem('Depature_Date_JH')  ?  localStorage.getItem('Depature_Date_JH'): '',
                'Depature_Time': localStorage.getItem('Depature_Time_JH')  ?  localStorage.getItem('Depature_Time_JH'): '',
                'Return_Date': localStorage.getItem('Return_Date_JH')  ?  localStorage.getItem('Return_Date_JH'): '',
                'Return_Time': localStorage.getItem('Return_Time_JH')  ?  localStorage.getItem('Return_Time_JH'): '',
                'Number_of_Passangers': localStorage.getItem('number_of_passangers_JH')  ?  localStorage.getItem('number_of_passangers_JH'): '',
                'Flight_Type': localStorage.getItem('FlightType_JH')  ?  localStorage.getItem('FlightType_JH'): '',
                'Preferred_Aircraft': localStorage.getItem('preferredAircraft_JH')  ?  localStorage.getItem('preferredAircraft_JH'): '',
                'Comfort': localStorage.getItem('comfort_JH')  ?  localStorage.getItem('comfort_JH'): '',
            }


            const form_data = {
                'JetHire': JetHire,
            };

            setPopUp('Loading.....')

            try {
                const formData = new FormData();
                formData.append('jarom', 'jaromtravels');
                formData.append('jarom_subject', 'Permanent Residency in Canada(Our Services)');
                formData.append('jarom_message', JSON.stringify(form_data));


                const response = await axiosWithAuth(apikey).post('/payment/jsondata/',  formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.status === 201) {
                    setPopUp('submitted');
                    toast.success("Form submitted successfully");
                    props.ReceiveJetHireForm(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setPopUp('Failed');
                toast.error("Failed to submit form");

            }

        }


    };

    const radioUpdate___ = () => {
        const storedValue = localStorage.getItem('comfort_CH');
        if (storedValue) {
            setComfort(storedValue);
        }
    };

    return (
        <form ref={props.close}  onSubmit={(e)=>handleSubmit(e)} className='blurred-div'>
            <div className="blueHeader">
                Personal Information
            </div>
            <div className="IELTS1">

                <VisaInputField labelName='Surname' star='*' type='text'
                                localStorageName='Surname_JH'
                                placeholder='Surname as on intl. passport' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Full Name' star='*' type='text'
                                localStorageName='FullName_JH'
                                placeholder='Full name as on intl. passport' required={true}>

                </VisaInputField>
                <VisaInputField labelName='Email' star='*' type='email'
                                localStorageName='Email_JH'
                                placeholder='jarom@gmail.com' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Phone Number' star='*' type='number'
                                localStorageName='Phone_number_JH'
                                placeholder='Phone Number' required={true}>

                </VisaInputField>

                <VisaInputField labelName='Company' star='*' type='text'
                                localStorageName='Company_JH'
                                placeholder='Jarom Travels Limited' required={true}>

                </VisaInputField>


                <VisaInputField labelName='Address' star='*' type='text'
                                localStorageName='Address_JH'
                                placeholder='San Francisco Downtown Office' required={true}>

                </VisaInputField>


                <VisaInputField labelName='Country' star='*' type='text'
                                localStorageName='Country_JH'
                                placeholder='Nigeria' required={true}>

                </VisaInputField>

                <div className="IELTSInner2">
                </div>
            </div>

            <div className="blueHeader">
                Private Jet Hire Details
            </div>
            <div className="IELTS1">
                <div className="IELTSInner2">

                    <VisaInputField labelName='Departing From' star='*' type='text'
                                    localStorageName='Departing_From_JH'
                                    placeholder='Los Angeles International Airport (LAX)' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Going To' star='*' type='text'
                                    localStorageName='Going_To_JH'
                                    placeholder='New York John F. Kennedy International Airport (JFK)' required={true}>

                    </VisaInputField>
                </div>

                <div className="IELTSInner2">
                    <VisaInputField labelName='Depature Date' star='*' type='date'
                                    localStorageName='Depature_Date_JH'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Depature Time' star='*' type='text'
                                    localStorageName='Depature_Date_JH'
                                    placeholder='10:00AM (G.M.T)' required={true}>

                    </VisaInputField>
                </div>

                <div className="IELTSInner2">
                    <VisaInputField labelName='Return Date' star='*' type='date'
                                    localStorageName='Return_Date_JH'
                                    placeholder='DD-MM-YY' required={true}>

                    </VisaInputField>

                    <VisaInputField labelName='Return Time' star='*' type='text'
                                    localStorageName='returnTime_JH'
                                    placeholder='4:00PM (G.M.T)' required={true}>

                    </VisaInputField>

                </div>


                <div className="IELTSInner2">

                    <VisaInputField labelName='Number of Passangers' star='*' type='text'
                                    localStorageName='number_of_passangers_JH'
                                    placeholder='2' required={true}>

                    </VisaInputField>

                    <div className='visa_inputField ' id='inputTop'>
                        <label>
                            Business or Leisure<span>*</span>
                        </label>
                        <TitleDropdown name='Flight Type' localStorageName='comfort_JH' mwidth='130px'
                                       mxfont='14px' mxwidth='100%' mfont='10px'
                                       options={ComfortList} title="Select"
                                       className='visa_input'/>

                    </div>

                </div>

                <div className='visa_inputField ' id='inputTop'>
                    <label>
                        Preferred Aircraft<span>*</span>
                    </label>
                    <TitleDropdown name='Preferred Aircraft' localStorageName='preferredAircraft_JH' mwidth='130px'
                                   mxfont='14px' mxwidth='100%' mfont='10px'
                                   options={preferredAircraft} title="Select"
                                   className='visa_input'/>

                </div>

                <div className='visa_inputField ' id='inputTop'>
                    <label>
                        Flight Type<span>*</span>
                    </label>
                    <TitleDropdown name='Flight Type' localStorageName='FlightType_JH' mwidth='130px'
                                   mxfont='14px' mxwidth='100%' mfont='10px'
                                   options={flightTypes} title="Select"
                                   className='visa_input'/>

                </div>


            </div>


            <div className="centerBtn">
                <div className='visa_casing'>
                    <button className='visa_next'>
                        {PopUp? PopUp : 'Submit Form'}<span><img
                        src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1683935551/arrow-right_mpoy01.svg'/></span>
                    </button>
                    <button className='visa_next visaCent' id='timiButton' onClick={()=>props.ReceiveJetHireForm(false)}>
                        <img id='visaPrev'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1685921946/arrow-left_spn2ft.svg'/><span>Go back</span>
                    </button>
                </div>
            </div>

        </form>

    );
};

export default JetHireForm;