import React from 'react'

export default function SeatClass({ecoSeatImg, EcoHeadText, TrFa , ecoSubText, listImg, classList, lineColour}){
    return(
        <div className="ecoCont">
            <div className="ecoSeatImg">
                <img src={ecoSeatImg} alt=""/>
            </div>

            <div className="EcoHeadCont">
                <div className="EcoHeadText">{EcoHeadText}</div>
                {TrFa &&<div className="EcoSelect">
                    Selected
                </div>}
            </div>



            <div className="ecoSubText">
                {ecoSubText}
            </div>
            <div className={`${lineColour}`}>

            </div>


            <div className="featuresListsCont">

                <ul className="featuresLists">
                    {classList.map((item, index) =>
                    <li key={index}>
                        <img src={listImg} alt="seat class Image" className="blueDot"/>
                        <div className="listsText">{item}</div>
                    </li> )}
                </ul>
            </div>

        </div>

    )
}