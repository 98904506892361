import React, {useState} from "react";
import Check from "./checkBox";

const MultiCityStop = ({checkedKey,Destination, hadleSubmitF,handleCheckChangeF }) =>{
    const [multicityInput, setMulticityInput] = useState('');
    const [multicityDropDown, setMulticityDropDown] = useState(false);

    const handleMulticityDropDown = () =>{
        if(multicityDropDown){
            setMulticityDropDown(false)
        }else{
            setMulticityDropDown(true)
        }
    }
    const handleMulticityChange = (option) =>{
        setMulticityInput(option)
    }

    return(
        <div className="multicityStop">
            <div className="stopDest">{Destination}</div>
            <div className="inputEstdropdownCont">
                <div className="inputEstdropdown">
                    <input type="text" name='multicityInput' value={multicityInput ? `${multicityInput}` : 'Any'}/>
                    <div className="dropdown" onClick={handleMulticityDropDown}>
                        <img src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1687751320/arrow-down_s79bkv.svg" alt=""/>
                    </div>
                </div>
                {multicityDropDown &&
                    <div className="multicityDropDown">
                        <ul onClick={handleMulticityDropDown}>
                            <li onClick={() => handleMulticityChange('Any')}>Any</li>
                            <li onClick={() => handleMulticityChange('Direct')}>Direct</li>
                            <li onClick={() => handleMulticityChange('Up to 1 stop')}>Up to 1 stop</li>
                            <li onClick={() => handleMulticityChange('Up to 2 stops')}>Up to 2 stops</li>
                        </ul>
                    </div>

                }
            </div>

            <Check storageKey='anyStop' header={multicityInput ? `${multicityInput}` : 'Any'} onCheckChange={handleCheckChangeF}
                   isChecked={checkedKey === 'anyStop'} handleSubmit={hadleSubmitF} />

        </div>
    )
}


export default MultiCityStop;