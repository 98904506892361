import React from 'react';

function InputField(props) {
    return (
        <div className={props.classNameSg}>
            <div className="row">
                <div className="col-1">
                    <div className="ficon">

                        <img
                            className="hun"
                            src={props.iconSrc}
                            alt={props.name}
                        />

                    </div>
                </div>
                <input
                    className={props.className}
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    required={props.required} />
            </div>
        </div>
    );
}

export default InputField;
