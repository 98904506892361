import React, {useEffect, useRef, useState} from 'react';

function VisaInputField({
                            labelName,
                            star,
                            name,
                            id,
                            type,
                            placeholder,
                            onChange,
                            onClick,
                            required,
                            localStorageName,
                            additionalFunction,
                            className,
                            status,
                            idInput

                        }) {
    const [selectedValue, setSelectedValue] = useState('');
    const fileInputRef = useRef(null)
    let newValue;
    const storedName = localStorage.getItem(`${localStorageName}name`);
    useEffect(() => {
        const storedValue = localStorage.getItem(localStorageName);
        if (storedValue) {
            setSelectedValue(storedValue);
        }
    }, [localStorageName]);

    const ageValidationValid = (age, birthday, maxAge) => {
        const birthDate = new Date(birthday);
        const currentDate = new Date();
        const yearsAgo = new Date();
        const yearsBefore = new Date();
        yearsAgo.setFullYear(currentDate.getFullYear() - age);
        yearsBefore.setFullYear(currentDate.getFullYear() - maxAge)
        const isUnderAge = birthDate < yearsAgo && birthDate > yearsBefore;
        return isUnderAge
    }



    const handleChange = (event) => {
        newValue = event.target.value;
        let validAge;
        let validMax;
        validAge = status === 'Adult' ? 18 : status === 'Child' ? 2 : 0;
        validMax = status === 'Adult' ? 200 : status === 'Child' ? 18 : 2;
        if(status){
            if(!ageValidationValid(validAge,newValue,validMax)){
                newValue =null
            }
        }
        localStorage.setItem(localStorageName, newValue);
        if (onChange) {
            onChange(newValue);
        }
        setSelectedValue(newValue);

    };
    localStorage.setItem('dateValidation', selectedValue)
    const handleFileChange = (event) => {
        let file = event.target.files[0];
        additionalFunction(file.size)
        if(file.size> 0.5 * 1024 * 1024){
            fileInputRef.current.value = null;
        }
        else{
            setSelectedValue(file.name);
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileData = e.target.result;
                localStorage.setItem(localStorageName, fileData);
                const fileName = file.name
                localStorage.setItem(`${localStorageName}name`, fileName);
                // localStorage.setItem('uploadedFile', fileData);
            };
            reader.readAsDataURL(file);
            if (onChange) {
                onChange(idInput, file);
            }
        }
    };
    // console.log(selectedValue)

    return (
        <div className="visa_inputField" id="inputTop">
            <label>
                {labelName}
                <span>{star}</span>
            </label><br/>
            {type === 'file' ? (
                <div key={id}>
                    <input
                        className={`visa_input myinput ${className}`}
                        name={name}
                        id={id}
                        ref={fileInputRef}
                        type="file"
                        onChange={handleFileChange}
                        required={selectedValue ? '' : required }
                    /><br/>
                    {selectedValue && <label>{storedName}</label>}
                </div>
            ) : (
                <input
                    className={`visa_input myinput ${className}`}
                    name={name}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    value={localStorage.getItem('dateValidation')}
                    onChange={handleChange}
                    onClick={onClick}
                    required={required}
                />
            )}
        </div>
    );
}

export default VisaInputField;
