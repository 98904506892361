import React from "react";
import AboutMain from "./AboutMain";
import "./Aboutus.css"
import {Helmet} from "react-helmet-async";

export default function AboutUs (){


    return(
     <>
         <Helmet>
             <title>About Us - Jarom Travel Agency</title>
                <meta name="robots" content="index" />
                <meta name="title" content="About Us - Jarom Travel Agency" />
             <meta name="description" content="Learn about Jarom travel agency. Discover our mission, vision, and the team that makes it all possible." />
             <meta name="keywords" content="about us, travel agency, mission, vision, team" />
             <link rel="canonical" href="https://jaromtravels.com/AboutUs" />
             <script type="application/ld+json">
                 {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
             </script>
         </Helmet>
         <div className='forceMiddle'>
             <div>

                 <div className="hero-container1">
                     <div className="hero-text">
                         <h1>About Us</h1>
                         <p>Efficiency is what we are known for...You are our priority</p>
                     </div>
                 </div>






                 <AboutMain/>
             </div>

         </div>
     </>
    );
}
