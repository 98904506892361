import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {City, Country, State} from 'country-state-city';


const DropdownAll = ({
                         Country_,
                         State_,
                         City_,
                         localStorageCountry,
                         localStorageState,
                         localStorageCity,
                         localStoragezip,
    mfont, mwidth, mxfont, mxwidth
                     }) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCode, setPostalCode] = useState('');
    const [postalCodeError, setPostalCodeError] = useState('');

    useEffect(() => {
        const countryOptions = Country.getAllCountries().map((country) => ({
            value: country.isoCode,
            label: country.name,
        }));
        setCountries(countryOptions);
    }, []);

    useEffect(() => {
        if (selectedCountry) {
            const stateOptions = State.getStatesOfCountry(selectedCountry.value).map(
                (state) => ({
                    value: state.isoCode,
                    label: state.name,
                })
            );
            setStates(stateOptions);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedState) {
            const cityOptions = City.getCitiesOfState(
                selectedCountry.value,
                selectedState.value
            ).map((city) => ({
                value: city.name,
                label: city.name,
            }));
            setCities(cityOptions);
        }
    }, [selectedState]);

    useEffect(() => {
        const storedValue = localStorage.getItem(localStorageCountry);
        if (storedValue) {
            setSelectedCountry({ value: storedValue, label: storedValue });
        }
        const storedValue_ = localStorage.getItem(localStorageCity);
        const storedValue__ = localStorage.getItem(localStorageState);
        const storedValue___ = localStorage.getItem(localStoragezip);
        if (storedValue_) {
            setSelectedCity({ value: storedValue_, label: storedValue_ });
        }
        if (storedValue__) {
            setSelectedState({ value: storedValue__, label: storedValue__ });
        }
        if (storedValue___) {
            setPostalCode(storedValue___);
        }
        // console.log('postal',storedValue)

    }, [localStorageCountry, localStorageCity, localStorageState, localStoragezip]);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedState(null);
        setSelectedCity(null);
        localStorage.setItem(localStorageCountry, selectedOption.label);

    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
        localStorage.setItem(localStorageState, selectedOption.label);
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        localStorage.setItem(localStorageCity, selectedOption.value);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '4px',
            borderColor: state.isFocused ? 'blue' : '#DDEAF0',
            boxShadow: 'none',
            borderWidth: '1px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17px',
            '@media (min-width: 760px)': {
                fontSize: `${mfont}`,
                height:'24px',
                width:`${mwidth}`,
            },
            '@media (min-width: 1024px)': {
                fontSize: `${mxfont}`,
                height:'41px',
                width:`${mxwidth}`,
            },
            '&:hover': {
                borderColor: state.isFocused ? 'blue' : '#DDEAF0',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'lightblue' : 'white',
            color: '#6D8494',
            '&:hover': {
                backgroundColor: 'lightblue',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#6D8494', // Change the color value as desired
        }),
    };
    const validatePostalCode = (value) => {
        const postalCodeRegex = /^[0-9]{6}$/; // Updated regex for Nigerian postal code
        return postalCodeRegex.test(value);
    };

    const handlePostalCodeChange = (e) => {
        const { value } = e.target;
        setPostalCode(value);
        if (value && !validatePostalCode(value)) {
            setPostalCodeError('Invalid postal code');
        } else {
            setPostalCodeError('');
        }
        localStorage.setItem(localStoragezip, value);
    };


    return (
        <div>
            <div className='visaFirstLast'>
                <div>
                    <div className="visa_inputField " id="inputTop">
                        <label>
                            {Country_}
                            <span>*</span>
                        </label>
                        <Select
                            className="visa_input_country"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            options={countries}
                            styles={customStyles}
                            placeholder="Country"
                            required={true}
                            name='Country'
                        />
                    </div>

                </div>
                <div>
                    {selectedCountry && (
                        <div className="visa_inputField " id="inputTop">
                            <label>
                                {State_}
                                <span>*</span>
                            </label>
                            <Select
                                value={selectedState}
                                onChange={handleStateChange}
                                options={states}
                                styles={customStyles}
                                placeholder="State"
                                name='State'
                                required={true}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className='visaFirstLast'>
                <div>
                    {selectedState && (
                        <div className="visa_inputField " id="inputTop">
                            <label>
                                {City_}
                                <span>*</span>
                            </label>
                            <Select
                                value={selectedCity}
                                onChange={handleCityChange}
                                options={cities}
                                styles={customStyles}
                                placeholder="City"
                                required={true}
                                name='City'
                            />
                        </div>
                    )}
                </div>
                <div className="visa_inputField " id="inputTop">
                    <label>
                        Postal Zone/Zip Code<span>*</span>
                    </label><br/>
                    <input
                        id='widthOurservices'
                        className="visa_input myinput"
                        type="text"
                        placeholder="Postal Zone/Zip Code"
                        value={postalCode}
                        onChange={handlePostalCodeChange}
                        name='PostalCode'
                        required={true}
                    /><br/>
                    {postalCodeError && <label className="error ">{postalCodeError}</label>}
                </div>
            </div>
        </div>
    );
};

export default DropdownAll;
