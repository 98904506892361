import React, {useState} from "react";

export default function GaranteeUI ({header, price, subText, co, key, name, accumulateDatafromGarantee, value, dataTosave}){


    const [garanteeOption, setGaranteeOption] = useState(null);

    // Handler function to update the selected option

    const currency = localStorage.getItem('Currency');
    let currSign = ''
    let price_ = '';
    if (currency === 'NGN') {
        price_ = Number(price).toLocaleString('en-NG', { maximumFractionDigits: 2 });
        price_ = `₦${price_}`;
    }
    else if (currency === 'USD') {
        price_ = Number(price).toLocaleString('en-US', { maximumFractionDigits: 2 });
        price_ = `$${price_}`;
    }
    else if (currency === 'EUR') {
        price_ = Number(price).toLocaleString('en-GB', { maximumFractionDigits: 2 });
        price_ = `€${price_}`;
    }



    const handleOptionChange = (event) => {
        setGaranteeOption(event.target.value);
        accumulateDatafromGarantee({garanteeName: 'Garantee', price: price_}, key)
        set_to_local({garanteeName: 'Garantee', price: price_},  `FlightGuarantee`)
        set_to_local(co ? 'No Garantee' : {price: dataTosave},  `FlightGuaranteeSaved`)

    };
    const getStyle = (optionName) => {
        // Define your default style
        const defaultStyle = {

        };

        // Define your selected style
        const selectedStyle = {
            borderRadius: '8px',
            border: '2px solid  #054DAB',
            background: '#FAFCFF',
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
        };

        // Return the selected style if the current option matches the selected option, otherwise return the default style
        return garanteeOption === optionName ? selectedStyle : defaultStyle;
    };

    function customStringify(obj) {
        const seen = new WeakSet();

        function replacer(key, value) {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return "[Circular Reference]";
                }
                seen.add(value);
            }
            return value;
        }

        return JSON.stringify(obj, replacer);
    }

    const set_to_local = (dataToStore, key_name) => {
        const data_stringify = customStringify(dataToStore);
        localStorage.setItem(key_name, data_stringify);
    }




    return(
        <>
            <div className='garantFormCont'  key={key}>
                <div className="GarantForm">
                    <label className='garaFormLab'>
                        <input type="radio" required={true}
                               // checked={garanteeOption === name}
                               onChange={handleOptionChange}
                               name={name}
                               value={value}
                               id={key}
                        />

                        <div className="garaTextCont">
                            <div className="HeaderGaratext">
                                {header}

                            </div>
                            <ul className={`Garatext ${ co ? 'coRed' : ''}`}>
                                {subText.map((text, i) => (
                                    <li key={i}>{text}</li>
                                ))}
                            </ul>
                        </div>
                    </label>
                    <div className="grantPrice">
                        {price_}
                    </div>
                </div>
            </div>
        </>
    )
}