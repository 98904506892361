import React, {useEffect, useRef, useState} from "react";

export default function EconSeat(
    {
        state,
        extraRoom,
        seat, premium,
        seatIndex, selectOne,
        selectOneSeat, mainRoute,
        seatMapIndex, passengerData,
        generateSeatingSummary, handle_return, passengerName, passIndex, remove_return
    }
) {

    const [clickSeat, setClickSeat] = useState(false);
    const [hover, setHover] = useState(false);
    const [clicked, setClicked] = useState(null);
    const [checked, setChecked] = useState(false);


    const get_from_local = (key_name) =>{
        const data_stringify = localStorage.getItem(`${key_name}`);
        if(data_stringify){
            const full_response = JSON.parse(data_stringify)
            // console.log(`${key_name},found in local storage`)
            return full_response
        }else{
            // console.log(`${key_name}, not found in local storage`)
        }
    }



    const checkColumn = (x) => {
        return x !== undefined && x !== null ? x : <div>None</div>;
    };



    const seats =  seat ?  checkColumn(seat) : null;


    let localStorageKeys_to_remove1 = [];


    function customStringify(obj) {
        const seen = new WeakSet();

        function replacer(key, value) {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return "[Circular Reference]";
                }
                seen.add(value);
            }
            return value;
        }

        return JSON.stringify(obj, replacer);
    }

    const set_to_local = (dataToStore, key_name) => {
        const data_stringify = customStringify(dataToStore);
        localStorage.setItem(key_name, data_stringify);
        localStorageKeys_to_remove1.push(key_name);
    }


   let seatDic = get_from_local(`seatDictionary${seatMapIndex}`) ? get_from_local(`seatDictionary${seatMapIndex}`) : {};

  const priceeee = seats && seats.price && seats.price.amount ? parseFloat(seats.price.amount) : ''


    const currency = localStorage.getItem('Currency');
    let currSign = ''
    let price_ = '';
    if (currency === 'NGN') {
        price_ = Number(priceeee).toLocaleString('en-NG', { maximumFractionDigits: 2 });
        price_ = `₦${price_}`;
        currSign = '₦'
    }
    else if (currency === 'USD') {
        price_ = Number(priceeee).toLocaleString('en-US', { maximumFractionDigits: 2 });
        price_ = `$${price_}`;
        currSign ='$'
    }
    else if (currency === 'EUR') {
        price_ = Number(priceeee).toLocaleString('en-GB', { maximumFractionDigits: 2 });
        price_ = `€${price_}`;
        currSign='€'
    }



    function handleClick(){
        if (state === 'unavailable') {
            setClickSeat(false);
        }
        else {
            setHover(true)
        }
        // }
    }

    const divRef = useRef(null);
    const divRef2 = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setHover(!hover)
                divRef2.current.click();

            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [hover]);


    function select_func (index, object){
        localStorage.setItem(index, 'myindex')
        const summary1 = generateSeatingSummary(object, index, seatMapIndex);
        const filteredArray = summary1.filter(item => item !== undefined);
        handle_return(filteredArray);
        setClicked((prevIndex) => (prevIndex === index ? null : index))
    }

    function unselect_func(index){
        remove_return(index, seatMapIndex);
        setClicked((prevIndex) => (prevIndex === index ? null : index))
        console.log(clicked)
    }



    const pickSeat = (index) =>{
        const planedta = [];
        const selectedSeatsBySeatmapId = new Map();
        const dynamicSelectedSeats = [];

        function handleSeatClick(seatmapIndex, seats) {
            dynamicSelectedSeats.push({ seatmapIndex, seats });
        }

        // Assuming seatMapIndex and seats are defined somewhere before this point
        handleSeatClick(seatMapIndex, seats);

        dynamicSelectedSeats.forEach(({ seatmapIndex, seats }) => {
            const sm = mainRoute[seatmapIndex];
            const isSelectedRoute = seatmapIndex === seatMapIndex;

            const currentSeats = isSelectedRoute ? seats : [];

            selectedSeatsBySeatmapId.set(seatmapIndex, currentSeats);

            const seatMapdata = {
                passengerIndex: isSelectedRoute ? index : '',
                seatmapid: isSelectedRoute ? seatmapIndex : '',
                cityTo: isSelectedRoute ? sm.cityTo : '',
                cityFrom: isSelectedRoute ? sm.cityFrom : '',
                cityCodeTo: isSelectedRoute ? sm.cityCodeTo : '',
                cityCodeFrom: isSelectedRoute ? sm.cityCodeFrom : '',
                price: isSelectedRoute ? price_ : '',
                intPrice: isSelectedRoute ? parseFloat(price_.replace(/[^0-9.-]/g, '')) : '',
                seat: currentSeats,
            };

            planedta.push(seatMapdata);
        });

        const flattenedData = planedta.reduce((acc, innerArray) => acc.concat(innerArray), []);

        flattenedData.forEach(object => {
            if (clicked === index) {
                unselect_func(index);
                // unselectSeatPass(0)
            } else {
                select_func(index, object);
            }
            set_to_local(object, `seatmapData ${seatMapIndex} ${index}`);
        });

        localStorage.setItem('keytoclear', customStringify(localStorageKeys_to_remove1));

        // const updatedSeat___
            let updatedSeats = get_from_local(`updatedSeat${seatMapIndex}`) ? get_from_local(`updatedSeat${seatMapIndex}`) : [];




        const deepEqual = (a, b) => {
            if (a === b) return true;

            if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) return false;

            const keysA = Object.keys(a);
            const keysB = Object.keys(b);

            if (keysA.length !== keysB.length) return false;

            for (const key of keysA) {
                if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
            }

            return true;
        };

        const findKeyByValue = (obj, searchObj) => {
            return Object.keys(obj).find(index => deepEqual(obj[index], searchObj));
        };


        // Toggle the selected state for the clicked passenger
        const indexInArray = updatedSeats.indexOf(index);
        const keybyval= findKeyByValue(seatDic, seats);
        if(keybyval){
            delete seatDic[parseInt(keybyval, 10)];
             updatedSeats = Object.keys(seatDic).map(index => parseInt(index));
            set_to_local(seatDic, `seatDictionary${seatMapIndex}`)
            localStorage.removeItem(`selectedSeat seatMapIndex ${seatMapIndex} ${keybyval}`);
            set_to_local(updatedSeats, `updatedSeat${seatMapIndex}`)
        }
        if (indexInArray !== -1) {
            // If the index is already in the array, remove it
            if (seatDic.hasOwnProperty(indexInArray)) {
                delete seatDic[indexInArray];
                updatedSeats = Object.keys(seatDic).map(index => parseInt(index));
                localStorage.removeItem(`selectedSeat seatMapIndex ${seatMapIndex} ${index}`);
                set_to_local(updatedSeats, `updatedSeat${seatMapIndex}`);
                unselect_func(indexInArray);
            }
            set_to_local(seatDic, `seatDictionary${seatMapIndex}`);


        } else {
            const getSeatSumLS = get_from_local(`seatDictionary${seatMapIndex}`) ? get_from_local(`seatDictionary${seatMapIndex}`) : {};
            getSeatSumLS[index] = seats;
            updatedSeats.push(index)
            set_to_local(getSeatSumLS, `seatDictionary${seatMapIndex}`);
            set_to_local(updatedSeats, `updatedSeat${seatMapIndex}`)
        }

        set_to_local(seats, `selectedSeat seatMapIndex ${seatMapIndex} ${index}`);
        set_to_local(index, 'myIndex')
        if(findKeyByValue(get_from_local(`seatDictionary${seatMapIndex}`), seats)){
            setChecked(true)
        }else{
            setChecked(false)
        }

        // const nameList = Object.values(get_from_local(`seatDictionary${seatMapIndex}`)).map(item => item.name);
        // selectOne = [...nameList];
        selectOneSeat(seats.name);

    }



    const updatedSeatLS = get_from_local(`updatedSeat${seatMapIndex}`) ? get_from_local(`updatedSeat${seatMapIndex}`) : []



    function closeDag (){
        setHover(false)
    }
    // function handleHover (){
    //     // selectOneSeat(seats.name)
    //     setHover(true)
    // }




    return (
        <>
            <div
                className={`EconBox1 ${(selectOne && selectOne.includes && selectOne.includes(seats.name)) && hover  ? 'makePink2'  : ''}`}
                style={{
                    background:
                        selectOne && selectOne.includes && selectOne.includes(seats.name)
                            ? 'linear-gradient(180deg, #EB568C 0%, #ED5E76 100%)'
                            : state === 'unavailable'
                                ? '#E9E8FC'
                                : premium
                                    ? 'linear-gradient(180deg, #5CD6C0 0%, #22C3A6 100%)'
                                    : '',
                    position: 'relative',
                }}
                onClick={handleClick}
                // onMouseOver={handleHover}

            >
                {selectOne && selectOne.includes && selectOne.includes(seats.name) &&

                    <p>

                    </p>
                }

            </div>
            {hover && (
                <div className='seatClickDis' ref={divRef}
                     onMouseLeave={closeDag}
                >
                    <div className="SeatDataCont">
                        <div className="seatdata">
                            <div className='mainTextSeat'>Assign seat {`${seats.name}`} to</div>
                            <div className='minTextSeat'>{`${price_}`} ({`${seats.seat_class}`})</div>
                        </div>

                        <div className="closeX" ref={divRef2} onClick={closeDag}> <img src="https://res.cloudinary.com/do5wu6ikf/image/upload/v1699982891/starpenzu/iconoir_cancel_dyqpvv.svg" alt=""/></div>
                    </div>

                    <ul style={{padding: 0, listStyle : 'none'}}>

                                    <div>
                                        {passengerName.map((pass, passIndex)=>(
                                            <div key={passIndex}>
                                                <div className="passcnt" key={passIndex}>
                                                    <div className="passcnt"  key={passIndex}>
                                                        <div className="passNames"> {pass.firstName} {` `} {pass.secName}  </div>
                                                        <div  key={passIndex} className={updatedSeatLS.includes(passIndex) ? 'selectSeatII' : "selectSeat" }
                                                              onClick={()=>{pickSeat(passIndex)}}
                                                        >
                                                            {updatedSeatLS.includes(passIndex) ? 'Selected' : 'Select'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='makeLine'></div>
                                            </div>
                                        ))
                                        }
                                    </div>
                    </ul>
                </div>
            )}
        </>
    );
}
