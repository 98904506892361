import React from 'react';
import BlueBack from "../SignUp/BlueBack";
import {Link} from "react-router-dom";

function ResetPassWordConfirmation() {

    return (
        <div>
                <BlueBack id='PasswordEmail' containerClassName="PasswordReset">

                    <Link to="/signup">
                        <img className='cancelButton'
                             src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1678241476/add_rkjnq2.png'
                             alt='cancelButton'/>
                    </Link>
                    <div id='passRight' className='img-con'>
                        <img id="passkey"
                             src="https://res.cloudinary.com/dk80ynx1w/image/upload/v1679567393/Frame_3325_1_otpbkn.svg"/>
                    </div>
                    <div className='firstOne' >
                        Check your Mail
                    </div>
                    <div id='passRight_' className='secOne'>We’ve sent a password recovery <br/>instruction to your email address.</div>
                    <Link to='https://mail.google.com/'>
                        <button type='submit' id="passEmailButton"  className='stepButton'>Open your email app
                        </button>
                    </Link>
                    <div id='emailNote' className='blueBack-fort toper'>Didn’t receive any email? <span><Link to ="/password-reset-email">Resend link.</Link>
                    </span>. </div>
                </BlueBack>
        </div>
    );
}

export default ResetPassWordConfirmation;
