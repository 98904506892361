import React, {useEffect, useState} from "react";
import cardOne from '../../images/cardone.webp';
import cardTwo from '../../images/cardSec.webp';
import cardThree from '../../images/cardThi.webp';
import cardFour from '../../images/cardFor.webp';
import cardFive from '../../images/cardFi.webp';
import cardSix from '../../images/cardSix.webp';
import "../boostrap.css";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"
import BoxTab from "./boxTab";
import Substitute from "./substitute";
import {Link} from "react-router-dom";
import axios from "axios";
import {calculateDay, convertTime, getAirlineImageUrl} from "../FlightSearch/search";
import envVariables from "../../dev";
import {Helmet} from "react-helmet-async";
import LazyLoad from 'react-lazyload';
import Skeleton from "react-loading-skeleton";


export default function Home({targ}) {
    const [ticketInfo, setticketInfo] = useState(['LOS','Lagos']);
    const [ticketInfoFiltered, setticketInfoFiltered] = useState(['LOS','Lagos']);
    const [displayedTickets, setDisplayedTickets] = useState(3);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);

    const today = new Date();
    let thirtyDaysFromToday = new Date(today);
    thirtyDaysFromToday.setDate(today.getDate() + 30);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const day_ = String(thirtyDaysFromToday.getDate()).padStart(2, '0');
    const month_ = String(thirtyDaysFromToday.getMonth() + 1).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedDate_ = `${year}-${month_}-${day_}`;
    let uniqueAirlines;
    let params ={
        fly_from: 'LOS' ,
        fly_to: 'ABV',
        date_from: formattedDate,
        date_to: formattedDate_,
        adults: 1,
        children: 0,
        infants: 0,
        sort: 'price',
        curr: 'NGN',
        adult_hold_bag: 0,
        adult_hand_bag: 0,
        child_hold_bag: '',
        child_hand_bag: '',
        stopover_from: '2:00',
        stopover_to: '25:00',
    }
    const getTopThreeCheapFlights =()=>{
        setLoading2(true);
        axios
            .get(`https://api.tequila.kiwi.com/search`, {
                params: params,
                headers: {
                    "accept": "application/json",
                    "apikey": envVariables.APIKEY_RETURN_ONEWAY,
                }
            })

            .then((response) => {
                setLoading2(false);
                const airlines = response.data.data.reduce((acc, curr) => acc.concat(curr.airlines), []);
                uniqueAirlines = [...new Set(airlines)];
                const ticketData = response.data.data.map((data) => {
                    const departureTime = convertTime(data.dTime);
                    const arrivalTime = convertTime(data.aTime);
                    const day = calculateDay(data.dTimeUTC);
                    const day2 = calculateDay(data.aTimeUTC);
                    const route = data.route.length - 1;
                    const airlines = data.airlines;
                    const airlineImages = airlines.map((airline) => getAirlineImageUrl(airline));
                    const bookingToken = data.booking_token;

                    return {
                        fromCode: data.flyFrom,
                        toCode: data.flyTo,
                        departure: departureTime,
                        arrival: arrivalTime,
                        id: data.id,
                        price: data.price,
                        duration: data.fly_duration,
                        day: day,
                        day2: day2,
                        route: route,
                        cityFrom: data.cityFrom,
                        cityTo: data.cityTo,
                        airLinesLogo: airlineImages,
                        moreInfo: data.route,
                        airLines: data.airlines,
                        bookingToken: bookingToken,
                    };
                });
                setticketInfo(ticketData);
                setticketInfoFiltered(ticketData)
                // console.log(ticketInfoFiltered)
                // if (Array.isArray(ticketData) && ticketData.length === 0) {
                //     setairNames([]);
                // } else {
                //     if (uniqueAirlines.length !== 0) {
                //         setairNames(getNamesFromIds());
                //     }
                // }
            })
            .catch((error) => {
                // setLoading2(false);
                console.error("Error fetching countries:", error);
            });
    }
    useEffect(() => {
        getTopThreeCheapFlights()
    }, []);
    function Card(props) {
        return (
            <div className={props.class}>
                <LazyLoadImage effect="blur" className='all_cards' src={props.src}/>
                <div className='card_text'>{props.text}<br/>{props.mytext}</div>
            </div>
        )
    }
    //
    // function TrackWhatsapp() {
    //     trackWhatsapp();
    // }

    // function TrackForm() {
    //     trackForm();
    // }

    function handlesub() {
        const firstButton = document.getElementById('firstButton');
        const secButton = document.getElementById('secButton');

        firstButton.addEventListener('click', () => {
            firstButton.classList.add('clicked');
            secButton.classList.remove('clicked');
        });

        secButton.addEventListener('click', () => {
            secButton.classList.add('clicked');
            firstButton.classList.remove('clicked');
        });
    }

    useEffect(()=>{
        handlesub();
    }, [])



    const [isFlight, setIsFlight] = useState(false);
    const [isVisa, setIsVisa] = useState(true);




    const handleSwitchClick = () => {
        setIsFlight(false);
        // setIsVisa(false); when you want to bring back the functionality of the visa button
        setIsVisa(true);
    };
    const handleUnSwitchClick = () => {

        setIsVisa(true);
        setIsFlight(false);
    };


    useEffect(() => {
        // Use setTimeout to change the message after 2 seconds
        const timer = setTimeout(() => {
            setLoading(false);
        }, 5000);

        // Cleanup the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);

    const imageUrls = [
        'ticketTab',
        'ticketTab2',
        'ticketTab3',
    ];
    const scrollToDiv = () => {
        const targetDiv = document.getElementById('targetDiv');

        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [loading3, setLoading3] = useState(true);
    useEffect(() => {
        // Use setTimeout to change the message after 2 seconds
        const timer = setTimeout(() => {
            setLoading3(false);
        }, 4000);

        // Cleanup the timer to avoid memory leaks
        return () => clearTimeout(timer);
    }, []);








    return (
       <>
           <Helmet>
               <title>Home - Jarom Travel Agency</title>
               <meta name="robots" content="index" />
               <meta name="description" content="Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages." />
               <meta name="keywords" content="travel, flights,  vacation, packages, Flight Tickets, Visa Processing, International Exams" />
               <link rel="canonical" href="https://jaromtravels.com/" />
               <script type="application/ld+json">
                   {`
                      {
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "Jarom Travel Agency",
                        "url": "https://jaromtravels.com",
                        "description": "Exploring The World in Comfort & Style. We offer the best deals on  Flight Tickets, Visa Processing, International Exams and vacation packages."
                      }
                    `}
               </script>
           </Helmet>
           <div>
               <div >

                   {
                       loading3 ?
                            <Skeleton height={700} />
                            :
                           <div className={`firstHome`}>
                               <div className='killer'>
                                   <h1 className='first_text'>
                                       Exploring The World  in Comfort & Style.
                                   </h1>
                                   <h2 className="subHeader">
                                       Flight Tickets, Visa Processing,<br/>International Exams
                                   </h2>
                                   <h2 className='subText'>Book your Airline Tickets at the comfort of your home<br/> and get the best
                                       experience with Jaromtravels.<br/>
                                       Get quick updates on coming flight
                                   </h2>
                                   <Link to='/ourservices'  className="link1" >
                                       <div className='ma'>
                                           <button >
                                               Explore →
                                           </button>
                                       </div>
                                   </Link>
                               </div>
                               {/*<div className="cook">*/}
                               {/*    <CookiesUI cookiesState={cookiesState} isVisible={isVisible} handleClose={handleClose} />*/}
                               {/*</div>*/}
                           </div>
                   }





                   <div  id="targetDiv" ref={targ}>
                       <br/>
                       <br/>
                       <br/>

                       <div className='topBdiv'>
                           <button style={{display: 'none'}} id='firstButton' onClick={handleSwitchClick} className='first clicked'><span className='flight' ></span>Book Flight</button>
                           {/*<button id='secButton' onClick={handleUnSwitchClick} className='sec'><span className='visa'></span>Visa</button>*/}
                           <button id='secButton' onClick={handleUnSwitchClick} className='sec'><span className='visa'></span>Visa</button>

                       </div>
                       <Substitute isFlight={isFlight} isVisa={isVisa} loading={loading}/>
                   </div>
                       <div className="message">
                   {/*<div style={isVisible ? setDisplay : null}>*/}

                       We Provide Best<br/>
                       <span>We are committed to giving a quality services for you. As we deliver on time and give you a comfortable, affordable, yet high quality service that suits you best. </span>
                   </div>
                   <div className="boxTab">
                       <>
                           <BoxTab image="https://res.cloudinary.com/dk80ynx1w/image/upload/v1680825426/mdi_airplane-location_nmiy9h.svg" text="Select Destination" subText="Choose your desired place of destination" loading={loading}>

                           </BoxTab>
                           <BoxTab image="https://res.cloudinary.com/dk80ynx1w/image/upload/v1680825426/material-symbols_airplane-ticket-rounded_yhoxdm.svg" loading={loading} text="Book a Trip" subText="Book a ticket to get to your desire destination">

                           </BoxTab>
                           <BoxTab image="https://res.cloudinary.com/dk80ynx1w/image/upload/v1680825425/fluent-emoji-high-contrast_luggage_t6raba.svg"  loading={loading} text="Select Destination" subText="Take your flight on the selected date and enjoy ">

                           </BoxTab>
                       </>

                   </div>
                   <div className='sec'>
                       <div className='service'>Our Services</div>
                       <div className='row wid ' id='annoying'>
                           <div className='col-6 '>
                               <Card class='myCard' text='IELTS Training & ' mytext='Registration' src={cardOne}/>
                               <Card class='myCard' text='Touring  ' mytext='Package ' src={cardThree}/>
                               <Card class='myCard' text='Work While ' mytext='Studying ' src={cardFive}/>
                           </div>
                           <div className='col-6 he_o'>
                               <Card class='myCard' text='Relocation ' mytext='Service ' src={cardTwo}/>
                               <Card class='myCard' text='Visa ' mytext='Processing ' src={cardFour}/>
                               <Card class='myCard' text='Birth Service  ' mytext='Abroad' src={cardSix}/>
                           </div>
                       </div>
                       <div className='row wid' id='annoying_'>
                           <div className='col-4 '>
                               <Card class='myCard' text='IELTS Training & ' mytext='Registration' src={cardOne}/>
                               <Card class='myCard' text='Touring  ' mytext='Package ' src={cardThree}/>
                           </div>
                           <div className='col-4 '>
                               <Card class='myCard' text='Relocation ' mytext='Service ' src={cardTwo}/>
                               <Card class='myCard' text='Visa ' mytext='Processing ' src={cardFour}/>

                           </div>
                           <div className='col-4'>
                               <Card class='myCard' text='Work While ' mytext='Studying ' src={cardFive}/>
                               <Card class='myCard' text='Birth Service  ' mytext='Abroad' src={cardSix}/>
                           </div>
                       </div>
                   </div>
                   <div className="message " id="modify">Today’s Hot Deal<br/>
                       <span>
                    Check out exclusive offer for the day made just for you
                    </span>
                   </div>
                   {/*/!*<div className="ticketTabGrp">*!/*/}
                   {/*/!*    {ticketInfoFiltered.slice(0, displayedTickets).map((ticket, index) => (*!/*/}
                   {/*/!*        <div key={ticket.id}>*!/*/}
                   {/*/!*            <TicketTab*!/*/}
                   {/*/!*                check={loading2}*!/*/}
                   {/*/!*                key={ticket.id}*!/*/}
                   {/*/!*                className="ticketTab"*!/*/}
                   {/*//                 id= {imageUrls[index % imageUrls.length]}*/}
                   {/*/!*                location="Lagos to Abuja"*!/*/}
                   {/*/!*                subText={ticket.price}*!/*/}
                   {/*//                 dates={`${ticket.day} - ${ticket.day2}`}*/}
                   {/*//                 image= {ticket.airLinesLogo}*/}
                   {/*/!*                route={ticket.moreInfo}*!/*/}
                   {/*/!*                duration={ticket.duration}*!/*/}
                   {/*/!*                token ={ticket.bookingToken}*!/*/}
                   {/*/!*                departure={ticket.departure}*!/*/}
                   {/*/!*                arrival={ticket.arrival}*!/*/}
                   {/*/!*                // fromCountry={fromCountry[1]}*!/*/}
                   {/*/!*                // toCountry={toCountry[1]}*!/*/}
                   {/*/!*                fromCode={ticket.fromCode}*!/*/}
                   {/*/!*                toCode={ticket.toCode}*!/*/}
                   {/*/!*                stops={ticket.route}*!/*/}
                   {/*//                 day2={ticket.day2}*/}
                   {/*//                 day={ticket.day}*/}
                   {/*//             >*/}
                   {/*//*/}
                   {/*/!*            </TicketTab>*!/*/}
                   {/*/!*        </div>*!/*/}
                   {/*/!*    ))}*!/*/}


                   {/*</div>*/}

                   <button onClick={scrollToDiv} className="deals">See more Deals</button>
                   <div className="message " id="modify">Our partners<br/>
                       <span>
                    Governments, Civil society organizations,<br/> Businesses, Academia and  more
                     </span>
                   </div>
                   <div className="airlineImg"></div>
               </div>
           </div>

       </>
    );
}