import React, {useEffect, useState} from "react";
import {convertTime} from "./search";
import axios from "axios";
import data from "./data";
import envVariables from "../../dev";
import './search.css'


function MoreInfo  ({duration,route, hideinfo , style2, style, key, departure}){

    // const [showMoreInfo, setshowMoreInfo] = useState(false);
    // const divRef = useRef(null);
    // useEffect(() => {
    //     const handleOutsideClick = (event) => {
    //         if (divRef.current && !divRef.current.contains(event.target)) {
    //             setshowMoreInfo(!showMoreInfo);
    //
    //         }
    //     };
    //
    //     document.addEventListener('mousedown', handleOutsideClick);
    //     return () => {
    //         document.removeEventListener('mousedown', handleOutsideClick);
    //     };
    // }, [showMoreInfo]);
    function convertTimeSpace(timestamp) {
        const minutes = Math.floor(timestamp / 60); // Get the total minutes
        const hours = Math.floor(minutes / 60); // Get the total hours
        const remainingMinutes = minutes % 60; // Get the remaining minutes after calculating hours

        const formattedTime = `${hours}h ${remainingMinutes}m`; // Format the time as "3h 4m"
        return formattedTime;
    }
    function convertDaySpace(timestamp) {
        const minutes = Math.floor(timestamp / 60); // Get the total minutes
        const hours = Math.floor(minutes / 60); // Get the total hours
        const days = Math.floor(hours / 24); // Get the total days

        return days;
    }

    const calculateDayFormat = (timestamp) => {
        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const date = new Date(timestamp * 1000);
        const dayOfWeek = weekdays[date.getUTCDay()];
        const dayOfMonth = date.getUTCDate();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');

        const formattedDay = `${dayOfWeek}, ${dayOfMonth}.${month}`;
        return formattedDay;
    };
    const getAPIKEY =()=>{
        const trip = localStorage.getItem('Trip')||'One Way'
        let apikey
        if (trip === 'One Way'){
            apikey = envVariables.APIKEY_RETURN_ONEWAY;
        }
        else{
            apikey = envVariables.APIKEY_MULTICITY;
        }
        return apikey
    }
    const fetchCountry = async (code) => {
        try {
            const response = await axios.get('https://api.tequila.kiwi.com/locations/query', {
                params: {
                    term: code,
                    locale: 'en-US',
                    location_types: 'airport',
                    limit: 1,
                    active_only: true,
                },
                headers: {
                    accept: 'application/json',
                    apikey: getAPIKEY(),
                },
            });
            return response.data.locations[0].name;
        } catch (error) {
            console.error('Error fetching countries:', error);
            return '';
        }
    };

    useEffect(() => {
        const fetchUpdatedRoute = async () => {
            const updatedRoute = await Promise.all(
                route.map(async (dictionary, index) => {
                    const { cityCodeFrom, cityCodeTo } = dictionary;
                    const processedCityCodeFrom = await fetchCountry(cityCodeFrom);
                    const processedCityCodeTo = await fetchCountry(cityCodeTo);
                    const timeDiff = index < route.length - 1
                        ? route[index +1].dTimeUTC - route[index].aTimeUTC
                        : 0;
                    return { ...dictionary, processedCityCodeFrom, processedCityCodeTo, timeDiff  };
                })
            );
            // console.log(updatedRoute);

            setUpdatedRoute(updatedRoute);
        };

        fetchUpdatedRoute();
    }, [route]);

    const [updatedRoute, setUpdatedRoute] = useState([]);
    const getNamesFromIds = (uniqueAirline) => {
        const item = data.find(item => item.id === uniqueAirline);
        return item ? item.name : '';
    };
    const toCountry_=JSON.parse(localStorage.getItem('toCountry0'));

    localStorage.setItem('updatedRoute', JSON.stringify(updatedRoute));
    localStorage.setItem('moreInfoKey', key);



    return(
        <div className="moreInfoCont" style={style2}  key={key}>

            <div className='more-info-Booking' style={style2}>
                <div  onClick={hideinfo} style={{cursor: "pointer"}}>
                    <img style={style} className='moreInfoImg'
                         src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1689366744/Group_6846_hergx0.svg'/>
                </div>

                <div className='itenery'>Flight Itinerary</div>
                <div className='transport'>{route[0].cityFrom} → {route[route.length - 1].cityTo}<span>Duration: <span>{duration}</span></span></div>
                {updatedRoute.map(routes =>(
                    <div>
                        <div key={routes.id} className='stops'>
                            <div className='timeDetail'>
                                <div className='numb'>{convertTime(routes.dTime)}<br/><span>{calculateDayFormat(routes.dTimeUTC)}</span></div>
                                <div className='tDiff'>{convertTimeSpace(routes.aTimeUTC - routes.dTimeUTC)}</div>
                                <div className='numb'>{convertTime(routes.aTime)}<br/><span >{calculateDayFormat(routes.aTimeUTC)}</span></div>
                            </div>
                            <img className='arrow' alt='Flight Direction Img'
                                 src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1687836109/Group_133542_usyaf7.svg'/>
                            <div className='timeDetail'>
                                <div className='numb'>{routes.cityFrom} · {routes.cityCodeFrom}<br/><span>{routes.processedCityCodeFrom}</span></div>
                                <div className='tDiff airway'><img
                                    src={`https://images.kiwi.com/airlines/64x64/${routes.airline}.png?default=airline.png`}/><span>{getNamesFromIds(routes.airline)}</span>
                                </div>
                                <div className='numb'>{routes.cityTo} · {routes.cityCodeTo}<br/><span>{routes.processedCityCodeTo}</span></div>
                            </div>
                        </div>
                        {routes.cityCodeTo !== toCountry_[0] && routes.timeDiff !== 0 && (<div className='layover'><img src='https://res.cloudinary.com/dk80ynx1w/image/upload/v1689378891/Frame_rfmk76.svg'/><span>{convertTimeSpace(routes.timeDiff)} layover</span></div>)}
                        {
                            routes.cityCodeTo === toCountry_[0] &&

                            (<div className='nightCalc'>
                                <hr className="hrline"></hr>
                                <div className='nightCont'>{convertDaySpace(routes.timeDiff)} nights in {routes.cityTo} </div>
                                <hr className="hrline"></hr>

                            </div>)
                        }
                    </div>
                ))}

            </div>
        </div>
    )

}
export default React.memo(MoreInfo);