// ReloadContext.js

import React from 'react';

const ReloadContext = React.createContext({
    reloadFlag: false,
    setReloadFlag: () => {},
});

export default ReloadContext;
