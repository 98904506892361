import axios from 'axios';
import getCookie from "../Login/getCookie";


const tok = getCookie('AUTH-Token')

const axiosWithAuth =(apikey) =>  axios.create({
    baseURL: 'https://api.jaromtravels.com/api',
    headers: {
        Authorization: apikey || `Bearer ${tok}`,
    },
    // credentials: 'include',
});

export default axiosWithAuth;
