import React from "react";

export default function SearchState ({handleClickFirst, img, HeadText, SubText, BtnText}){
    return (
        <div className="unavilableContainer">
            <div className='unavailable'>
                <img src={img} alt=""/>
                <div className="unavailbleText">
                    {HeadText}
                </div>

                <div className="unavilSubText">
                    {SubText}
                </div>
                <br/>

                <div className="unavailableBtn" onClick={()=>handleClickFirst}>
                    {BtnText}
                </div>

            </div>
        </div>
    )
}