import React, {useEffect, useState} from 'react';
import Select from "react-select";

const TitleDropdown = ({ options, title, mfont,mxfont,mwidth,mxwidth,required, className,name, id,margin, width, borderR, borderL, height, localStorageName }) => {
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        const storedValue = localStorage.getItem(localStorageName);
        if (storedValue) {
            setSelectedValue({ value: storedValue, label: storedValue });
        }
    }, [localStorageName]);
    const handleSelectChange = (selectedOption) => {
        const newValue = selectedOption ? selectedOption.value : null;
        setSelectedValue(newValue);
        localStorage.setItem(localStorageName, newValue);
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '4px',
            borderColor: state.isFocused ? 'blue' : '#DDEAF0',
            boxShadow:  'none',
            borderWidth: '1px',
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17px',
            borderTopRightRadius:`${borderR}`,
            borderBottomRightRadius:`${borderL}`,
            width:`${width}`,
            height:`${height}`,
            margin:`${margin}`,
            '@media (min-width: 760px)': {
                fontSize: `${mfont}`,
                height:'24px',
                width:`${mwidth}`,
            },
            '@media (min-width: 1024px)': {
                fontSize: `${mxfont}`,
                height:'41px',
                width:`${mxwidth}`,
            },
            '&:hover': {
                borderColor: state.isFocused ? 'blue' : '#DDEAF0',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'lightblue' : 'white',
            color: '#6D8494',
            '&:hover': {
                backgroundColor: 'lightblue',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#6D8494', // Change the color value as desired
        }),
    };

    return (
        <div>
            <Select
                onChange={handleSelectChange}
                options={options.map((option) => ({ value: option, label: option }))}
                styles={customStyles}
                placeholder={selectedValue ? selectedValue.value : title}
                required={required === undefined ? true : required}
                name={name}
            />
        </div>
    );
};

export default TitleDropdown;
