import React, {useEffect, useState} from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';


export default function Check({filterAirlines, name, storageKey, header, onCheckChange, isChecked, handleSubmit}) {
    const [checked, setChecked] = useState(isChecked);
    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);


    const handleCheckboxChange = (event) => {
        // Uncheck other checkboxes
        const otherKeys = ['anyStop', 'directStop', 'upToOneStop', 'upToTwoStop'];
        otherKeys.forEach((key) => {
            if (key !== storageKey) {
                localStorage.setItem(key, 'false');
            }
        });
        if(otherKeys.includes(storageKey)){
            setChecked(true);
            localStorage.setItem(storageKey, 'true');
            onCheckChange(storageKey, 'true');
            setTimeout(handleSubmit, 1000);
        }
        else{
            const checked_ = event.target.checked;
            if(checked_ === true){
                const list = JSON.parse(localStorage.getItem("listKey"));
                list.push(name);
                localStorage.setItem("listKey", JSON.stringify(list));
                filterAirlines();
            }
            else{
                const list = JSON.parse(localStorage.getItem("listKey"));
                const index = list.indexOf(name);
                list.splice(index, 1);
                localStorage.setItem('listKey', JSON.stringify(list));
                filterAirlines();
            }
        }


    };


    return (
        <div>
            <label className="visa_lcheck">
                <input
                    type="checkbox"
                    className="custom-checkbox filter-checkbox"
                    checked={checked}
                    name={name}
                    onChange={handleCheckboxChange}
                />
                <span className="checkmark" id="filter_checkmark"></span>
                <span className={`logged ${checked ? 'checked' : ''}`} id="visa_tick">
          {header || <Skeleton width={100} duration={20}/>}
                    <br />
        </span>
            </label>
        </div>
    );
}
